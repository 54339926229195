import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import shortId from 'shortid';
import classnames from 'classnames';

import {
  getConcentrationAssetsList,
  setTemplateData,
} from 'actions/flinkPlayActions';
import { showStats } from 'actions/gameActions';
import { GO_BACK, HELP } from 'consts/buttons';
import { setActivityPartial } from 'actions/flinkPlayActions';
import { playAudio, stopAudio } from 'actions/audioActions';
import { imagesURL, s3bucketPublicURL } from 'config';
import { stringProblemsMapper } from 'activity-templates/utils';
import { prepareProblem, validateProblem } from './concentrationHelpers';
import { ActivityButtons, WhiteBox } from 'components/flink-play';
import classes from './Concentration.module.scss';
import { CONCENTRATION } from 'consts/activity-templates';

class Concentration extends Component {
  state = {
    leftMatchTypeIdx: 0,
    rightMatchTypeIdx: 1,
  };

  buffer = [];
  timeout = null;

  /*async componentDidMount() {
    const {
      concentrationData,
      getConcentrationAssetsList,
      translate,
      getStringAudio,
      activity,
    } = this.props;
    */
    /*const {
      gameData: { matchTypes, problems, cardsPerLevel },
    } = activity.data;*/
    /*const thisVersion = !this.props.isPlayVersion ? activity.activity.currentLanguageVersion 
    : {version: 'immersion', locale: this.props.solutionLocale.code};
    const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";    
    const gameData = versionType 
    ? activity.data.languageVersionData?.[versionType]
    : activity.data.gameData;

    const { matchTypes, problems, cardsPerLevel } = gameData;

    // Remove invalid problems and map them
    const mapper = stringProblemsMapper({ translate, getStringAudio });
    const matches = problems
      .filter((problem) => validateProblem(matchTypes, problem))
      .map(prepareProblem(mapper));

    const filteredCardsPerLevel = cardsPerLevel.filter(
      (num) => num <= matches.length * 2
    );

    if (!concentrationData) {
      await getConcentrationAssetsList();
    }

    const contentPath = versionType 
    ? `${s3bucketPublicURL}/${activity.activity.contentFolder}/languageVersionData/${versionType}`
    : `${s3bucketPublicURL}/${activity.activity.contentFolder}/gamedata`;

    const audioCardImg = `${imagesURL}/TemplateGraphics/Concentration/audio_card_back.jpg`;
    const leftArrowImg = `${imagesURL}/TemplateGraphics/Concentration/arrow_back.png`;
    const rightArrowImg = `${imagesURL}/TemplateGraphics/Concentration/arrow_forward.png`;

    this.setState({
      initialized: true,
      audioCardImg,
      leftArrowImg,
      rightArrowImg,
      contentPath,
      cardsPerLevel: filteredCardsPerLevel,
      matches,
      matchTypes,
    });
  }*/

    async componentDidMount() {
      const {
        concentrationData,
        getConcentrationAssetsList,
        translate,
        getStringAudio,
        activity,
      } = this.props;
    
      const thisVersion = !this.props.isPlayVersion
        ? activity.activity.currentLanguageVersion
        : { version: 'immersion', locale: this.props.solutionLocale.code };
    
      const versionType =
        thisVersion?.version && thisVersion?.locale
          ? `${thisVersion.version}_${thisVersion.locale}`
          : null;
    
      const languageVersionDataExists =
        versionType && activity.data.languageVersionData?.[versionType];
    
      const gameData = languageVersionDataExists
        ? activity.data.languageVersionData[versionType]
        : activity.data.gameData;
    
      if (!gameData) {
        console.error('Game data is missing or undefined.');
        return;
      }
    
      const { matchTypes, problems, cardsPerLevel } = gameData;
    
      const mapper = stringProblemsMapper({ translate, getStringAudio });
      const matches = problems
        .filter((problem) => validateProblem(matchTypes, problem))
        .map(prepareProblem(mapper));
    
      const filteredCardsPerLevel = cardsPerLevel.filter(
        (num) => num <= matches.length * 2
      );
    
      if (!concentrationData) {
        await getConcentrationAssetsList();
      }
    
      const contentPath = languageVersionDataExists
        ? `${s3bucketPublicURL}/${activity.activity.contentFolder}/languageVersionData/${versionType}`
        : `${s3bucketPublicURL}/${activity.activity.contentFolder}/gamedata`;
    
      const audioCardImg = `${imagesURL}/TemplateGraphics/Concentration/audio_card_back.jpg`;
      const leftArrowImg = `${imagesURL}/TemplateGraphics/Concentration/arrow_back.png`;
      const rightArrowImg = `${imagesURL}/TemplateGraphics/Concentration/arrow_forward.png`;
    
      this.setState({
        initialized: true,
        audioCardImg,
        leftArrowImg,
        rightArrowImg,
        contentPath,
        cardsPerLevel: filteredCardsPerLevel,
        matches,
        matchTypes,
      });
    }
      

  componentDidUpdate(prevProps, prevState) {
    const { gameInitialized } = this.props;
    const {
      cardsCount,
      leftMatchTypeIdx,
      rightMatchTypeIdx,
      initialized,
    } = this.state;

    if (prevState.initialized !== initialized) {
      if (gameInitialized) {
        this.initGame();
      } else {
        document.addEventListener('startGame', this.initGame);
      }

      return;
    }

    if (
      (prevState.cardsCount && prevState.cardsCount !== cardsCount) ||
      prevState.leftMatchTypeIdx !== leftMatchTypeIdx ||
      prevState.rightMatchTypeIdx !== rightMatchTypeIdx
    ) {
      this.startGame();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    document.removeEventListener('startGame', this.initGame);
  }

  initGame = () => {
    const { setTemplateData, concentrationData } = this.props;

    setTemplateData({
      CONCENTRATION: {
        ...concentrationData,
        numberOfPlays: concentrationData.numberOfPlays + 1,
      },
    });

    this.startGame();
  };

  startGame = () => {
    const {
      cardsPerLevel,
      matches,
      leftMatchTypeIdx,
      rightMatchTypeIdx,
      cardsCount: selectedCardsCount,
      matchTypes,
    } = this.state;

    clearTimeout(this.timeout);
    this.buffer = [];

    const cardsCount = selectedCardsCount || cardsPerLevel[0];
    const commonMatchTypeIdx = _.findIndex(matchTypes, { isCommon: true });

    const cards = _.chain(matches)
      .shuffle()
      .take(cardsCount / 2)
      .map((match) => {
        const { items } = match;

        const firstCardId = shortId.generate();
        const secondCardId = shortId.generate();

        const commonValue =
          commonMatchTypeIdx !== -1 && items[commonMatchTypeIdx].text;

        const firstCard = {
          id: firstCardId,
          ...matchTypes[leftMatchTypeIdx],
          ...items[leftMatchTypeIdx],
          commonValue: commonMatchTypeIdx !== leftMatchTypeIdx && commonValue,
          matchCardId: secondCardId,
          isOpened: false,
        };

        const secondCard = {
          id: secondCardId,
          ...matchTypes[rightMatchTypeIdx],
          ...items[rightMatchTypeIdx],
          commonValue: commonMatchTypeIdx !== rightMatchTypeIdx && commonValue,
          matchCardId: firstCardId,
          isOpened: false,
        };

        return [firstCard, secondCard];
      })
      .flatten()
      .shuffle()
      .value();

    // determine font size of answers based on max letter count
    const maxTextLength = Math.max(
      ...cards.map((c) => (c.text ? c.text.length : 0))
    );

    this.setState({
      maxTextLength,
      cardsCount,
      cards,
    });
  };

  onCardClick = (card) => {
    const { cards, contentPath } = this.state;

    if (card.isOpened) {
      if (this.checkIfCardAudioIsPlayingNow(card)) {
        stopAudio();
      } else if (card.type === 'audio') {
        playAudio(`${contentPath}/${card.audio}`);
      }

      return;
    }

    if (this.buffer.length === 2) return;

    const openedCard = { ...card, isOpened: true };

    this.buffer.push(openedCard);

    const cardValue = openedCard[card.type];

    if (openedCard.type === 'audio') {
      // play sound
      playAudio(`${contentPath}/${cardValue}`);
    }

    // Open Card
    const modifiedCards = _.map(cards, (c) =>
      c.id === openedCard.id ? openedCard : c
    );

    this.setState({ cards: modifiedCards });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.checkCardsInBuffer();
    }, 1200);
  };

  checkCardsInBuffer = () => {
    const { preview, setActivityPartial } = this.props;

    if (this.buffer.length < 2) return;

    // Check if cards in buffer are match
    const { cards: stateCards } = this.state;
    const [card1, card2] = this.buffer;

    let cards;

    if (
      (card1.matchCardId === card2.id && card2.matchCardId === card1.id) ||
      (card1.isCommon && !card2.isCommon && card2.commonValue === card1.text) ||
      (card2.isCommon && !card1.isCommon && card1.commonValue === card2.text)
    ) {
      // MATCH
      cards = _.map(stateCards, (c) => {
        if (c.id === card1.id) {
          return { ...card1, isResolved: true };
        }

        if (c.id === card2.id) {
          return { ...card2, isResolved: true };
        }

        return c;
      });
    } else {
      // Close opened cards (that in buffer)
      cards = stateCards.map((c) => {
        if (c.id === card1.id || c.id === card2.id) {
          return { ...c, isOpened: false };
        }

        return c;
      });
    }

    if (
      this.checkIfCardAudioIsPlayingNow(card1) ||
      this.checkIfCardAudioIsPlayingNow(card2)
    ) {
      stopAudio();
    }

    this.setState({ cards });

    // Clear Buffer
    this.buffer = [];

    const resolvedCards = cards.filter((c) => c.isResolved);

    // NOT FOR PREVIEW: check if 2 (4 cards) matches are resolved then
    // save that learner partialy completed the activity
    if (!preview && resolvedCards.length === 4) {
      setActivityPartial();
    }

    // Check is all cards are resolved
    if (resolvedCards.length === cards.length) {
      this.timeout = setTimeout(this.finishGame, 5000);
    }
  };

  checkIfCardAudioIsPlayingNow = (card) => {
    const { audio } = this.props;

    const isSomethingCurrentlyPlaying = !!(
      audio &&
      !audio.ended &&
      !audio.paused
    );

    if (!card || !isSomethingCurrentlyPlaying) return false;

    if (card.audio && audio.src.indexOf(card.audio) !== -1) {
      return true;
    }
  };

  changeCardsCount = (cardsCount) => {
    this.setState({ cardsCount });
  };

  finishGame = () => {
    this.setState({
      cards: null,
    });

    this.props.showStats({
      isCongratulations: true,
    });
  };

  changeMatchType = (matchTypeIndex) => {
    const { matchTypes, leftMatchTypeIdx, rightMatchTypeIdx } = this.state;

    const availbleMatchTypeIdx = _.findIndex(
      matchTypes,
      (m, idx) => idx !== leftMatchTypeIdx && idx !== rightMatchTypeIdx
    );

    this.setState({
      leftMatchTypeIdx:
        leftMatchTypeIdx === matchTypeIndex
          ? availbleMatchTypeIdx
          : leftMatchTypeIdx,
      rightMatchTypeIdx:
        rightMatchTypeIdx === matchTypeIndex
          ? availbleMatchTypeIdx
          : rightMatchTypeIdx,
    });
  };

  render() {
    const {
      leftArrowImg,
      rightArrowImg,
      leftMatchTypeIdx,
      rightMatchTypeIdx,
      contentPath,
      cards,
      cardsCount,
      audioCardImg,
      cardsPerLevel,
      maxTextLength,
      matchTypes,
    } = this.state;

    if (!cards) return <ActivityButtons buttons={[GO_BACK, HELP]} />;

    const { textStyle, concentrationData } = this.props;

    const textCardClassnames = {
      [classes.textUpTo2]: maxTextLength <= 2,
      [classes.textUpTo4]: maxTextLength > 2 && maxTextLength <= 4,
      [classes.textUpTo6]: maxTextLength > 4 && maxTextLength <= 6,
      [classes.textUpTo10]: maxTextLength > 6 && maxTextLength <= 10,
    };

    const cardsPerLevelCount = cardsPerLevel.filter((num) => !!num).length;
    const matchTypesCount = matchTypes.filter((m) => !!m.type).length;
    const leftMatchType = matchTypes[leftMatchTypeIdx];
    const rightMatchType = matchTypes[rightMatchTypeIdx];

    return (
      <>
        {cardsPerLevelCount > 1 && (
          <div className={classes.selectCardsCount}>
            {cardsPerLevel.map((num) =>
              num ? (
                <button
                  className={classnames({
                    [classes.active]: num === cardsCount,
                  })}
                  onClick={(e) => this.changeCardsCount(num)}
                  key={num}
                >
                  {num}
                </button>
              ) : null
            )}
          </div>
        )}

        {matchTypesCount > 2 && (
          <div className={classes.matchTypes}>
            <span>{leftMatchType.title || 'No Title'}</span>
            <button onClick={() => this.changeMatchType(leftMatchTypeIdx)}>
              <img src={leftArrowImg} alt="" />
            </button>
            <button onClick={() => this.changeMatchType(rightMatchTypeIdx)}>
              <img src={rightArrowImg} alt="" />
            </button>
            <span>{rightMatchType.title || 'No Title'}</span>
          </div>
        )}

        <WhiteBox outerStyle={textStyle} outerClass={classes.cardsBox}>
          <div
            className={`${classes.cardsWrapper} ${
              classes[`cardsWrapper--${cardsCount}`]
            }`}
            style={{
              backgroundImage: `url(${
                concentrationData.rewardGraphics[
                  (concentrationData.numberOfPlays - 1) %
                    concentrationData.rewardGraphics.length
                ]
              })`,
            }}
          >
            {cards.map((card) => {
              const { id, type, isOpened, isResolved } = card;
              const value = card[type];

              return (
                <div
                  key={id}
                  onClick={(e) => this.onCardClick(card)}
                  className={classnames(
                    classes.card,
                    classes[`card--${type}`],
                    {
                      [classes.cardOpened]: isOpened,
                      [classes.cardResolved]: isResolved,
                    }
                  )}
                >
                  <div
                    className={`${classes.cardSide} ${classes.cardFront}`}
                    style={{
                      backgroundImage: `url(${
                        concentrationData.cardbacks[
                          (concentrationData.numberOfPlays - 1) %
                            concentrationData.cardbacks.length
                        ]
                      })`,
                    }}
                  ></div>
                  <div
                    className={classnames(
                      classes.cardSide,
                      classes.cardBack,
                      textCardClassnames
                    )}
                  >
                    {type === 'audio' ? (
                      <img src={audioCardImg} alt="" />
                    ) : type === 'image' ? (
                      <img src={`${contentPath}/${value}`} alt="" />
                    ) : (
                      value
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </WhiteBox>

        <ActivityButtons buttons={[GO_BACK, HELP]} />
      </>
    );
  }
}

Concentration.propTypes = {
  activity: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
  // From redux
  audio: PropTypes.object,
  setActivityPartial: PropTypes.func.isRequired,
  showStats: PropTypes.func.isRequired,
  setTemplateData: PropTypes.func.isRequired,
  getConcentrationAssetsList: PropTypes.func.isRequired,
};

const mapStateToProps = ({ game, flinkPlay, audio }) => ({
  audio: audio.audio,
  gameInitialized: game.gameInitialized,
  concentrationData: flinkPlay.templatesData[CONCENTRATION],
});

export default connect(mapStateToProps, {
  setActivityPartial,
  showStats,
  setTemplateData,
  getConcentrationAssetsList,
})(Concentration);
