import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { getInstruction } from 'activity-templates/utils';
import { Instructions, ActivityButtons } from 'components/flink-play';
import { setActivityPartial } from 'actions/flinkPlayActions';
import PuzzleBlock from './PuzzleBlock/PuzzleBlock';
import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import { playAudio } from 'actions/audioActions';
import { validateProblem } from './validateWordSearch';
import { generatePuzzle, checkStep, getDirection } from './wordSearchHelpers';
import { WhiteBox } from 'components/flink-play';

import classes from './WordSearch.module.scss';

class WordSearch extends Component {
  /*constructor(props) {
    super(props);

    /*const {
      // options,
      gameData: { problems: originalProblems },
    } = props.activity.data;*/

   /* const thisVersion = !props.isPlayVersion ? props.activity.activity.currentLanguageVersion 
    : {version: 'immersion', locale: props.solutionLocale.code};
    const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";    
    const gameData = versionType 
    ? props.activity.data.languageVersionData?.[versionType]
    : props.activity.data.gameData;

    const { problems: originalProblems } = gameData;
    const problems = _.chain(originalProblems).filter(validateProblem).value();

    this.state = {
      problems,
      currentProblem: null,
    };
  }*/

    constructor(props) {
      super(props);
  
      const thisVersion = !props.isPlayVersion
          ? props.activity.activity.currentLanguageVersion
          : { version: 'immersion', locale: props.solutionLocale.code };
  
      const versionType =
          thisVersion && thisVersion.version && thisVersion.locale
              ? `${thisVersion.version}_${thisVersion.locale}`
              : "";
  
      const languageVersionDataExists =
          versionType &&
          props.activity.data.languageVersionData &&
          props.activity.data.languageVersionData[versionType];
  
      const gameData = languageVersionDataExists
          ? props.activity.data.languageVersionData[versionType]
          : props.activity.data.gameData;
  
      const { problems: originalProblems } = gameData;
  
      const problems = _.chain(originalProblems)
          .filter(validateProblem)
          .value();
  
      this.state = {
          problems,
          currentProblem: null,
          versionType,
          languageVersionDataExists, 
      };
  }
    

  componentDidMount() {
    document.addEventListener('startGame', this.startGame);
    document.addEventListener('mouseup', this.onEndSelect);
    document.addEventListener('touchend', this.onEndSelect);
  }

  componentWillUnmount() {
    document.removeEventListener('startGame', this.startGame);
    document.removeEventListener('mouseup', this.onEndSelect);
    document.removeEventListener('touchend', this.onEndSelect);

    clearTimeout(this.finishTimeout);
  }

  startGame = () => {
    const { activity } = this.props;
    const { problems } = this.state;
    const currentProblem = problems[0];

    const puzzle = generatePuzzle(currentProblem, activity.data.options);

    const {
      data: {
        options: { wordSearch },
      },
    } = activity;

    const allWords =
      wordSearch && wordSearch.alphabetize
        ? puzzle.words
        : _.chain(puzzle.settings.dictionary)
            .map((word) => _.find(puzzle.words, { word }))
            .value();

    this.setState({
      currentProblem,
      puzzle,
      allWords,
      currentIndex: 0,
      isResolved: false,
      problemsCount: problems.length,
      attemptsOnCurrentProblem: 0,
      answeredCorrectly: 0,
      answeredIncorrectly: 0,
      problemsLeft: problems.length,
    });
  };

  onStartSelect = (path) => {
    this.setState({ selectedPaths: [path], selecting: true });
  };

  onMouseEnter = (path) => {
    const { selecting, selectedPaths = [], directionOfSelect } = this.state;

    if (!selecting) return;

    const prevPath = _.last(selectedPaths);

    if (_.isEqual(prevPath, path)) {
      return;
    }

    const prePrevPath = selectedPaths[selectedPaths.length - 2];

    if (_.isEqual(prePrevPath, path)) {
      return this.setState({
        selectedPaths: _.initial(selectedPaths),
      });
    }

    const correctStep = checkStep(prevPath, path);

    if (!correctStep) {
      return this.resetSelection();
    }

    let direction = directionOfSelect;

    const currentDirection = getDirection(prevPath, path);

    if (!direction) {
      direction = currentDirection;
    } else if (direction !== currentDirection) {
      return;
    }

    const newSelectedPaths = _.chain(selectedPaths)
      .concat(path)
      .uniqWith(_.isEqual)
      .value();

    this.setState({
      selectedPaths: newSelectedPaths,
      directionOfSelect: direction,
    });
  };

  onEndSelect = () => {
    const {
      selectedPaths = [],
      puzzle,
      currentProblem,
      resolvedPaths: stateResolvedPaths,
      allWords,
      hintedPaths,
      directionOfSelect,
      selecting,
    } = this.state;
    const { contentFolder } = this.props;

    if (!selecting) return;

    const { disabledDirections } = puzzle.settings;

    this.resetSelection();

    if (disabledDirections.includes(directionOfSelect)) {
      // Not allowed direction
      return;
    }

    const wordFromSelection = _.chain(allWords)
      .filter((w) => !w.isResolved)
      .find((w) => JSON.stringify(w.path) === JSON.stringify(selectedPaths))
      .value();

    if (!wordFromSelection) {
      return;
    }

    const resolvedPaths = _.chain(stateResolvedPaths)
      .concat(selectedPaths)
      .uniqWith(_.isEqual)
      .value();

    if (currentProblem.wordsAudio) {
      const wordAudio = currentProblem.wordsAudio[wordFromSelection.word];
      wordAudio && playAudio(contentFolder + wordAudio);
    }

    wordFromSelection.isResolved = true;

    const newHintedPaths = _.differenceWith(
      hintedPaths,
      selectedPaths,
      _.isEqual
    );

    this.setState(
      (state) => ({
        allWords: [...allWords],
        hintedPaths: newHintedPaths,
        resolvedPaths,
        resolvedWrappers: [...(state.resolvedWrappers || []), selectedPaths],
      }),
      this.checkCompete
    );
  };

  resetSelection = () => {
    this.setState({
      selectedPaths: [],
      selecting: false,
      directionOfSelect: '',
    });
  };

  checkCompete = () => {
    const { allWords } = this.state;
    const { preview, setActivityPartial } = this.props;

    const resolvedCount = allWords.filter((w) => !!w.isResolved).length;

    // if 2 words are resolved then set activity as partial completed
    if (!preview && resolvedCount === 2) {
      setActivityPartial();
    }

    // Check if all words are resolved
    if (resolvedCount === allWords.length) {
      // finish game
      this.finishTimeout = setTimeout(() => {
        this.finishGame();
      }, 1000);
    }
  };

  finishGame = () => {
    const { showStats } = this.props;

    showStats({
      isCongratulations: true,
    });

    this.setState({
      currentProblem: null,
      puzzle: null,
      allWords: null,
      selectedPaths: [],
      resolvedPaths: [],
      resolvedWrappers: [],
      hintedPaths: [],
    });
  };

  showHint = () => {
    const { hintedPaths = [], resolvedPaths, allWords } = this.state;

    if (hintedPaths.length) return;

    const unresolvedPaths = _.chain(allWords)
      .filter((w) => !w.isResolved)
      .map((w) => w.path)
      .flatten()
      .value();

    const notHintedYet = _.reject(
      unresolvedPaths,
      (path) => !!_.find(hintedPaths, path)
    );

    const notInResolvedWords = _.reject(
      notHintedYet,
      (path) => !!_.find(resolvedPaths, path)
    );

    const pathToHint = _.sample(
      notInResolvedWords.length ? notInResolvedWords : notHintedYet
    );

    if (!pathToHint) {
      return;
    }

    this.setState({ hintedPaths: [...hintedPaths, pathToHint] });
  };

  render() {
    const {
      solutionLocale,
      translate,
      getStringAudio,
      activity: {
        data: { options },
      },
    } = this.props;
    const { currentProblem, isResolved, hintedPaths = [] } = this.state;

    const instruction = getInstruction(
      currentProblem, 
      solutionLocale,
      undefined, undefined, undefined,
      { translate, getStringAudio },
    );

    return (
      <>
        <Instructions problem={currentProblem} />

        {this.renderBlocks()}

        <ActivityButtons
          buttons={[
            {
              type: buttonsTypes.CLUE,
              onClick: this.showHint,
              disabled: hintedPaths.length,
              dontShow:
                !options.wordSearch ||
                !options.wordSearch.showHints ||
                isResolved,
            },
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.SPEAK,
              dontShow: !instruction.audio || isResolved,
            },
            buttonsTypes.HELP,
          ]}
        />
      </>
    );
  }

  renderBlocks = () => {
    const { textStyle } = this.props;
    const {
      currentProblem,
      puzzle,
      selectedPaths,
      resolvedPaths,
      resolvedWrappers,
      hintedPaths,
      allWords,
    } = this.state;

    if (!puzzle || !currentProblem) return null;

    const { title } = currentProblem;

    return (
      <>
        <div style={textStyle}>
          <PuzzleBlock
            puzzle={puzzle}
            selectedPaths={selectedPaths}
            resolvedPaths={resolvedPaths}
            hintedPaths={hintedPaths}
            resolvedWrappers={resolvedWrappers}
            onStartSelect={this.onStartSelect}
            // onEndSelect={this.onEndSelect}
            onMouseEnter={this.onMouseEnter}
          />
        </div>

        <div className={classes.rightColumn}>
          {title && (
            <WhiteBox
              outerClass={classes.titleBox}
              innerClass={classes.titleBoxInner}
            >
              <p>{title}</p>
            </WhiteBox>
          )}

          <WhiteBox innerClass={classes.wordsBoxInner}>
            <ul className={classes.wordsList}>
              {allWords.map((w, idx) => (
                <li
                  key={idx}
                  className={classnames({
                    [classes.resolved]: w.isResolved,
                  })}
                >
                  {w.clean}
                </li>
              ))}
            </ul>
          </WhiteBox>
        </div>
      </>
    );
  };
}

WordSearch.propTypes = {
  setActivityPartial: PropTypes.func.isRequired,
  activity: PropTypes.object.isRequired,
};

export default connect(null, { showStats, setActivityPartial })(WordSearch);
