import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import API from 'api';
import {
  setActivityPartial,
  setActivityMastered,
} from 'actions/flinkPlayActions';
import { ActivityButtons, WhiteBox } from 'components/flink-play';
import { printElem, getScale } from 'utils';
import { GO_BACK, SPEAK, HELP, PRINT } from 'consts/buttons';
import { activitiesKey, usersKey, uploadsURL, imagesURL } from 'config';

import { stringProblemsMapper } from 'activity-templates/utils';
import { toggleAudio, stopAudio } from 'actions/audioActions';
import { closeWritingFeedback } from 'actions/flinkPlayActions';

import { EDITOR_WIDTH } from './writingAssistantHelpers';
import WritingAssistantEditor from './WritingAssistantEditor/WritingAssistantEditor';
import GuideBox from './GuideBox/GuideBox';
import { WRITING_ASSISTANT } from 'consts/activity-templates';
import classes from './WritingAssistant.module.scss';

import { WritingFeedback } from 'components/flink-play';

const templateGraphicsFolder = `${imagesURL}/TemplateGraphics/WritingAssistant/`;

const divideSize = (s) => s / 2 + 'px';

const defaultData = {
  templateAlias: WRITING_ASSISTANT,
  text: '',
};

export class WritingAssistant extends Component {
  /*constructor(props) {
    super(props);

    const { activity, learnerId, solutionLocale, team, translate, getStringAudio } = props;
    
    const thisVersion = !props.isPlayVersion ? activity.activity.currentLanguageVersion 
    : {version: 'immersion', locale: props.solutionLocale.code};
    const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";    
    const gameData = versionType 
    ? props.activity.data.languageVersionData?.[versionType]
    : props.activity.data.gameData;
    
    const {
      /*data: {
        gameData: {
          guides = [],
          images = [],
          words = [],
          starters = [],
          reading = '',
          multiLocaleGuides,
        },
      },*/
    /*  activity: { _id: activityId },
    } = activity;

    const {
          guides = [],
          images = [],
          words = [],
          starters = [],
          reading = '',
          multiLocaleGuides,
        } = gameData;
    
    const learnersIDs = [];

    if (learnerId) {
      learnersIDs.push(learnerId);
    }

    if (team) {
      learnersIDs.push(...team.map((l) => l._id));
    }

    const activityAssetsKey = versionType ? `${activitiesKey}/${activityId}/languageVersionData/${versionType}` 
    : `${activitiesKey}/${activityId}/gamedata`;
    const activityAssetsFolder = `${uploadsURL}/${activityAssetsKey}/`;
    const learnerAssetsKey =
      learnerId && `${usersKey}/${learnerId}/${activityId}`;
    const learnerAssetsFolder =
      learnerId && `${uploadsURL}/${learnerAssetsKey}/`;

    const locale =
      (solutionLocale && solutionLocale.code) ||
      (multiLocaleGuides && multiLocaleGuides.defaultLocale) ||
      'en';

    const currentGuides = multiLocaleGuides
      ? multiLocaleGuides[locale] || []
      : guides;

    const mapper = stringProblemsMapper({ translate, getStringAudio, translateArgs: ['', false, true] });
    const filteredGuides = currentGuides
      .map((guide, idx) => ({
        ...(guide || {}),
        starter: starters[idx],
        text: guide?.string || guide?.text,
      }))
      .filter((g) => !!(g.text || g.starter || g.audio))
      .map(mapper);

    const filteredImages = images
      .filter((i) => !!i)
      .map((i) => activityAssetsFolder + i);
    const filteredWords = words.filter((w) => !!(w && w.trim()) && w);

    this.state = {
      paths: {
        activityAssetsKey,
        activityAssetsFolder,
        learnerAssetsKey,
        learnerAssetsFolder,
      },
      reading,
      activeGuideIdx: 0,
      filteredGuides,
      filteredImages,
      filteredWords,
      data: defaultData,
      learnersIDs,
    };
  }*/

    constructor(props) {
      super(props);
  
      const { activity, learnerId, team, solutionLocale, translate, getStringAudio } = props;
  
      const thisVersion = !props.isPlayVersion
          ? activity.activity.currentLanguageVersion
          : { version: 'immersion', locale: solutionLocale.code };
  
      const versionType =
          thisVersion && thisVersion.version && thisVersion.locale
              ? `${thisVersion.version}_${thisVersion.locale}`
              : "";
  
      const languageVersionDataExists =
          versionType &&
          activity.data.languageVersionData &&
          activity.data.languageVersionData[versionType];
  
      const gameData = languageVersionDataExists
          ? activity.data.languageVersionData[versionType]
          : activity.data.gameData;
  
      const {
          guides = [],
          images = [],
          words = [],
          starters = [],
          reading = '',
          multiLocaleGuides,
      } = gameData;
  
      const learnersIDs = [];
      if (learnerId) {
          learnersIDs.push(learnerId);
      }
      if (team) {
          learnersIDs.push(...team.map((l) => l._id));
      }
  
      const activityAssetsKey = languageVersionDataExists
          ? `${activitiesKey}/${activity.activity._id}/languageVersionData/${versionType}`
          : `${activitiesKey}/${activity.activity._id}/gamedata`;
  
      const activityAssetsFolder = `${uploadsURL}/${activityAssetsKey}/`;
      const learnerAssetsKey = learnerId && `${usersKey}/${learnerId}/${activity.activity._id}`;
      const learnerAssetsFolder = learnerId && `${uploadsURL}/${learnerAssetsKey}/`;
  
      const locale =
          (solutionLocale && solutionLocale.code) ||
          (multiLocaleGuides && multiLocaleGuides.defaultLocale) ||
          'en';
  
      const currentGuides = multiLocaleGuides
          ? multiLocaleGuides[locale] || []
          : guides;
  
      const mapper = stringProblemsMapper({ translate, getStringAudio, translateArgs: ['', false, true] });
  
      const filteredGuides = currentGuides
          .map((guide, idx) => ({
              ...(guide || {}),
              starter: starters[idx],
              text: guide?.string || guide?.text,
          }))
          .filter((g) => !!(g.text || g.starter || g.audio))
          .map(mapper);
  
      const filteredImages = images
          .filter((i) => !!i)
          .map((i) => activityAssetsFolder + i);
  
      const filteredWords = words.filter((w) => !!(w && w.trim()) && w);
  
      this.state = {
          paths: {
              activityAssetsKey,
              activityAssetsFolder,
              learnerAssetsKey,
              learnerAssetsFolder,
          },
          reading,
          activeGuideIdx: 0,
          filteredGuides,
          filteredImages,
          filteredWords,
          data: defaultData,
          learnersIDs,
      };
  }
    

  componentDidMount() {
    document.addEventListener('startGame', this.init);

    const { learnersIDs } = this.state;

    // Get students data for this activity
    if (learnersIDs.length) {
      this.getLearnerData();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('startGame', this.init);
  }

  onExit = (cb) => {
    const { 
      preview, 
      setActivityMastered, 
      setActivityPartial,
      closeWritingFeedback,
    } = this.props;

    closeWritingFeedback();

    if (preview) {
      return cb && cb();
    }

    // Check if user has at least 3 sentences
    const text = this.editorRef.current.getText();
    const regExpResult = text.match(/[\w)](\s+)?[.?!]+/g);
    const sentencesCount = regExpResult ? regExpResult.length : 0;

    this.setState({ exiting: true }, () => {
      if (sentencesCount >= 3) {
        setActivityMastered();
      } else if (sentencesCount > 0) {
        setActivityPartial();
      }

      this.saveChanges(cb);
    });
  };

  getLearnerData = async () => {
    const {
      activity: {
        activity: { _id: activityId },
      },
      noReports
    } = this.props;
    const { learnersIDs } = this.state;

    if (!learnersIDs.length || noReports) return;

    const learnersData = await Promise.all(
      learnersIDs.map((learnerId) =>
        API.game.getLearnerActivityData({
          learnerId,
          activityId,
        })
      )
    );

    // If multiple members logged in, then check that they have same activity data
    const allDataIsTheSame = !!learnersData.reduce(
      (a, b) => JSON.stringify(a) === JSON.stringify(b)
    );

    if (!allDataIsTheSame) return;

    const data = learnersData[0];
    if (!data) return;

    this.editorRef.current.pasteHtml(data.text);

    this.setState({
      data,
    });
  };

  onEditorChange = (html) => {
    const { data = {} } = this.state;

    if (data.text === html) return;

    this.setState({
      hasUnsavedChanges: true,
      data: {
        ...data,
        text: html,
      },
    });
  };

  saveChanges = (cb) => {
    const { activity, learnerId, preview, noReports } = this.props;
    const { data, hasUnsavedChanges, exiting, learnersIDs } = this.state;

    if (preview || !learnerId || !hasUnsavedChanges || noReports) {
      return cb && cb();
    }

    API.game
      .saveLearnerActivityData({
        learnerId: learnersIDs,
        activityId: activity.activity._id,
        data,
      })
      .then((success) => {
        success && !exiting && this.setState({ hasUnsavedChanges: false });
        cb && cb();
      })
      .catch((err) => {
        console.log(err);
        cb && cb();
      });
  };

  onChangeActiveGuide = (idx) => {
    stopAudio();
    this.setState({ activeGuideIdx: idx });
  };

  playGuideAudio = () => {
    const {
      paths: { activityAssetsFolder },
    } = this.state;

    const current = this.getCurrentGuide();
    if (!current || !current.audio) return;

    toggleAudio((current.audioPath || activityAssetsFolder) + current.audio);
  };

  getCurrentGuide = () => {
    const { filteredGuides, activeGuideIdx } = this.state;
    const currentGuide = (filteredGuides || [])[activeGuideIdx];

    return currentGuide;
  };

  onPasteStarter = (html) => {
    const editor = this.editorRef.current;
    if (!editor) return;

    editor.pasteHtml(html);
  };

  print = () => {
    const { data } = this.state;
    const div = document.createElement('div');
    div.style.width = EDITOR_WIDTH + 'px';
    div.style.margin = '0 auto';
    div.innerHTML = data.text;

    printElem(div);
  };

  editorRef = React.createRef();

  render() {
    const {
      data,
      reading,
      exiting,
      activeGuideIdx,
      filteredGuides,
      filteredWords,
      filteredImages,
    } = this.state;

    const currentGuide = this.getCurrentGuide();
    // const { translate } = this.props;

    return (
      <>
        <div
          style={{
            width: divideSize(2000),
            height: divideSize(1280),
            transform: 'translate(-50%, -50%) scale(' + getScale() + ')',
          }}
          className={classnames(classes.wrapper, {
            [classes.noGuide]: !filteredGuides.length,
          })}
        >
          {filteredGuides.length !== 0 && (
            <WhiteBox
              outerStyle={{
                width: divideSize(620),
                fontSize: '14px',
                padding: '15px',
                borderRadius: '30px',
              }}
              innerClass={classes.guideInner}
            >
              <GuideBox
                templateGraphicsFolder={templateGraphicsFolder}
                activeGuideIdx={activeGuideIdx}
                guides={filteredGuides}
                onPasteStarter={this.onPasteStarter}
                onChangeActive={this.onChangeActiveGuide}
              />
            </WhiteBox>
          )}

          <WhiteBox
            outerStyle={{
              width: divideSize(1400),
              fontSize: '20px',
              padding: '15px',
              borderRadius: '30px',
            }}
            innerClass={classes.textInner}
          >
            <WritingAssistantEditor
              reading={reading}
              initialValue={data && data.text}
              saveChanges={this.saveChanges}
              ref={this.editorRef}
              words={filteredWords}
              images={filteredImages}
              changeHandler={this.onEditorChange}
            />
          </WhiteBox>
        </div>

        <WritingFeedback />

        <ActivityButtons
          buttons={[
            {
              type: GO_BACK,
              beforeClickHandler: this.onExit,
              disabled: exiting,
            },
            {
              type: PRINT,
              onClick: this.print,
            },
            {
              type: SPEAK,
              onClick: this.playGuideAudio,
              dontShow: !currentGuide || !currentGuide.audio,
            },
            HELP,
          ]}
        />
      </>
    );
  }
}

WritingAssistant.propTypes = {
  solutionLocale: PropTypes.object,
  learnerId: PropTypes.string,
  setActivityPartial: PropTypes.func.isRequired,
  setActivityMastered: PropTypes.func.isRequired,
  noReports: PropTypes.bool,
};

const mapStateToProps = ({ flinkPlay: { learner, team, learningCenter } }) => ({
  learnerId: learner && learner._id,
  noReports: !!(
    learningCenter &&
    learningCenter.config &&
    learningCenter.config.noReports
  ),
  team,
});

export default connect(mapStateToProps, {
  setActivityPartial,
  setActivityMastered,
  closeWritingFeedback,
})(WritingAssistant);
