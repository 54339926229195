import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';

import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import {
  playCorrectSound,
  playIncorrectSound,
  playWatch,
} from 'actions/audioActions';
import { animations, getInstruction } from 'activity-templates/utils';
import { validateProblem, prepareProblem } from './matchItHelpers';
import {
  ActivityButtons,
  Instructions,
  WhiteBox,
  ProblemsProgress,
  MultiplayerOverlay,
} from 'components/flink-play';
import {
  ItemsDragLayer,
  ItemSlots,
  BoardWithDraggableItems,
} from 'components/flink-play/dnd-components';

import {
  changePlayerTurn,
  incrementPlayerPoints,
} from 'actions/gameActions';

import classes from './MatchIt.module.scss';
import Item from './Item/Item';

const animationSpeed = 1000;
const maxItems = 8;

class MatchIt extends Component {
  /*constructor(props) {
    super(props);

    const { options } = props.activity.data;
    const thisVersion = !props.isPlayVersion ? props.activity.activity.currentLanguageVersion 
    : {version: 'immersion', locale: props.solutionLocale.code};
    const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";    
    const gameData = versionType 
    ? props.activity.data.languageVersionData?.[versionType]
    : props.activity.data.gameData;

    // Remove invalid problems
    let problems = gameData.problems.filter(validateProblem);

    const { questionsLimit, randomOrder } = options;

    problems = randomOrder ? _.shuffle(problems) : problems;

    // Need to trim?
    if (
      questionsLimit &&
      questionsLimit > 0 &&
      questionsLimit < problems.length
    ) {
      problems = _.take(problems, questionsLimit);
    }

    const maxTextLength = _.chain(problems)
      .map((p) => [...p.questions, ...p.correctAnswers, ...p.incorrectAnswers])
      .flatten()
      .map((i) => i && i.text && i.text.length)
      .max()
      .value();

    this.state = {
      maxTextLength,
      problems,
      currentProblem: null,
    };
  }*/

    constructor(props) {
      super(props);
    
      const { options } = props.activity.data;
      const thisVersion = !props.isPlayVersion
        ? props.activity.activity.currentLanguageVersion
        : { version: 'immersion', locale: props.solutionLocale.code };
    
      const versionType =
        thisVersion?.version && thisVersion?.locale
          ? `${thisVersion.version}_${thisVersion.locale}`
          : null;
    
      const languageVersionDataExists =
        versionType && props.activity.data.languageVersionData?.[versionType];
    
      const gameData = languageVersionDataExists
        ? props.activity.data.languageVersionData[versionType]
        : props.activity.data.gameData;
    
      if (!gameData) {
        console.error('Game data is missing or undefined.');
        return;
      }
    
      let problems = gameData.problems.filter(validateProblem);
    
      const { questionsLimit, randomOrder } = options;
    
      problems = randomOrder ? _.shuffle(problems) : problems;
    
      if (questionsLimit && questionsLimit > 0 && questionsLimit < problems.length) {
        problems = _.take(problems, questionsLimit);
      }
    
      const maxTextLength = _.chain(problems)
        .map((p) => [...p.questions, ...p.correctAnswers, ...p.incorrectAnswers])
        .flatten()
        .map((i) => i && i.text && i.text.length)
        .max()
        .value();
    
      this.assetsFolder = languageVersionDataExists
        ? `${props.activity.activity.contentFolder}/languageVersionData/${versionType}/`
        : `${props.activity.activity.contentFolder}/gamedata/`;
    
      this.state = {
        maxTextLength,
        problems,
        currentProblem: null,
      };
    }
      

  componentDidMount() {
    document.addEventListener('startGame', this.startGame);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutBeforeNext);
    document.removeEventListener('audioEnded', this.setupNextQuestion);
    document.removeEventListener('startGame', this.startGame);
  }

  startGame = () => {
    const { problems } = this.state;
    const { translate, getStringAudio } = this.props;

    const currentProblem = problems[0];
    const { items, questions } = prepareProblem(currentProblem, maxItems, {
      translate,
      getStringAudio,
    });

    this.refs = {
      boxes: _.times(items.length).map((idx) => React.createRef()),
      slots: _.times(questions.length).map((idx) => React.createRef()),
    };

    this.setState({
      currentProblem,
      items,
      questions,
      animations: _.shuffle(animations),
      currentIndex: 0,
      isResolved: false,
      problemsCount: problems.length,
      attemptsOnCurrentProblem: 0,
      answeredCorrectly: 0,
      answeredIncorrectly: 0,
      problemsLeft: problems.length,
    });
  };

  onDrop = ({ itemId, slotIdx }) => {
    if (slotIdx !== undefined) {
      this.moveToSlot(itemId, slotIdx);
    } else {
      this.moveToBoard(itemId);
    }
  };

  moveToSlot = (itemId, slotIdx) => {
    const { items } = this.state;

    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        return { ...item, slotIdx: slotIdx, inSlot: true };
      }

      if (item.slotIdx === slotIdx) {
        return { ...item, slotIdx: undefined, inSlot: false };
      }

      return item;
    });

    this.setState({ items: updatedItems });

    this.checkAnswer();
  };

  moveToBoard = (itemId) => {
    const { items } = this.state;

    this.setState({
      items: items.map((item) => {
        if (item.id !== itemId) return item;
        return { ...item, inSlot: false, slotIdx: null };
      }),
    });
  };

  checkAnswer = () => {
    const { items, questions, attemptsOnCurrentProblem } = this.state;
    const { 
      changePlayerTurn, 
      incrementPlayerPoints,
      multiplayerModeEnabled,
    } = this.props;

    const itemsInSlot = _.filter(items, { inSlot: true });

    if (itemsInSlot.length < questions.length) return;

    let allCorrect = true;

    itemsInSlot.forEach((item) => {
      if (!allCorrect) return;

      if (!item.correct) {
        allCorrect = false;
        return;
      }

      const relatedQuestionIdx = _.findIndex(questions, {
        id: item.relatedToId,
      });
      if (item.slotIdx !== relatedQuestionIdx) {
        allCorrect = false;
      }
    });

    if (allCorrect) {
      this.setState({ isResolved: true, shouldChangeTurn: true });
      this.props.playCorrectSound();
      if (multiplayerModeEnabled) {
        incrementPlayerPoints(attemptsOnCurrentProblem);
      };

      document.addEventListener('audioEnded', this.setupNextQuestion, {
        once: true,
      });
      return;
    }

    this.props.playIncorrectSound();
    if (multiplayerModeEnabled) {
      changePlayerTurn();
    };

    this.setState((state) => ({
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
      answeredIncorrectly:
        state.attemptsOnCurrentProblem === 0
          ? state.answeredIncorrectly + 1
          : state.answeredIncorrectly,
    }));
  };

  finishGame = () => {
    const { answeredCorrectly, answeredIncorrectly, problems } = this.state;

    this.props.showStats({
      withScore: true,
      data: {
        allProblemsCount: problems.length,
        problemsAnsweredCorrectly: answeredCorrectly,
        problemsAnsweredIncorrectly: answeredIncorrectly,
      },
    });

    this.setState({ currentProblem: null });
  };

  setupNextQuestion = () => {
    const {
      problemsLeft,
      answeredCorrectly,
      attemptsOnCurrentProblem,
      shouldChangeTurn,
    } = this.state;

    const { delayBeforeNext } = this.props.activity.data.options;
    const { multiplayerModeEnabled, changePlayerTurn } = this.props;

    let newAnsweredCorrectly = answeredCorrectly;

    if (!attemptsOnCurrentProblem) {
      newAnsweredCorrectly++;
    }

    let newProblemsLeft = problemsLeft - 1;

    if (!newProblemsLeft) {
      this.setState({
        answeredCorrectly: newAnsweredCorrectly,
        problemsLeft: newProblemsLeft,
      });

      this.timeoutBeforeNext = setTimeout(() => {
        this.finishGame();
      }, delayBeforeNext * 1000);

      return;
    }

    this.timeoutBeforeNext = setTimeout(() => {
      // Get next question
      this.setState((state) => {
        const nextProblem = state.problems[state.currentIndex + 1];

        const { translate, getStringAudio } = this.props;
        const { items, questions } = prepareProblem(nextProblem, maxItems, {
          translate,
          getStringAudio,
        });

        this.refs.boxes = _.times(items.length).map((idx) => React.createRef());
        this.refs.slots = _.times(questions.length).map((idx) =>
          React.createRef()
        );

        return {
          items,
          questions,
          answeredCorrectly: newAnsweredCorrectly,
          attemptsOnCurrentProblem: 0,
          problemsLeft: newProblemsLeft,
          isResolved: false,
          currentProblem: nextProblem,
          currentIndex: state.currentIndex + 1,
        };
      });

      if (multiplayerModeEnabled && shouldChangeTurn) changePlayerTurn();
    }, delayBeforeNext * 1000);
  };

  showAnswer = () => {
    const { items, questions } = this.state;
    let changedItems = [...items];

    changedItems = changedItems.map((item) => {
      if (!item.correct && item.inSlot) {
        return {
          ...item,
          moveFrom: this.refs.slots[item.slotIdx].current,
          inSlot: false,
          slotIdx: null,
        };
      }

      const relatedQuestionIdx = _.findIndex(questions, {
        id: item.relatedToId,
      });

      if (item.correct && !item.inSlot) {
        return {
          ...item,
          moveFrom: this.refs.boxes[item.boxIdx].current,
          inSlot: true,
          slotIdx: relatedQuestionIdx,
        };
      }

      if (item.correct && item.inSlot && item.slotIdx !== relatedQuestionIdx) {
        return {
          ...item,
          moveFrom: this.refs.slots[item.slotIdx].current,
          inSlot: true,
          slotIdx: relatedQuestionIdx,
        };
      }

      return item;
    });

    console.log(changedItems);

    this.props.playWatch();
    this.setState((state) => ({
      items: [...changedItems],
      isResolved: true,
      shouldChangeTurn: false,
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
    }));
    document.addEventListener('audioEnded', this.setupNextQuestion, {
      once: true,
    });
  };

  render() {
    const { solutionLocale, translate, getStringAudio } = this.props;
    const {
      isResolved,
      problems,
      problemsLeft,
      maxTextLength,
      currentProblem,
      attemptsOnCurrentProblem,
    } = this.state;

    const instruction = getInstruction(
      currentProblem, 
      solutionLocale,
      undefined, undefined, undefined,
      { translate, getStringAudio },
    );

    const { activity, multiplayerModeEnabled } = this.props;
    const { options } = activity.data;

    const textStyle = currentProblem
      ? {
          fontFamily: currentProblem.fontFamily,
          color: currentProblem.fontColor,
        }
      : {};

    return (
      <>
        <div
          style={textStyle}
          className={classnames(classes.text, {
            [classes.textUpTo1]: maxTextLength === 1,
            [classes.textUpTo2]: maxTextLength === 2,
            [classes.textUpTo4]: maxTextLength > 2 && maxTextLength <= 4,
            [classes.textUpTo6]: maxTextLength > 4 && maxTextLength <= 6,
            [classes.textUpTo10]: maxTextLength > 6 && maxTextLength <= 10,
          })}
        >
          <Instructions problem={currentProblem} />

          {this.renderBlocks()}

          {problems && (
            <ProblemsProgress
              problemsNumber={problems.length}
              problemsLeft={problemsLeft}
            />
          )}
        </div>

        {multiplayerModeEnabled && (
          <MultiplayerOverlay /> 
        )}

        <ActivityButtons
          buttons={[
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.CORRECT_ANSWER,
              onClick: () => this.showAnswer(),
              dontShow:
                multiplayerModeEnabled ||
                isResolved ||
                options.showAnswer === 'n/a' ||
                +options.showAnswer > attemptsOnCurrentProblem,
            },
            {
              type: buttonsTypes.SPEAK,
              dontShow: !instruction.audio || isResolved,
            },
            buttonsTypes.HELP,
          ]}
        />
      </>
    );
  }

  renderBlocks = () => {
    const {
      items,
      questions,
      isResolved,
      animations,
      currentIndex,
      currentProblem,
    } = this.state;

    if (!currentProblem || !items || !questions) return null;

    const { fontFamily, fontColor } = currentProblem;
    const {
      activity: {
        data: { options },
      },
    } = this.props;

    const textStyle = { fontFamily, color: fontColor };

    return (
      <>
        <ItemsDragLayer ItemComponent={Item} />

        <WhiteBox
          outerClass={classes.boardOuter}
          innerClass={classes.boardInner}
        >
          <div className={classes.questionsWrapper}>
            {questions.map((question) => (
              <div key={question.id} className={classes.questionItemWrapper}>
                <Item data={question.data} />
              </div>
            ))}
          </div>

          <ItemSlots
            customClasses={{
              positionedBox: classes.slotsWrapper,
              wrapper: classes.slotsWrapper,
              inner: classes.slotsInner,
              slot: classes.slot,
              fixedSlot: classes.fixedSlot,
            }}
            options={options}
            animationSpeed={animationSpeed}
            isResolved={isResolved}
            animation={animations[currentIndex % animations.length]}
            count={questions.length}
            onDrop={this.onDrop}
            items={items}
            refs={this.refs.slots}
            ItemComponent={Item}
          />

          <BoardWithDraggableItems
            customClasses={{
              boardOuter: classes.answersWrapper,
              boardInner: classes.answersInner,
              box: classes.boardBox,
            }}
            isResolved={isResolved}
            boxesCount={items.length}
            onDrop={this.onDrop}
            items={items}
            textStyle={textStyle}
            refs={this.refs.boxes}
            ItemComponent={Item}
          />
        </WhiteBox>
      </>
    );
  };
}

export default connect(null, {
  playWatch,
  playCorrectSound,
  playIncorrectSound,
  showStats,
  changePlayerTurn,
  incrementPlayerPoints,
})(MatchIt);
