import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import shortid from 'shortid';

import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import { playAudio, toggleAudio, playWatch } from 'actions/audioActions';
import { s3bucketPublicURL } from 'config';
import { validateQuestion, validateAnswer } from './movingAnswersHelpers';
import {
  ActivityButtons,
  ActivityImage,
  InstructionsTextBlock,
  ProblemsProgress,
  MultiplayerOverlay,
} from 'components/flink-play';

import {
  changePlayerTurn,
  incrementPlayerPoints,
} from 'actions/gameActions';

import AnswersBlock from './AnswersBlock/AnswersBlock';
import { animations as stopItAnimations } from 'activity-templates/utils';
import './MovingAnswers.scss';

const keepGoingAnimations = [
  'leftToRight',
  'rightToLeft',
  'topToBottom',
  'bottomToTop',
];

const formatQuestion = (question) => {
  if (!question) return null;

  return { ...question, answers: shuffleAnswers(question) };
};

const shuffleAnswers = (question) =>
  _.chain(question.incorrectAnswers)
    .filter((answer) => validateAnswer(answer))
    .map((answer) => ({ ...answer, id: shortid.generate(), correct: false }))
    .concat({ ...question.correct, id: shortid.generate(), correct: true })
    .shuffle() // shuffle answers
    .value();

class MovingAnswers extends Component {
  constructor(props) {
    super(props);

    /*const { options } = props.activity.data;

    const thisVersion = !props.isPlayVersion ? props.activity.activity.currentLanguageVersion 
    : {version: 'immersion', locale: props.solutionLocale.code};
   // const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";  
    const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";  
 
    const gameData = versionType 
    ? props.activity.data.languageVersionData?.[versionType]
    : props.activity.data.gameData;

    // Remove invalid questions
    let problems = gameData.problems.filter(validateQuestion);

    const { questionsLimit, randomOrder } = options;

    problems = randomOrder ? _.shuffle(problems) : problems;

    // Need to trim?
    if (
      questionsLimit &&
      questionsLimit > 0 &&
      questionsLimit < problems.length
    ) {
      problems = _.take(problems, questionsLimit);
    }

    const isKeepGoingAnimations = !gameData.longAnswers && !gameData.withAudio;
    const animations = isKeepGoingAnimations
      ? keepGoingAnimations
      : stopItAnimations;

    const maxFontSize = gameData.longAnswers && 36;

    this.state = {
      maxFontSize,
      animations: _.shuffle(animations),
      problems,
      currentQuestion: null,
      gameData,
      versionType
    };
  }*/

    const thisVersion = !props.isPlayVersion
  ? props.activity.activity.currentLanguageVersion
  : { version: 'immersion', locale: props.solutionLocale.code };

const versionType =
  thisVersion?.version && thisVersion?.locale
    ? `${thisVersion.version}_${thisVersion.locale}`
    : null;

const languageVersionDataExists =
  versionType && props.activity.data.languageVersionData?.[versionType];

const gameData = languageVersionDataExists
  ? props.activity.data.languageVersionData[versionType]
  : props.activity.data.gameData;

if (!gameData) {
  console.error('Game data is missing. Unable to proceed.');
  return;
}

let assetsFolder;
if (languageVersionDataExists) {
  const versionPath = `${s3bucketPublicURL}/${props.activity.activity.contentFolder}/languageVersionData/${versionType}/`;
  
  if (props.activity.activity.contentFolder && props.activity.data.languageVersionData?.[versionType]) {
    assetsFolder = versionPath;
  } else {
    console.warn(`Language version data does not exist for ${versionType}, falling back to gamedata.`);
    assetsFolder = `${s3bucketPublicURL}/${props.activity.activity.contentFolder}/gamedata/`;
  }
} else {
  assetsFolder = `${s3bucketPublicURL}/${props.activity.activity.contentFolder}/gamedata/`;
}

console.log('Assets Folder:', assetsFolder);

const problems = Array.isArray(gameData.problems)
  ? gameData.problems.filter(validateQuestion)
  : [];

const { questionsLimit, randomOrder } = props.activity.data.options;

const finalProblems = randomOrder ? _.shuffle(problems) : problems;

const limitedProblems =
  questionsLimit && questionsLimit > 0 && questionsLimit < finalProblems.length
    ? _.take(finalProblems, questionsLimit)
    : finalProblems;

console.log('Problems:', limitedProblems);

const isKeepGoingAnimations =
  !gameData.longAnswers && !gameData.withAudio;
const animations = isKeepGoingAnimations
  ? keepGoingAnimations
  : stopItAnimations;

const maxFontSize = gameData.longAnswers ? 36 : undefined;

this.state = {
  maxFontSize,
  animations: _.shuffle(animations),
  problems: limitedProblems,
  currentQuestion: null,
  gameData,
  versionType,
  assetsFolder,
};

  }

  componentDidMount() {
    document.addEventListener('startGame', this.startGame);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutBeforeNext);
    document.removeEventListener('audioEnded', this.setupNextQuestion);
    document.removeEventListener('startGame', this.startGame);
  }

  startGame = () => {
    const { problems, gameData } = this.state;
    //const { gameData } = this.props.activity.data;

    const currentQuestion = formatQuestion(problems[0]);

    this.setState({
      currentQuestion,
      currentIndex: 0,
      resolved: false,
      problemsCount: problems.length,
      attemptsOnCurrentQuestion: 0,
      answeredCorrectly: 0,
      answeredIncorrectly: 0,
      isActivityImageShow: gameData.activityImageDefaultOpen || false,
      questionsLeft: problems.length,
    });

    this.playAudioQuestion(currentQuestion);
  };

  /*playAudioQuestion = (question) => {
    let questionToPlay = question || this.state.currentQuestion;
    const { versionType } = this.state;
    if (!questionToPlay || !questionToPlay.audio) return;

    const {
      activity: {
        activity: { contentFolder },
      },
    } = this.props;

    const audioUrl = versionType 
    ? `${s3bucketPublicURL}/${contentFolder}/languageVersionData/${versionType}/${questionToPlay.audio}`
    : `${s3bucketPublicURL}/${contentFolder}/gamedata/${questionToPlay.audio}`;

    // if no question passed then SPEAK button was clicked -> then toggle audio
    !question ? toggleAudio(audioUrl) : playAudio(audioUrl);
  };*/

    playAudioQuestion = (question) => {
      let questionToPlay = question || this.state.currentQuestion;
      const { versionType, languageVersionDataExists } = this.state;

      if (!questionToPlay || !questionToPlay.audio) return;

      const {
          activity: {
              activity: { contentFolder },
          },
      } = this.props;

      const audioUrl = languageVersionDataExists
          ? `${s3bucketPublicURL}/${contentFolder}/languageVersionData/${versionType}/${questionToPlay.audio}`
          : `${s3bucketPublicURL}/${contentFolder}/gamedata/${questionToPlay.audio}`;

      !question ? toggleAudio(audioUrl) : playAudio(audioUrl);
  };


  finishGame = () => {
    const { answeredCorrectly, answeredIncorrectly, problems } = this.state;
    this.props.showStats({
      withScore: true,
      data: {
        allProblemsCount: problems.length,
        problemsAnsweredCorrectly: answeredCorrectly,
        problemsAnsweredIncorrectly: answeredIncorrectly,
      },
    });

    this.setState({ isActivityImageShow: false });
  };

  setupNextQuestion = (callback) => {
    const {
      questionsLeft,
      answeredCorrectly,
      attemptsOnCurrentQuestion,
    } = this.state;
    const { delayBeforeNext } = this.props.activity.data.options;

    let newAnsweredCorrectly = answeredCorrectly;

    if (!attemptsOnCurrentQuestion) {
      newAnsweredCorrectly++;
    }

    let newQuestionsLeft = questionsLeft - 1;

    if (!newQuestionsLeft) {
      this.setState(
        {
          answeredCorrectly: newAnsweredCorrectly,
          questionsLeft: newQuestionsLeft,
          currentQuestion: null,
        },
        this.finishGame
      );
    } else {
      this.timeoutBeforeNext = setTimeout(() => {
        // Get next question
        this.setState((state) => {
          const nextQuestion = formatQuestion(
            state.problems[state.currentIndex + 1]
          );

          this.playAudioQuestion(nextQuestion);

          return {
            answeredCorrectly: newAnsweredCorrectly,
            attemptsOnCurrentQuestion: 0,
            questionsLeft: newQuestionsLeft,
            resolved: false,
            currentQuestion: nextQuestion,
            currentIndex: state.currentIndex + 1,
          };
        });

        if (callback) callback();
      }, delayBeforeNext * 1000);

    }
  };

  showAnswer = () => {
    this.setState((state) => ({
      resolved: true,
      attemptsOnCurrentQuestion: state.attemptsOnCurrentQuestion + 1,
    }));

    this.props.playWatch();
    document.addEventListener('audioEnded', this.setupNextQuestion, {
      once: true,
    });
  };

  answerClickHandler = (answer) => {
    const { currentQuestion, attemptsOnCurrentQuestion } = this.state;
    const { 
      changePlayerTurn, 
      incrementPlayerPoints,
      multiplayerModeEnabled,
    } = this.props;

    const feedback = { isCorrect: answer.correct };

    if (currentQuestion.specificFeedback) {
      feedback.audio = answer.feedbackAudio;
      feedback.text = answer.feedbackText;
    }

    if (answer.correct) {
      this.setState({ resolved: true });
      if (multiplayerModeEnabled) {
        incrementPlayerPoints(attemptsOnCurrentQuestion);
      };

      feedback.onClose = () => {
        this.setupNextQuestion(() => {
          if (multiplayerModeEnabled) {
            changePlayerTurn();
          };
        });
      };
    } else {
      if (multiplayerModeEnabled) {
        changePlayerTurn();
      };

      this.setState((state) => ({
        attemptsOnCurrentQuestion: state.attemptsOnCurrentQuestion + 1,
        answeredIncorrectly:
          state.attemptsOnCurrentQuestion === 0
            ? state.answeredIncorrectly + 1
            : state.answeredIncorrectly,
      }));
    }

    this.props.showFeedback(feedback);
  };

  showActivityImage = (show) => {
    this.setState({ isActivityImageShow: show });
  };

  render() {
    const {
      resolved,
      problems,
      animations,
      maxFontSize,
      currentIndex,
      questionsLeft,
      currentQuestion,
      isActivityImageShow,
      attemptsOnCurrentQuestion,
    } = this.state;

    const { activity, textStyle: propsTextStyle, multiplayerModeEnabled } = this.props;
    const { showAnswer, showQuestion } = activity.data.options;

    const textStyle =
      maxFontSize && parseInt(propsTextStyle.fontSize) > maxFontSize
        ? { ...propsTextStyle, fontSize: maxFontSize + 'px' }
        : propsTextStyle;

    return (
      <div className="moving-answers-template">
        {showQuestion && currentQuestion && (
          <InstructionsTextBlock
            textStyle={textStyle}
            text={
              currentQuestion.fillIn && !resolved
                ? currentQuestion.problem.replace(
                    currentQuestion.correct.text,
                    '_______'
                  )
                : currentQuestion.problem
            }
          />
        )}

        {/* {showQuestion && (
          <div className="question-block">
            {currentQuestion && (
              <span style={textStyle} className="question-block__question">
                {currentQuestion.fillIn && !resolved
                  ? currentQuestion.problem.replace(
                      currentQuestion.correct.text,
                      '_______'
                    )
                  : currentQuestion.problem}
              </span>
            )}
          </div>
        )} */}

        {problems && (
          <ProblemsProgress
            problemsNumber={problems.length}
            problemsLeft={questionsLeft}
          />
        )}

        <AnswersBlock
          animation={animations && animations[currentIndex % animations.length]}
          activity={activity}
          resolved={resolved}
          multiplayer={multiplayerModeEnabled}
          question={currentQuestion}
          textStyle={textStyle}
          answerClickHandler={this.answerClickHandler}
        />

        {multiplayerModeEnabled && (
          <MultiplayerOverlay /> 
        )}

        <ActivityButtons
          buttons={[
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.ACTIVITY_IMAGE,
              onClick: () => this.showActivityImage(true),
              dontShow: !activity.data.activityImage,
            },
            {
              type: buttonsTypes.CORRECT_ANSWER,
              onClick: () => this.showAnswer(),
              dontShow:
                multiplayerModeEnabled ||
                resolved ||
                showAnswer === 'n/a' ||
                +showAnswer > attemptsOnCurrentQuestion,
            },
            {
              type: buttonsTypes.SPEAK,
              onClick: () => this.playAudioQuestion(),
              dontShow: !currentQuestion || !currentQuestion.audio || resolved,
            },
            buttonsTypes.HELP,
          ]}
        />

        {activity.data.activityImage && (
          <ActivityImage
            closeHandler={() => this.showActivityImage(false)}
            activity={activity}
            show={isActivityImageShow}
          />
        )}
      </div>
    );
  }
}

export default connect(null, {
  playWatch,
  showStats,
  changePlayerTurn,
  incrementPlayerPoints,
})(MovingAnswers);
