import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import shortid from 'shortid';

import { uploading } from 'actions/statusActions';
import {
  changeActivityData,
  saveEditingActivity,
} from 'actions/flinkMakeActions';
import { PreviewImage, AddImageDialog } from 'components/flink-components';
import RadioGroup from 'components/flink-components/ActivityOptions/RadioGroup';
import { imagesURL, uploadsURL, activitiesKey } from 'config';
import API from 'api';

import classes from './AddImageBlock.module.scss';

class AddImageBlock extends Component {
  state = {
    showPreview: false,
    showPick: false,
  };

  closeModals = () => {
    if (this.state.showPreview || this.state.showPick) {
      this.setState({ showPreview: false, showPick: false });
    }
  };

  getPath = () =>{
    const { versionType } = this.props;

    if (versionType)
      return `${activitiesKey}/${this.props.editingActivity.activity._id}/languageVersionData/${versionType}`;
    else
      return `${activitiesKey}/${this.props.editingActivity.activity._id}/gamedata`;
  }

  getCurrent = () => {
    const { editingActivity, pathToProp } = this.props;

    return _.get(editingActivity.data, pathToProp);
  };

  deleteHandler = async () => {
    const path = this.getPath();
    const current = this.getCurrent();

    this.saveChanges(null);
    this.closeModals();

    const key = `${path}/${current}`;

    await API.storage.deleteFiles([key]);
  };

  uploadHandler = async (file) => {
    if (!file) return null;

    const { uploading, fileName, withId } = this.props;

    const path = this.getPath();

    const [, originFileName, extension] = file.name.match(/\/?([^/]+)\.(\S+)$/);

    const fileNameWithExt = `${fileName ? fileName : originFileName}${withId ? '_' + shortid.generate() : ''
      }.${extension}`;

    const key = `${path}/${fileNameWithExt}`;

    const success = await API.storage.uploadFile(key, file, {
      onUploadProgress: uploading,
    });

    uploading(null);

    return success ? fileNameWithExt : null;
  };

  changeHandler = async ({ file }) => {
    const current = this.getCurrent();

    // New file, need to upload
    const filename = await this.uploadHandler(file);

    if (current && current !== filename) {
      // remove previous image
      await this.deleteHandler();
    } else {
      this.closeModals();
    }

    this.saveChanges(filename);
  };

  saveChanges = (filename) => {
    const {
      changeActivityData,
      onChangeHandler,
      saveEditingActivity,
      pathToProp,
    } = this.props;

    changeActivityData(pathToProp, filename);
    onChangeHandler && onChangeHandler(filename);
    saveEditingActivity();
  };

  showPreviewModal = (showPreview) =>
    this.setState({ showPreview, showPick: false });

  showImagePickModal = (showPick) =>
    this.setState({ showPreview: false, showPick });

  render() {
    const { showPreview, showPick } = this.state;

    const {
      maxSize,
      translate,
      showFilename,
      withOverlay,
      withSize,
      withImageDefaultOpenCheckbox,
      withTestBtn,
      withoutTitle,
      withoutPreview,
      changeActivityData,
      editingActivity,
      disabled,
      versionType
    } = this.props;

    const path = this.getPath();
    const current = this.getCurrent();

    let currentSrc = current ? `${uploadsURL}/${path}/${current}` : null;

    return (
      <>
        <div className={classes.wrapper}>
          {!withoutPreview && (
            <div className={classes.imageAnswerPreview}>
              {currentSrc && <img src={currentSrc} alt="" />}
            </div>
          )}

          <div
            className={`${classes.addBtnWrapper} ${disabled ? classes.disabled : ''
              }`}
          >
            {!withoutTitle && (
              <span className={classes.btnTitle}>
                {translate(17, 'Add Image')}
              </span>
            )}
            <button
              disabled={disabled}
              className={disabled ? '' : 'btn-effect'}
              title={translate(17, 'Add Image', true)}
              onClick={() => this.showImagePickModal(!showPick)}
            >
              <img src={`${imagesURL}/FrontendUtilities/addimage.png`} alt="" />
            </button>
          </div>

          <AddImageDialog
            withOverlay={withOverlay}
            show={showPick}
            closeHandler={() => this.showImagePickModal(false)}
            onPick={this.changeHandler}
            maxSize={maxSize}
            path={path}
          />

          {current && (
            <>
              {showFilename && (
                <span className={classes['text']}>{current}</span>
              )}
              <button
                className={`btn-effect ${classes['delete-button']}`}
                onClick={this.deleteHandler}
                title={translate(153, 'Delete Image')}
              >
                <img
                  src={`${imagesURL}/FrontendUtilities/delete1.png`}
                  alt=""
                />
              </button>

              {withTestBtn && (
                <div className={classes['test']}>
                  <span className={classes['text']}>
                    {translate(444, 'Test')}
                  </span>
                  <button
                    className={`btn-effect`}
                    onClick={() => this.showPreviewModal(!showPreview)}
                    title={translate(444, 'Test', true)}
                  >
                    <img
                      src={`${imagesURL}/FlinkMake/arrowright.png`}
                      alt="Test"
                    />
                  </button>
                </div>
              )}
            </>
          )}

          <div className={classes.spacer} />

          <div className={classes.row}>
            {withImageDefaultOpenCheckbox && (
              <div className={classes.imgDefaultOpen}>
                <input
                  type="checkbox"
                  id="activity-image-open-by-default"
                  checked={versionType 
                  ? editingActivity.data.languageVersionData?.[versionType].activityImageDefaultOpen 
                  : editingActivity.data.gameData.activityImageDefaultOpen || false}
                  onChange={e => {
                    if (versionType){
                      changeActivityData(`languageVersionData[${versionType}].activityImageDefaultOpen`,
                       e.target.checked)
                    }
                    else{
                      changeActivityData(`gameData.activityImageDefaultOpen`, e.target.checked)
                    }
                  }}
                />
                <label htmlFor="activity-image-open-by-default">
                  {translate(0, 'Auto-open Activity Image')}
                </label>
              </div>
            )}
            {withSize && (
              <div>
                <span className={classes.formLabel}>Activity Image Size</span>
                <RadioGroup
                  name={versionType ? `languageVersionData[${versionType}].activityImageSize` 
                  : "gameData.activityImageSize"}
                  changeHandler={changeActivityData}
                  current={editingActivity.data}
                  options={[
                    {
                      label: 'Large',
                      value: 'large',
                    },
                    {
                      label: 'Small',
                      value: 'small',
                    },
                  ]}
                />
              </div>
            )}
          </div>

          <PreviewImage
            closeHandler={() => this.showPreviewModal(false)}
            show={showPreview}
            imageSrc={currentSrc}
            size={{ width: '30vmin' }}
            withOverlay
          />
        </div>
      </>
    );
  }
}

AddImageBlock.propTypes = {
  editingActivity: PropTypes.object.isRequired,
  changeActivityData: PropTypes.func.isRequired,
  pathToProp: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  saveEditingActivity: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  showFilename: PropTypes.bool,
  withTestBtn: PropTypes.bool,
  withOverlay: PropTypes.bool,
  withSize: PropTypes.bool,
  withImageDefaultOpenCheckbox: PropTypes.bool,
  withoutPreview: PropTypes.bool,
  withoutTitle: PropTypes.bool,
  withId: PropTypes.bool,
  versionType: PropTypes.string
};

const mapStateToProps = ({
  status: { translate },
  flinkMake: {
    activity: { editingActivity },
  },
}) => ({
  translate,
  editingActivity,
});

export default compose(
  connect(mapStateToProps, {
    uploading,
    changeActivityData,
    saveEditingActivity,
  })
)(AddImageBlock);
