import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import {
  changeGameData,
  saveEditingActivity,
  removeContentFiles,
  changeLanguageVersionData 
} from "actions/flinkMakeActions";
import {
  SelectModal,
  InstructionsBlock,
  MultiLocaleControls
} from "components/flink-components";
import {
  StringAssignField,
} from "components/form-components";

import { withGetStringAudio } from "components/hocs";

import classes from "./MultiLocaleInstructions.module.scss";

export class MultiLocaleInstructions extends Component {
  state = {
    selectLocaleModalOptions: null
  };

  componentDidMount() {
    this.setDefaultLocale();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.problemPath !== this.props.problemPath) {
      this.setDefaultLocale();
    }
  }

  setDefaultLocale = () => {
    const problem = this.getProblem();

    const { multiLocaleInstructions } = problem;
    const { versionType } = this.props;

    if (versionType){
      this.setState({
        selectedLocale: versionType.split('_')[1],
        selectLocaleModalOptions: null
      });
    }
    else{
      this.setState({
        selectedLocale: multiLocaleInstructions
          ? multiLocaleInstructions.defaultLocale
          : "",
        selectLocaleModalOptions: null
      });
    }   
  };

  getProblem = () => {
    const { gameData, problemPath, languageVersionData, versionType } = this.props;
    if (versionType)
      return (problemPath ? _.get(languageVersionData?.[versionType], problemPath) 
        : languageVersionData?.[versionType]) || {};
    else
      return (problemPath ? _.get(gameData, problemPath) : gameData) || {};    
  };

  makeMultiHandler = locale => {
    const { changeGameData, textPath, audioPath, stringPath, changeLanguageVersionData, versionType } = this.props;
    const problem = this.getProblem();

    this.setState({
      selectLocaleModalOptions: null,
      selectedLocale: locale
    });

    this.changeMultiLocaleInstructions({
      [locale]: {
        [textPath]: problem[textPath],
        [audioPath]: problem[audioPath],
        [stringPath]: problem[stringPath],
      },
      defaultLocale: locale
    });

    const pathPrefix = this.getPathPrefix();

    if (versionType){
      changeLanguageVersionData(versionType + '.' + pathPrefix + textPath, "");
      changeLanguageVersionData(versionType + '.' + pathPrefix + audioPath, "");
      changeLanguageVersionData(versionType + '.' + pathPrefix + stringPath, "");
    }
    else{
      changeGameData(pathPrefix + textPath, "");
      changeGameData(pathPrefix + audioPath, "");
      changeGameData(pathPrefix + stringPath, "");
    }    
  };

  makeSingleHandler = locale => {
    const {
      changeGameData,
      removeContentFiles,
      audioPath,
      textPath,
      stringPath,
      changeLanguageVersionData,
      versionType
    } = this.props;

    const problem = this.getProblem();
    const { multiLocaleInstructions } = problem;

    this.setState({
      selectLocaleModalOptions: null
    });

    const audioFilesToDelete = _.chain(multiLocaleInstructions)
      .map((val, key) => {
        if (key === "defaultLocale" || key === locale) return null;
        return val && val[audioPath];
      })
      .compact()
      .value();

    // Add audio files to remove list
    removeContentFiles(audioFilesToDelete);

    const textValue =
      multiLocaleInstructions[locale] &&
      multiLocaleInstructions[locale][textPath];
    const audioValue =
      multiLocaleInstructions[locale] &&
      multiLocaleInstructions[locale][audioPath];
    const stringValue =
      multiLocaleInstructions[locale] &&
      multiLocaleInstructions[locale][stringPath];

    const pathPrefix = this.getPathPrefix();

    if (versionType){
      changeLanguageVersionData(versionType + '.' + pathPrefix + textPath, textValue || "");
      changeLanguageVersionData(versionType + '.' + pathPrefix + audioPath, audioValue || "");
      changeLanguageVersionData(versionType + '.' + pathPrefix + stringPath, stringValue || "");
    }
    else{
      changeGameData(pathPrefix + textPath, textValue || "");
      changeGameData(pathPrefix + audioPath, audioValue || "");
      changeGameData(pathPrefix + stringPath, stringValue || "");
    }

    this.changeMultiLocaleInstructions(null);
  };

  closeSelectLocaleModal = () => {
    this.setState({
      selectLocaleModalOptions: null
    });
  };

  changeInstructions = (path, data) => {
    const { selectedLocale } = this.state;
    const { changeGameData, changeLanguageVersionData, versionType } = this.props;

    const problem = this.getProblem();

    const { multiLocaleInstructions } = problem;

    if (versionType){
      if (multiLocaleInstructions) {
        changeLanguageVersionData(versionType + '.' + this.getPathPrefix() 
          + `multiLocaleInstructions.${selectedLocale}.${path}`, data);
      } else {
        changeLanguageVersionData(versionType + '.' + this.getPathPrefix() + path, data);
      }
    }
    else{
        if (multiLocaleInstructions) {
          if (!selectedLocale) {
            return alert("No selected locale");
          }

        // const dataToSet = {
        //   ...multiLocaleInstructions,
        //   [selectedLocale]: {
        //     ...multiLocaleInstructions[selectedLocale],
        //     [path]: data
        //   }
        // };
        // this.changeMultiLocaleInstructions(dataToSet);

        changeGameData(this.getPathPrefix() + `multiLocaleInstructions.${selectedLocale}.${path}`, data);

      } else {
        changeGameData(this.getPathPrefix() + path, data);
      }
    }    
  };

  getPathPrefix = () => {
    const { problemPath } = this.props;
    const pathPrefix = problemPath ? problemPath + "." : "";
    return pathPrefix;
  };

  changeLocaleHandler = locale => {
    this.setState({ selectedLocale: locale });
  };

  changeMultiLocaleInstructions = data => {
    const { changeGameData, changeLanguageVersionData, versionType } = this.props;
    if (versionType){
      changeLanguageVersionData(versionType + '.' + this.getPathPrefix() + "multiLocaleInstructions", data);
    }
    else{
      changeGameData(this.getPathPrefix() + "multiLocaleInstructions", data);
    }    
  };

  setCurrentLocaleAsDefault = () => {
    const { selectedLocale } = this.state;

    const problem = this.getProblem();

    this.changeMultiLocaleInstructions({
      ...problem.multiLocaleInstructions,
      defaultLocale: selectedLocale
    });
  };

  render() {
    const {
      translate,
      getStringAudio,
      textPath,
      audioPath,
      stringPath,
      withoutAudio,
      maxCharacters,
      withStringsPicker,
      versionType
    } = this.props;

    const { selectLocaleModalOptions, selectedLocale } = this.state;

    const problem = this.getProblem();

    const { multiLocaleInstructions } = problem;

    const currentLocaleInstructions =
      multiLocaleInstructions &&
      (multiLocaleInstructions[selectedLocale] || {});

    // current path value (if using strings, for audio)
    let currentPathValue;

    let currentTextValue = problem[textPath];
    let currentAudioValue = problem[audioPath];
    let currentStringValue = problem[stringPath];

    if (currentLocaleInstructions) {
      currentTextValue = currentLocaleInstructions[textPath];
      currentAudioValue = currentLocaleInstructions[audioPath];
      currentStringValue = currentLocaleInstructions[stringPath];
    }

    if (currentStringValue) {
      const stringAudio = getStringAudio(currentStringValue);
      currentTextValue = translate(currentStringValue, '', true);
      
      if (versionType)
        currentTextValue = translate(currentStringValue, '', false, true, versionType.split('_')[1]);
        
      currentAudioValue = stringAudio.audio;
      currentPathValue = stringAudio.path;
    }
    console.log('currentAudioValue: ', currentAudioValue)

    return (
      <div className={classes.wrapper}>
        <label className={classes.label}>
          {translate(432, "Instructions")}
        </label>

        {withStringsPicker && !multiLocaleInstructions && (
          <StringAssignField
            value={currentStringValue}
            changeAudioHandler={filename => this.changeInstructions(audioPath, filename)}
            changeTextHandler={string => {
              this.changeInstructions(textPath, `${string || ""}`);
              this.changeInstructions(stringPath, string);
            }}
            withoutAudio={withoutAudio}
            localeOverride={versionType ? versionType.split('_')[1] : selectedLocale}
            isTooltip
          />
        )}

        {!versionType &&
          <MultiLocaleControls
            selectedLocale={selectedLocale}
            multiLocaleData={multiLocaleInstructions}
            stringData={currentStringValue}
            changeLocaleHandler={this.changeLocaleHandler}
            makeDefaultHandler={this.setCurrentLocaleAsDefault}
            makeSingleHandler={this.makeSingleHandler}
            makeMultiHandler={this.makeMultiHandler}
            makeSingleDialogText={translate(
              0,
              "Which instructions to use as single? Warning all other instructions will be deleted including audio files"
            )}
            makeMultiDialogText={translate(
              0,
              "Select language of current instructions"
            )}
            classes={{ wrapper: classes.multiLocaleControls }}
          />
        }

        <InstructionsBlock
          maxCharacters={maxCharacters}
          withoutAudio={withoutAudio}
          textValue={currentTextValue}
          stringValue={currentStringValue}
          onTextChange={val => this.changeInstructions(textPath, val)}
          audioValue={currentAudioValue}
          pathValue={currentPathValue}
          onAudioChange={filename =>
            this.changeInstructions(audioPath, filename)
          }
          disabled={withStringsPicker && !!currentStringValue}
          versionType={versionType}
        // label={translate(432, "Instructions")}
        />

        {selectLocaleModalOptions && (
          <SelectModal
            show
            title={translate(251, "Select a locale")}
            options={selectLocaleModalOptions.options}
            selectHandler={selectLocaleModalOptions.cb}
            html={selectLocaleModalOptions.html}
            onClose={this.closeSelectLocaleModal}
          />
        )}
      </div>
    );
  }
}

MultiLocaleInstructions.defaultProps = {
  textPath: "instructionText",
  audioPath: "instructionAudio",
  stringPath: "instructionString",
  withoutAudio: false,
  withStringsPicker: false,
};

MultiLocaleInstructions.propTypes = {
  withoutAudio: PropTypes.bool,
  withStringsPicker: PropTypes.bool,
  gameData: PropTypes.object.isRequired,
  textPath: PropTypes.string,
  audioPath: PropTypes.string,
  problemPath: PropTypes.string,
  saveEditingActivity: PropTypes.func.isRequired,
  removeContentFiles: PropTypes.func.isRequired,
  changeGameData: PropTypes.func.isRequired,
  getStringAudio: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  changeLanguageVersionData: PropTypes.func.isRequired,
  versionType: PropTypes.string,
  languageVersionData: PropTypes.object,
};

const mapStateToProps = ({ status, common, flinkMake: { activity } }) => ({
  gameData: activity.editingActivity.data.gameData,
  translate: status.translate,
  languageVersionData: activity.editingActivity.data.languageVersionData
});

export default compose(
  withGetStringAudio,
  connect(mapStateToProps, {
    changeGameData,
    saveEditingActivity,
    removeContentFiles,
    changeLanguageVersionData 
  })
)(MultiLocaleInstructions);
