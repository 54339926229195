import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import shortId from 'shortid';

import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import {
  toggleAudio,
  playCorrectSound,
  playIncorrectSound,
  playWatch,
} from 'actions/audioActions';
import { animations } from 'activity-templates/utils';
import { s3bucketPublicURL } from 'config';
import { validateProblem } from './spellingConnectablesHelpers';
import {
  ActivityButtons,
  ProblemsProgress,
  ItemsDragLayer,
  ItemSlots,
  ProblemImage,
  BoardWithDraggableItems,
  ConnectablesProblemDefinition,
  MultiplayerOverlay,
} from 'components/flink-play';

import {
  changePlayerTurn,
  incrementPlayerPoints,
} from 'actions/gameActions';

import useNameModes from 'consts/use-name-modes';
import classes from './SpellingConnectables.module.scss';

const animationSpeed = 1000;

const getBoxesCount = (word) => {
  if (word.length <= 16) return 16;
  if (word.length > 16 && word.length > 25) return 25;
  return 35;
};

const getLetters = (problem) => {
  if (!problem) return null;
  const { word, letters, options } = problem;

  const startPositions = _.chain(getBoxesCount(word)).times().shuffle().value();

  const formattedLetters = _.chain(word)
    .split('')
    .map((l, idx) => {
      const isBreaker = letters.charAt(idx) === '2';
      const isFixed = isBreaker || letters.charAt(idx) === '1';

      return {
        text: l,
        inSlot: isFixed,
        fixed: isFixed,
        slotIdx: isFixed ? idx : null,
        breaker: isBreaker,
      };
    })
    .concat(options.map((l) => ({ text: l, inSlot: false })))
    .map((l, idx) => ({
      ...l,
      id: shortId.generate(),
      boxIdx: startPositions[idx],
    }))
    .value();

  return formattedLetters;
};

class SpellingConnectables extends Component {
  /*constructor(props) {
    super(props);

    const { options } = props.activity.data;
    const thisVersion = !props.isPlayVersion ? props.activity.activity.currentLanguageVersion 
    : {version: 'immersion', locale: props.solutionLocale.code};
    const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";    
    const gameData = versionType 
    ? props.activity.data.languageVersionData?.[versionType]
    : props.activity.data.gameData;

    // Remove invalid problems
    let problems = gameData.problems.filter(validateProblem);

    const { questionsLimit, randomOrder } = options;

    problems = randomOrder ? _.shuffle(problems) : problems;

    // Need to trim?
    if (
      questionsLimit &&
      questionsLimit > 0 &&
      questionsLimit < problems.length
    ) {
      problems = _.take(problems, questionsLimit);
    }

    this.state = {
      problems,
      currentProblem: null,
      versionType
    };
  }*/
  
    constructor(props) {
      super(props);
  
      const { options } = props.activity.data;
  
      const thisVersion = !props.isPlayVersion
          ? props.activity.activity.currentLanguageVersion
          : { version: 'immersion', locale: props.solutionLocale.code };
  
      const versionType =
          thisVersion && thisVersion.version && thisVersion.locale
              ? `${thisVersion.version}_${thisVersion.locale}`
              : "";
  
      const languageVersionDataExists =
          versionType &&
          props.activity.data.languageVersionData &&
          props.activity.data.languageVersionData[versionType];
  
      const gameData = languageVersionDataExists
          ? props.activity.data.languageVersionData[versionType]
          : props.activity.data.gameData;
  
      let problems = gameData.problems.filter(validateProblem);
  
      const { questionsLimit, randomOrder } = options;
  
      problems = randomOrder ? _.shuffle(problems) : problems;
  
      if (
          questionsLimit &&
          questionsLimit > 0 &&
          questionsLimit < problems.length
      ) {
          problems = _.take(problems, questionsLimit);
      }
  
      this.state = {
          problems,
          currentProblem: null,
          versionType,
          languageVersionDataExists, 
      };
  }
    

  componentDidMount() {
    document.addEventListener('startGame', this.startGame);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutBeforeNext);
    document.removeEventListener('audioEnded', this.setupNextQuestion);
    document.removeEventListener('startGame', this.startGame);
  }

  prepareProblem = (problem) => {
    const { useName, useNameMode } = problem;
    if (!useName) return problem;

    const { learnerFirstname, learnerLastname } = this.props;

    let word;
    let letters;

    switch (useNameMode) {
      case useNameModes.FIRST_NAME: {
        word = learnerFirstname;
        letters = _.repeat('0', word.length);
        break;
      }
      case useNameModes.LAST_NAME: {
        word = learnerLastname;
        letters = _.repeat('0', word.length);
        break;
      }
      case useNameModes.FIRST_AND_LAST_NAME: {
        word = learnerFirstname + ' ' + learnerLastname;
        letters =
          _.repeat('0', learnerFirstname.length) +
          '2' +
          _.repeat('0', learnerLastname.length);
        break;
      }
      default: {
      }
    }

    problem.word = word;
    problem.letters = letters;
    return problem;
  };

  startGame = () => {
    const { problems } = this.state;
    const currentProblem = this.prepareProblem(problems[0]);
    const letters = getLetters(currentProblem);

    this.refs = {
      boxes: _.times(getBoxesCount(currentProblem.word)).map((idx) =>
        React.createRef()
      ),
      slots: _.times(currentProblem.word.length).map((idx) =>
        React.createRef()
      ),
    };

    this.setState({
      currentProblem,
      letters,
      animations: _.shuffle(animations),
      currentIndex: 0,
      isResolved: false,
      problemsCount: problems.length,
      attemptsOnCurrentProblem: 0,
      answeredCorrectly: 0,
      answeredIncorrectly: 0,
      problemsLeft: problems.length,
    });

    this.playProblemAudio(currentProblem);
  };

  onDrop = ({ itemId, slotIdx }) => {
    if (slotIdx !== undefined) {
      this.moveLetterToSlot(itemId, slotIdx);
    } else {
      this.moveLetterToBoard(itemId);
    }
  };

  moveLetterToSlot = (letterId, slotIdx) => {
    const { letters } = this.state;

    const updatedLetters = letters.map((letter) => {
      if (letter.id === letterId) {
        return { ...letter, slotIdx: slotIdx, inSlot: true };
      }

      if (letter.slotIdx === slotIdx) {
        return { ...letter, slotIdx: undefined, inSlot: false };
      }

      return letter;
    });

    this.setState({ letters: updatedLetters });

    this.checkAnswer();
  };

  moveLetterToBoard = (letterId) => {
    const { letters } = this.state;

    this.setState({
      letters: letters.map((letter) => {
        if (letter.id !== letterId) return letter;
        return { ...letter, inSlot: false, slotIdx: null };
      }),
    });
  };

  checkAnswer = () => {
    const { letters, currentProblem, attemptsOnCurrentProblem } = this.state;
    const { 
      changePlayerTurn, 
      incrementPlayerPoints,
      multiplayerModeEnabled,
    } = this.props;

    const { word } = currentProblem;
    const lettersInSlot = _.chain(letters)
      .filter({ inSlot: true })
      .sortBy((l) => l.slotIdx)
      .value();

    if (lettersInSlot.length !== word.length) return;

    const wordInSlots = lettersInSlot.map((l) => l.text).join('');

    if (wordInSlots === word) {
      this.setState({ isResolved: true, shouldChangeTurn: true });
      this.props.playCorrectSound();
      if (multiplayerModeEnabled) {
        incrementPlayerPoints(attemptsOnCurrentProblem);
      };

      document.addEventListener('audioEnded', this.setupNextQuestion, {
        once: true,
      });
      return;
    }

    this.props.playIncorrectSound();
    if (multiplayerModeEnabled) {
      changePlayerTurn();
    };

    this.setState((state) => ({
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
      answeredIncorrectly:
        state.attemptsOnCurrentProblem === 0
          ? state.answeredIncorrectly + 1
          : state.answeredIncorrectly,
    }));
  };

  playProblemAudio = (problem) => {
    let problemToPlay = problem || this.state.currentProblem;

    if (!problemToPlay || !problemToPlay.audio) return;
    const {versionType} = this.state;
    const {
      activity: {
        activity: { contentFolder },
      },
    } = this.props;

    const audioUrl = versionType 
    ? `${s3bucketPublicURL}/${contentFolder}/languageVersionData/${versionType}/${problemToPlay.audio}`
    : `${s3bucketPublicURL}/${contentFolder}/gamedata/${problemToPlay.audio}`;
    toggleAudio(audioUrl);
  };

  finishGame = () => {
    const { answeredCorrectly, answeredIncorrectly, problems } = this.state;

    this.props.showStats({
      withScore: true,
      data: {
        allProblemsCount: problems.length,
        problemsAnsweredCorrectly: answeredCorrectly,
        problemsAnsweredIncorrectly: answeredIncorrectly,
      },
    });

    this.setState({ currentProblem: null });
  };

  setupNextQuestion = () => {
    const {
      problemsLeft,
      answeredCorrectly,
      attemptsOnCurrentProblem,
      shouldChangeTurn,
    } = this.state;

    const { delayBeforeNext } = this.props.activity.data.options;
    const { multiplayerModeEnabled, changePlayerTurn } = this.props;

    let newAnsweredCorrectly = answeredCorrectly;

    if (!attemptsOnCurrentProblem) {
      newAnsweredCorrectly++;
    }

    let newProblemsLeft = problemsLeft - 1;

    if (!newProblemsLeft) {
      this.setState({
        answeredCorrectly: newAnsweredCorrectly,
        problemsLeft: newProblemsLeft,
      });

      this.timeoutBeforeNext = setTimeout(() => {
        this.finishGame();
      }, delayBeforeNext * 1000);

      return;
    }

    this.timeoutBeforeNext = setTimeout(() => {
      // Get next question
      this.setState((state) => {
        const nextProblem = this.prepareProblem(
          state.problems[state.currentIndex + 1]
        );
        const letters = getLetters(nextProblem);

        this.refs.slots = _.times(nextProblem.word.length).map((idx) =>
          React.createRef()
        );

        this.playProblemAudio(nextProblem);

        return {
          letters,
          answeredCorrectly: newAnsweredCorrectly,
          attemptsOnCurrentProblem: 0,
          problemsLeft: newProblemsLeft,
          isResolved: false,
          currentProblem: nextProblem,
          currentIndex: state.currentIndex + 1,
        };
      });

      if (multiplayerModeEnabled && shouldChangeTurn) changePlayerTurn();
    }, delayBeforeNext * 1000);
  };

  showAnswer = () => {
    const {
      letters,
      currentProblem: { word },
    } = this.state;

    let changedLetters = _.map(letters, (l) => ({
      ...l,
      isCorrect: l.inSlot && l.text === word[l.slotIdx],
    }));

    _.chain(word.length)
      .times()
      .forEach((idx) => {
        const letterInThatSlot = _.find(changedLetters, { slotIdx: idx });

        if (letterInThatSlot && letterInThatSlot.isCorrect) return;

        const shouldBeLetter = word[idx];

        const letterForThatSlot = _.find(changedLetters, {
          text: shouldBeLetter,
          isCorrect: false,
        });

        letterForThatSlot.moveFrom = letterForThatSlot.inSlot
          ? this.refs.slots[letterForThatSlot.slotIdx].current
          : this.refs.boxes[letterForThatSlot.boxIdx].current;
        letterForThatSlot.inSlot = true;
        letterForThatSlot.slotIdx = idx;
        letterForThatSlot.isCorrect = true;
      })
      .value();

    changedLetters.forEach((l) => {
      if (!l.isCorrect && l.inSlot) {
        l.moveFrom = this.refs.slots[l.slotIdx].current;
        l.inSlot = false;
        l.slotIdx = null;
      }
    });

    this.setState((state) => ({
      letters: [...changedLetters],
      isResolved: true,
      shouldChangeTurn: false,
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
    }));

    this.props.playWatch();

    document.addEventListener('audioEnded', this.setupNextQuestion, {
      once: true,
    });
  };

  render() {
    const {
      isResolved,
      problems,
      problemsLeft,
      currentProblem,
      attemptsOnCurrentProblem,
    } = this.state;

    const { textStyle, activity, multiplayerModeEnabled } = this.props;
    const { options } = activity.data;

    let fontSize = textStyle.fontSize;

    const wordLength =
      currentProblem && currentProblem.word && currentProblem.word.length;
    if (wordLength > 16) {
      fontSize = parseFloat(fontSize) * 0.8 + 'vmin';
    }
    if (wordLength > 25) {
      fontSize = parseFloat(fontSize) * 0.8 + 'vmin';
    }

    return (
      <div style={{ ...textStyle, fontSize }}>
        {this.renderBlocks()}

        {problems && (
          <ProblemsProgress
            problemsNumber={problems.length}
            problemsLeft={problemsLeft}
          />
        )}
        
        {multiplayerModeEnabled && (
          <MultiplayerOverlay /> 
        )}

        <ActivityButtons
          buttons={[
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.CORRECT_ANSWER,
              onClick: () => this.showAnswer(),
              dontShow:
                multiplayerModeEnabled ||
                isResolved ||
                options.showAnswer === 'n/a' ||
                +options.showAnswer > attemptsOnCurrentProblem,
            },
            {
              type: buttonsTypes.SPEAK,
              onClick: () => this.playProblemAudio(),
              dontShow: !currentProblem || !currentProblem.audio || isResolved,
            },
            buttonsTypes.HELP,
          ]}
        />
      </div>
    );
  }

  renderBlocks = () => {
    const {
      letters,
      isResolved,
      animations,
      currentIndex,
      currentProblem,
      versionType
    } = this.state;

    if (!currentProblem || !letters) return null;

    const {
      textStyle,
      activity: {
        activity: { contentFolder },
        data: { options },
      },
    } = this.props;

    const imageUrl = versionType 
    ? (currentProblem.image &&
          `${s3bucketPublicURL}/${contentFolder}/languageVersionData/${versionType}/${currentProblem.image}`)
    : (currentProblem.image &&
            `${s3bucketPublicURL}/${contentFolder}/gamedata/${currentProblem.image}`);

    const breakIndex = currentProblem.letters.indexOf('2');
    const wordLength = currentProblem.word.length;

    return (
      <Fragment>
        <ItemsDragLayer />
        <ItemSlots
          slotClassname={classes.letterSlot}
          options={options}
          animationSpeed={animationSpeed}
          isResolved={isResolved}
          animation={animations[currentIndex % animations.length]}
          count={
            breakIndex !== -1
              ? [breakIndex, wordLength - breakIndex]
              : wordLength
          }
          onDrop={this.onDrop}
          items={letters}
          refs={this.refs.slots}
        />
        <ProblemImage url={imageUrl} />
        <BoardWithDraggableItems
          isResolved={isResolved}
          boxesCount={getBoxesCount(currentProblem.word)}
          onDrop={this.onDrop}
          items={letters}
          textStyle={textStyle}
          refs={this.refs.boxes}
        />
        <ConnectablesProblemDefinition text={currentProblem.definition} />
      </Fragment>
    );
  };
}

const mapStateToProps = ({ auth, flinkPlay }) => ({
  learnerFirstname:
    (flinkPlay.learner && flinkPlay.learner.firstname) ||
    (auth.user && auth.user.firstname),
  learnerLastname:
    (flinkPlay.learner && flinkPlay.learner.lastname) ||
    (auth.user && auth.user.lastname),
});

export default connect(mapStateToProps, {
  playWatch,
  playCorrectSound,
  playIncorrectSound,
  showStats,
  changePlayerTurn,
  incrementPlayerPoints,
})(SpellingConnectables);
