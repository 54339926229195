import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { publicKey } from 'config';

import AddAudio from '../AddAudio/AddAudio';

const ActivityAddAudio = ({ path, contentFolder, versionType, helpTab = false, ...otherProps }) => {
  const filesPath = path || ((!helpTab && versionType?.indexOf('immersion') !== -1) 
  ? `${publicKey}/${contentFolder}/languageVersionData/${versionType}`
  : `${publicKey}/${contentFolder}/gamedata`);
  return (
    <AddAudio
      saveAfterChange
      withOverlay
      path={filesPath}
      withId={!otherProps.filename}
      removeOldOnChange={!otherProps.filename}
      versionType={versionType}
      {...otherProps}
    />
  );
};

ActivityAddAudio.propTypes = {
  path: PropTypes.string,
  contentFolder: PropTypes.string.isRequired
};

const mapStateToProps = ({ flinkMake: { activity } }) => ({
  contentFolder: activity.editingActivity.activity.contentFolder
});

export default connect(mapStateToProps)(ActivityAddAudio);
