const calcFontSize = (fontSize, templateDimensions, max, min, base) => {
  if (!fontSize) return 14;

  const { width, height } = templateDimensions;
  const minSide = width < height ? width : height;
  const maxFontSize = max && +max < fontSize ? +max : fontSize;

  if (minSide > (base - 1 || 767)) return maxFontSize;

  const rate = minSide / (base || 768);
  let calculatedFontSize = rate * maxFontSize;

  calculatedFontSize > maxFontSize && (calculatedFontSize = maxFontSize);
  calculatedFontSize < (min || 12) && (calculatedFontSize = min || 12);

  return calculatedFontSize;
};

export default calcFontSize;
