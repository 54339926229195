import _ from 'lodash';

export default (group, activityGroups, activities, translate, locale = '') => {
  if (!group || !group.elements) return [];
console.log("getGroupElements locale", locale);
  const groupElements = _.chain(group.elements)
    .map((i) => {
      const item = _.find(i.isGroup ? activityGroups : activities, {
        _id: i._id,
      });

      if (!item) return null;

      if (item.activityNameString){
        const interpolated = item.activityNameString.replace(/#\d+/g, function (match) {
          return translate(
            match.slice(1),
            "Unknown",
            true,
            false,
            locale
            );
        });
        item.activityName = interpolated
      }

      let formattedName = i.isGroup ? item.name : item.activityName;

      if (i.isGroup && item.nameString) {
        formattedName = translate(item.nameString, '', true, false, locale);
      } else if (formattedName.indexOf('~') !== -1) {
        formattedName = formattedName.split('~')[1];
      }

      return { ...item, formattedName, isGroup: i.isGroup };
    })
    .compact()
    .value();

  return groupElements;
};
