import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';

import Buttons from '../Buttons/Buttons';
import {
  LOGOUT,
  SETTINGS,
  GO_BACK,
  HELP,
  REPORTS,
  SHARE,
  PARENT_ADVICE,
  QUICKSTART_VIDEOS,
} from 'consts/buttons';
import { logout } from 'actions/authActions';
import {
  showSettings,
  showMenuHelp,
  goBack,
  showFamilyReports,
  showParentAdvice,
  showQuickstartVideos,
} from 'actions/flinkPlayActions';

const MenuButtons = ({
  buttons,
  videos,
  showSettings,
  goBack,
  solution,
  solutionMenu,
  groupsBreadcrumbs,
  familyReportsOpened,
  showFamilyReports,
  showParentAdvice,
  showQuickstartVideos,
  parentAdvice,
  parentAdviceUrl,
  quickStartVideos,
  showMenuHelp,
  organizationConfig,
}) => {
  const dispatch = useDispatch();
  const formattedButtons = useMemo(
    () =>
      buttons
      .filter((btn) => btn !== QUICKSTART_VIDEOS)
      .map((btn) => {
        const { activityBuilder } = solution || {};
        const dispatchLogOut = () => dispatch(logout());

        const btnObj = typeof btn === 'string' ? { type: btn } : btn;

        if (btnObj.onClick) {
          return btnObj;
        }

        switch (btnObj.type) {
          case SHARE:
            btnObj.dontShow = !activityBuilder || btnObj.dontShow;
            btnObj.onClick = () => console.log(btnObj.type, 'No Handler');

            break;
          case REPORTS:
            btnObj.dontShow =
              organizationConfig.noReports || familyReportsOpened || btnObj.dontShow;
            btnObj.onClick = () => showFamilyReports(true);

            break;
          case GO_BACK: {
            if (
              solutionMenu &&
              solutionMenu.menuGroups &&
              solutionMenu.menuGroups.length === 1 &&
              !groupsBreadcrumbs.length
            ) {
              btnObj.type = LOGOUT;
              btnObj.dontShow = organizationConfig.noLogout;
              btnObj.onClick = dispatchLogOut;
            } else {
              btnObj.onClick = goBack;
            }
            break;
          }
          case LOGOUT:
            btnObj.onClick = dispatchLogOut;
            btnObj.dontShow = organizationConfig.noLogout;
            break;
          case HELP:
            btnObj.onClick = showMenuHelp;
            break;
          case PARENT_ADVICE: {
            btnObj.onClick = () => showParentAdvice(!parentAdvice);
            btnObj.dontShow = btnObj.dontShow || !parentAdviceUrl;
            break;
          }

          case SETTINGS:
            btnObj.dontShow = organizationConfig.noSettings;
            btnObj.onClick = showSettings;
            break;

          case QUICKSTART_VIDEOS:
            btnObj.onClick = () => showQuickstartVideos(!quickStartVideos);
            btnObj.dontShow = btnObj.dontShow || !videos.length;
            break;

          default:
            btnObj.onClick = () => console.log(btnObj.type, 'No Handler');
        }

        return btnObj;
      }),
    [
      buttons,
      videos,
      dispatch,
      showSettings,
      showMenuHelp,
      solution,
      goBack,
      showFamilyReports,
      groupsBreadcrumbs.length,
      familyReportsOpened,
      solutionMenu,
      parentAdvice,
      parentAdviceUrl,
      showParentAdvice,
      showQuickstartVideos,
      quickStartVideos,
      organizationConfig,
    ]
  );

  return <Buttons buttons={formattedButtons} />;
};

MenuButtons.propTypes = {
  buttons: PropTypes.array.isRequired,
  videos: PropTypes.array.isRequired,
  locale: PropTypes.object.isRequired,
  groupsBreadcrumbs: PropTypes.array.isRequired,
  showSettings: PropTypes.func.isRequired,
  showMenuHelp: PropTypes.func.isRequired,
  showFamilyReports: PropTypes.func.isRequired,
  showParentAdvice: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  familyReportsOpened: PropTypes.bool,
  solution: PropTypes.object,
  parentAdvice: PropTypes.bool,
  parentAdviceUrl: PropTypes.string,
  organizationConfig: PropTypes.shape({
    noReports: PropTypes.bool,
    noLogout: PropTypes.bool,
  }),
};

const mapStateToProps = ({
  flinkPlay: {
    menuButtons,
    solution,
    solutionMenu,
    solutionLocale,
    parentAdvice,
    parentAdviceUrl,
    familyReportsOpened,
    groupsBreadcrumbs,
    learningCenter: { config },
  },
  common: {
    config: {
      loginPageDocuments: { quickStart }
    }
  },
  status: { lang }
}) => ({
  organizationConfig: config,
  buttons: menuButtons,
  videos: quickStart[(solutionLocale || lang)?.code] || [],
  locale: solutionLocale || lang,
  solutionMenu,
  groupsBreadcrumbs,
  parentAdvice,
  parentAdviceUrl,
  solution,
  familyReportsOpened,
});

export default compose(
  connect(mapStateToProps, {
    goBack,
    showSettings,
    showMenuHelp,
    showFamilyReports,
    showParentAdvice,
    showQuickstartVideos,
  })
)(MenuButtons);
