import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactQuill, { Quill } from "react-quill";
import shortid from "shortid";
import classnames from "classnames";

import CustomToolbar from "./CustomToolbar";
import {
  fonts as availableFonts,
  sizes as normalSizes,
  formats
} from "settings/Quill/Quill";
import "./Editor.scss";

class Editor extends Component {
  constructor(props) {
    super(props);

    Quill.register(Quill.import("attributors/style/background"), true);
    Quill.register(Quill.import("attributors/style/color"), true);
    Quill.register(Quill.import("attributors/style/align"), true);

    const fonts = Quill.import("attributors/style/font");
    fonts.whitelist = availableFonts;
    Quill.register(fonts, true);

    const sizes = Quill.import("attributors/style/size");
    sizes.whitelist = normalSizes;
    Quill.register(sizes, true);

    const { withImage, withVideo, withLink } = props;

    const toolbarID = shortid.generate();

    const formatsToUse = [...formats];

    const Block = Quill.import("blots/block");
    Block.tagName = "P";
    Quill.register(Block, true);

    const modules = {
      clipboard: {
        matchers: [["image", null]],
        matchVisual: false
      },
      toolbar: {
        container: `[data-toolbar-id="${toolbarID}"]`
      }
    };

    if (withImage || withVideo) {
      formatsToUse.push("imageResize");
      modules.imageResize = {
        parchment: Quill.import("parchment")
      };
    }

    withImage && formatsToUse.push("image");
    withVideo && formatsToUse.push("video");
    withLink && formatsToUse.push("link");

    this.state = {
      editorHtml: "",
      toolbarID,
      formats: formatsToUse,
      modules
    };
  }

  componentDidMount() {
    const { initialValue } = this.props;

    if (initialValue) {
      this.setState({
        editorHtml: initialValue
      });
    }
  }

  setEditorContent(content) {
    this.setState({
      editorHtml: content,
    });
  }

  componentDidUpdate(_prevProps, prevState) {
    const { changeHandlerRaw } = this.props;
    if (changeHandlerRaw && (this.state.editorHtml !== prevState.editorHtml)) {
      const raw = this.getText().trim();
      changeHandlerRaw(raw);
    }
  }

  editorRef = React.createRef();

  getEditor = () => {
    const editor = this.editorRef.current && this.editorRef.current.getEditor();
    return editor;
  };

  getText = () => {
    const editor = this.getEditor();
    return editor.getText();
  };

  handleChange = html => {
    const { editorHtml } = this.state;
    if (editorHtml === html) return;

    // console.log(html)

    this.setState({ editorHtml: html });

    // Replace spaces - prevent collapsing of multiple spaces
    // const formatted = html.replace(/\s/g, "&nbsp;");
    this.props.changeHandler(html);
  };

  render() {
    const { editorHtml, toolbarID, modules, formats } = this.state;
    const {
      overflowHidden = false,
      translatorReadOnly,
      className,
      classes = {},
      onFocus,
      onBlur,
      withImage,
      withVideo,
      style = {},
      withLink,
      withAddAudio,
      readOnly = false
    } = this.props;

    return (
      <div
        className={classnames("custom-editor", {
          overflowHidden
        })}
      >
        <div className={classes.toolbar}>
          <CustomToolbar
            id={toolbarID}
            withImage={withImage}
            withVideo={withVideo}
            withLink={withLink}
            withAddAudio={withAddAudio}
          />
        </div>
        <ReactQuill
          ref={this.editorRef}
          style={style}
          readOnly={translatorReadOnly ? translatorReadOnly : readOnly}
          theme={"snow"}
          onChange={this.handleChange}
          value={editorHtml}
          modules={modules}
          formats={formats}
          className={className}
          onFocus={onFocus}
          onBlur={onBlur}
          preserveWhitespace
        />
      </div>
    );
  }
}

Editor.defaultProps = {
  withImage: true,
  withVideo: true,
  withLink: true
};

Editor.propTypes = {
  initialValue: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  translatorReadOnly: PropTypes.bool,
};

export default Editor;
