import React, { useEffect, useState } from 'react';

const getWindowSize = cb => {
  cb({ width: window.innerWidth, height: window.innerHeight });
};

const withWindowSize = Component => props => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    window.addEventListener('resize', getWindowSize.bind(null, setWindowSize));

    return () => {
      window.removeEventListener('resize', getWindowSize);
    };
  }, []);

  return <Component windowSize={windowSize} {...props} />;
};

export default withWindowSize;
