import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import shortid from 'shortid';

import API from 'api';
import { getInstruction } from 'activity-templates/utils';
import { getScale, extractContent } from 'utils';
import {
  Instructions,
  ActivityButtons,
  WhiteBox,
  PlayRecorder,
  WritingFeedback,
} from 'components/flink-play';
import { GO_BACK, SPEAK, HELP, EBOOK_BUTTON, STOP } from 'consts/buttons';
import { activitiesKey, usersKey, uploadsURL, imagesURL } from 'config';
import {
  EDITOR_AND_RECORDER,
  EDITOR_ONLY,
  RECORDER_ONLY,
} from 'consts/story-studio-consts';
import { playAudio, stopAudio } from 'actions/audioActions';
import { showStats } from 'actions/gameActions';
import { closeWritingFeedback } from 'actions/flinkPlayActions';

import StoryStudioEditor from './StoryStudioEditor/StoryStudioEditor';
import PagesListBox from './PagesListBox/PagesListBox';
import PictureBox from './PictureBox/PictureBox';
import { STORY_STUDIO } from 'consts/activity-templates';

import classes from './StoryStudio.module.scss';

const divideSize = (s) => s / 2 + 'px';
const prevBtnImg = `${imagesURL}/TemplateGraphics/e-Book/arrow_left.png`;
const nextBtnImg = `${imagesURL}/TemplateGraphics/e-Book/arrow_right.png`;

const defaultData = {
  templateAlias: STORY_STUDIO,
  pages: [],
};

export class StoryStudio extends Component {
  /*constructor(props) {
    super(props);

    const { activity, learnerId, team } = props;
    const {
      data: {
        options: { storyStudioMode },
        /*gameData: {
          autoInsertArt,
          images = [],
          words = [],
          pagesCount: pagesCountOrigin,
        },*/
    /*  },
      activity: { _id: activityId },
    } = activity;

    const thisVersion = !props.isPlayVersion ? props.activity.activity.currentLanguageVersion 
    : {version: 'immersion', locale: props.solutionLocale.code};
    const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";    
    const gameData = versionType 
    ? props.activity.data.languageVersionData?.[versionType]
    : props.activity.data.gameData;

    const {
      autoInsertArt,
      images = [],
      words = [],
      pagesCount: pagesCountOrigin,
    } = gameData;

    const filteredWords = words.filter((w) => !!(w && w.trim()) && w);
    const filteredImages = (images || []).filter((i) => !!i);
    const pagesCount = autoInsertArt ? filteredImages.length : pagesCountOrigin;

    const learnersIDs = [];

    if (learnerId) {
      learnersIDs.push(learnerId);
    }

    if (team) {
      learnersIDs.push(...team.map((l) => l._id));
    }

    if (autoInsertArt) {
      defaultData.pages = filteredImages.map((i) => ({ image: i }));
    } else {
      defaultData.pages = _.fill(Array(pagesCount), {});
    }

    const activityAssetsKey = versionType 
    ? `${activitiesKey}/${activityId}/languageVersionData/${versionType}`
    : `${activitiesKey}/${activityId}/gamedata`;
    const activityAssetsFolder = `${uploadsURL}/${activityAssetsKey}/`;
    const learnerAssetsKey =
      learnerId && `${usersKey}/${learnerId}/${activityId}`;
    const learnerAssetsFolder =
      learnerId && `${uploadsURL}/${learnerAssetsKey}/`;

    this.state = {
      mode: storyStudioMode,
      gameData,
      paths: {
        activityAssetsKey,
        activityAssetsFolder,
        learnerAssetsKey,
        learnerAssetsFolder,
      },
      filteredWords,
      pagesCount,
      filteredImages,
      learnersIDs,
      learnerAssetsList: [],
      data: defaultData,
      activePageIdx: 0,
      isLandscape: false,
      showEditor: true,
      ebookMode: false,
      initialSave: true,
      needToCopyAllImages: autoInsertArt,
      approved: false,
    };

    this.getPagesText = this.getPagesText.bind(this);
  }*/

    constructor(props) {
      super(props);
    
      const { activity, learnerId, team } = props;
      const {
        data: {
          options: { storyStudioMode },
        },
        activity: { _id: activityId },
      } = activity;
    
      // Определяем текущую версию
      const thisVersion = !props.isPlayVersion
        ? props.activity.activity.currentLanguageVersion
        : { version: 'immersion', locale: props.solutionLocale.code };
    
      const versionType =
        thisVersion?.version && thisVersion?.locale
          ? `${thisVersion.version}_${thisVersion.locale}`
          : null;
    
      // Проверяем наличие languageVersionData
      const languageVersionDataExists =
        versionType && props.activity.data.languageVersionData?.[versionType];
    
      // Определяем gameData
      const gameData = languageVersionDataExists
        ? props.activity.data.languageVersionData[versionType]
        : props.activity.data.gameData;
    
      // Проверяем наличие gameData
      if (!gameData) {
        console.error('Game data is missing or undefined.');
        return;
      }
    
      const {
        autoInsertArt = false,
        images = [],
        words = [],
        pagesCount: pagesCountOrigin = 0,
      } = gameData;
    
      // Фильтруем слова и изображения
      const filteredWords = words.filter((w) => !!(w && w.trim()) && w);
      const filteredImages = (images || []).filter((i) => !!i);
      const pagesCount = autoInsertArt ? filteredImages.length : pagesCountOrigin;
    
      // Список ID учеников
      const learnersIDs = [];
      if (learnerId) learnersIDs.push(learnerId);
      if (team) learnersIDs.push(...team.map((l) => l._id));
    
      // Устанавливаем страницы по умолчанию
      if (autoInsertArt) {
        defaultData.pages = filteredImages.map((i) => ({ image: i }));
      } else {
        defaultData.pages = _.fill(Array(pagesCount), {});
      }
    
      // Формируем пути для ресурсов
      const activityAssetsKey = languageVersionDataExists
        ? `${activitiesKey}/${activityId}/languageVersionData/${versionType}`
        : `${activitiesKey}/${activityId}/gamedata`;
    
      const assetsFolder = languageVersionDataExists
        ? `${uploadsURL}/${activitiesKey}/${activityId}/languageVersionData/${versionType}/`
        : `${uploadsURL}/${activitiesKey}/${activityId}/gamedata/`;
    
      const learnerAssetsKey =
        learnerId && `${usersKey}/${learnerId}/${activityId}`;
      const learnerAssetsFolder =
        learnerId && `${uploadsURL}/${learnerAssetsKey}/`;
    
      // Сохраняем состояние компонента
      this.state = {
        mode: storyStudioMode,
        gameData,
        paths: {
          activityAssetsKey,
          assetsFolder,
          learnerAssetsKey,
          learnerAssetsFolder,
        },
        filteredWords,
        pagesCount,
        filteredImages,
        learnersIDs,
        learnerAssetsList: [],
        data: defaultData,
        activePageIdx: 0,
        isLandscape: false,
        showEditor: true,
        ebookMode: false,
        initialSave: true,
        needToCopyAllImages: autoInsertArt,
        approved: false,
      };
    
      this.getPagesText = this.getPagesText.bind(this);
    }
    
    
  
  

  componentDidMount() {
    document.addEventListener('startGame', this.init);

    const { learnersIDs } = this.state;

    // Get students data for this activity
    if (learnersIDs.length) {
      this.getLearnerData();
    }

    this.checkOrientation();
  }

  getPagesText() {
    const pagesContent = Array.from(this.state.data.pages.entries())
      .map(([index, page]) => {
        const text = extractContent(page?.text)?.trim();
        return { page: index + 1, text }
      })
      .filter(({ text }) => !!text);

    return pagesContent
      .map(({ text }) => text)
      .join("... ");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.aspectRatio !== this.props.aspectRatio) {
      this.checkOrientation();
    }

    if (prevState.activePageIdx !== this.state.activePageIdx) {
      this.reloadEditor();
    }
  }

  componentWillUnmount() {
    stopAudio();
    document.removeEventListener('startGame', this.init);
  }

  init = () => {
    document.dispatchEvent(new Event('playInstructionsAudio'));
  };

  onExit = (goBackFn) => {
    const {
      showStats,
      closeWritingFeedback,
      activity: {
        data: { options },
      },
    } = this.props;
    const { data, mode, approved } = this.state;

    closeWritingFeedback();
    this.setState({ exiting: true }, () => {
      const texts = _.chain(data.pages)
        .map(
          (p) =>
            p &&
            p.text &&
            p.text
              .replace(/<[\s\S]+?>/g, ' ')
              .replace(/[^\w\s]/g, '')
              .split(/\s+/)
              .filter((w) => !!w).length
        )
        .filter((t) => !!t)
        .sort((a, b) => b - a)
        .take(4)
        .value();

      const wordsCount = _.sum(texts);

      console.log(texts, wordsCount);

      const audiosNumber = data.pages
        .map((p) => p && p.audio)
        .filter((a) => !!a).length;

      let isMastered, isPartial;

      const textsMastered = texts.length === 4 && wordsCount >= 5;
      const textsPartial = texts.length >= 2 && wordsCount >= 3;

      const audioMastered = audiosNumber >= 4;
      const audioPartial = audiosNumber >= 2;

      if (mode === EDITOR_ONLY) {
        isMastered = textsMastered;
        isPartial = textsPartial;
      } else if (mode === RECORDER_ONLY) {
        isMastered = audioMastered;
        isPartial = audioPartial;
      } else {
        isMastered = audioMastered || textsMastered;
        isPartial = audioPartial || textsPartial;
      }

      if ((isMastered || isPartial) && (!options.needApproval || approved)) {
        showStats({
          predefinedMastered: isMastered,
        });

        this.saveChanges();
      } else {
        this.saveChanges(goBackFn);
      }

      // if (isMastered) {
      //   // If five words on two pages, then activity is mastered
      //   setActivityMastered();
      // } else if (isPartial) {
      //   // If two words on 1 page, then activity is partial mastered
      //   setActivityPartial();
      // }
    });
  };

  getLearnerData = async () => {
    const {
      activity: {
        activity: { _id: activityId },
        /*data: {
          gameData: { autoInsertArt },
        },*/
      },
      noReports,
    } = this.props;
    const { learnersIDs, gameData } = this.state;
    const { autoInsertArt } = gameData;

    if (!learnersIDs.length || noReports) return;

    const learnersData = await Promise.all(
      learnersIDs.map((learnerId) =>
        API.game.getLearnerActivityData({
          learnerId,
          activityId,
        })
      )
    );

    // If multiple members logged in, then check that they have same activity data
    const allDataIsTheSame = !!learnersData.reduce(
      (a, b) => JSON.stringify(a) === JSON.stringify(b)
    );

    if (!allDataIsTheSame) return;

    const data = learnersData[0];
    if (!data) return;

    this.getLearnerAssets();

    const { pagesCount } = this.state;

    const learnerPagesCount = data.pages && data.pages.length;

    if (learnerPagesCount < pagesCount) {
      data.pages.length = pagesCount;
    }

    this.setState(
      {
        data,
        needToCopyAllImages:
          autoInsertArt && (!data.pages || data.pages.length === 0),
      },
      this.reloadEditor
    );
  };

  getLearnerAssets = () => {
    const { activity, learnerId } = this.props;

    API.game
      .getLearnerActivityAssetsList({
        learnerId,
        activityId: activity.activity._id,
      })
      .then((list) => {
        this.setState({ learnerAssetsList: list });
      });
  };

  checkOrientation = () => {
    const { aspectRatio } = this.props;

    if (!aspectRatio) return;
    this.setState({ isLandscape: aspectRatio >= 1.33 });
  };

  changeActivePageIdx = (idx) => {
    this.setState({ activePageIdx: idx }, () => {
      // this.saveChanges();
    });
  };

  onEditorChange = (html) => {
    const { data = {}, activePageIdx, ebookMode } = this.state;
    const { pages = [] } = data;

    if (
      ebookMode ||
      (pages[activePageIdx] && pages[activePageIdx].text === html)
    ) {
      return;
    }

    this.changeCurrentPageValues({ text: html });
  };

  changeCurrentPageValues = (values, cb) => {
    const { data = {}, activePageIdx } = this.state;
    const { pages = [] } = data;

    const pagesCopy = JSON.parse(JSON.stringify(pages));

    pagesCopy[activePageIdx] = {
      ...(pagesCopy[activePageIdx] || {}),
      ...values,
    };

    this.setState(
      {
        hasUnsavedChanges: true,
        data: {
          ...data,
          pages: pagesCopy,
        },
      },
      cb
    );
  };

  saveChanges = async (cb) => {
    const { activity, learnerId, preview, noReports } = this.props;
    const {
      data,
      initialSave,
      hasUnsavedChanges,
      needToCopyAllImages,
      exiting,
      learnerAssetsList,
      learnersIDs,
    } = this.state;

    if (preview || !learnerId || !hasUnsavedChanges || noReports) {
      return cb && cb();
    }

    const activityId = activity.activity._id;

    // Before Save
    // Check if team logged in, and this is a first save
    if (learnersIDs.length > 1 && initialSave) {
      // If so, then cleanup learners folders
      const results = await Promise.all(
        learnersIDs.map((learnerId) =>
          API.game
            .getLearnerActivityAssetsList({ learnerId, activityId })
            .then((assetsList) => {
              return API.game.deleteLearnerActivityAssets({
                learnerId,
                activityId,
                filenames: _.difference(assetsList, learnerAssetsList),
              });
            })
        )
      );

      console.log(results);
    }

    try {
      const success = await API.game.saveLearnerActivityData({
        learnerId: learnersIDs,
        activityId,
        data,
      });

      if (success && !exiting) {
        this.setState({ hasUnsavedChanges: false, initialSave: false });
      }

      if (needToCopyAllImages) {
        this.copyAllImagesToLearnersFolder();
      }
    } catch (err) {
      console.log(err);
    }

    cb && cb();
  };

  reloadEditor = () => {
    this.setState({ showEditor: false }, () => {
      this.setState({ showEditor: true });
    });
  };

  copyAllImagesToLearnersFolder = () => {
    const {
      activity: {
        activity: { _id: activityId },
      },
      learnerId,
    } = this.props;
    const {
      learnersIDs,
      exiting,
      filteredImages,
      paths: { activityAssetsKey },
    } = this.state;

    if (!learnerId) return;

    const array = filteredImages.map((filename) => {
      return {
        srcKey: `${activityAssetsKey}/${filename}`,
        uploadBucket: true,
        filename,
      };
    });

    API.game
      .copyToLearnerActivityFolder({
        activityId,
        learnerId: learnersIDs,
        array,
      })
      .then((results) => {
        !exiting &&
          this.setState((state) => ({
            learnerAssetsList: [...state.learnerAssetsList, ...filteredImages],
            needToCopyAllImages: false,
          }));
      });
  };

  uploadAudio = (url, blob) => {
    const { learnerId, activity, noReports } = this.props;
    const { activePageIdx, learnersIDs } = this.state;
    const activityId = activity.activity._id;

    if (!learnerId || noReports) {
      return this.changeCurrentPageValues({ audio: url });
    }

    const filename = `page-${activePageIdx}-${shortid.generate()}.mp3`;

    return API.game
      .uploadToLearnerActivityAssets({
        activityId,
        learnerId: learnersIDs,
        files: [
          {
            file: blob,
            filename,
          },
        ],
      })
      .then((results) => {
        this.setState(
          (state) => ({
            learnerAssetsList: [...state.learnerAssetsList, filename],
          }),
          () =>
            this.changeCurrentPageValues({ audio: filename }, this.saveChanges)
        );
      });
  };

  deleteAudio = () => {
    const { learnerId, activity, noReports } = this.props;
    const { learnersIDs } = this.state;
    const activityId = activity.activity._id;

    if (!learnerId || noReports) {
      return this.changeCurrentPageValues({ audio: null });
    }

    const currentPage = this.getCurrentPage();

    if (!currentPage.audio) return;

    return API.game
      .deleteLearnerActivityAssets({
        activityId,
        learnerId: learnersIDs,
        filenames: [currentPage.audio],
      })
      .then((results) => {
        this.setState(
          (state) => ({
            learnerAssetsList: state.learnerAssetsList.filter(
              (f) => f !== currentPage.audio
            ),
          }),
          () => this.changeCurrentPageValues({ audio: null })
        );
      });
  };

  uploadImage = (filename, srcKey, fromClipart) => {
    const {
      activity: {
        activity: { _id: activityId },
      },
      learnerId,
      noReports,
    } = this.props;
    const { learnersIDs } = this.state;

    if (!learnerId || noReports) return;

    return API.game
      .copyToLearnerActivityFolder({
        activityId,
        learnerId: learnersIDs,
        array: [
          {
            srcKey,
            uploadBucket: !fromClipart,
            filename,
          },
        ],
      })
      .then((results) => {
        this.setState((state) => ({
          learnerAssetsList: [...state.learnerAssetsList, filename],
        }));
      });
  };

  deleteImage = (filename) => {
    const {
      activity: {
        activity: { _id: activityId },
      },
    } = this.props;
    const { learnersIDs } = this.state;

    API.game
      .deleteLearnerActivityAssets({
        activityId,
        learnerId: learnersIDs,
        filenames: [filename],
      })
      .then((success) => {
        if (success) {
          this.setState((state) => ({
            learnerAssetsList: state.learnerAssetsList.filter(
              (f) => f !== filename
            ),
          }));
        }
      });
  };

  onRemoveImage = () => {
    const { learnerId } = this.props;
    const { data = {}, activePageIdx, learnerAssetsList } = this.state;
    const { pages = [] } = data;

    const currentPage = this.getCurrentPage();

    const { image } = currentPage;
    if (!image) return;

    if (learnerId) {
      const isImageInUse = pages.reduce((inUse, page, idx) => {
        return inUse || (idx !== activePageIdx && page && page.image === image);
      }, false);

      if (!isImageInUse && learnerAssetsList.includes(image)) {
        this.deleteImage(image);
      }
    }

    this.changeCurrentPageValues({ image: '' }, this.saveChanges);
  };

  onChangeImage = async (newImageFilename, srcKey, fromClipart) => {
    const { learnerId, preview } = this.props;
    const { data = {}, activePageIdx, learnerAssetsList } = this.state;
    const { pages = [] } = data;

    if (preview && fromClipart) {
      return alert('Import from clipart not available in Preview');
    }

    const currentPage = pages[activePageIdx] || {};

    if (currentPage.image === newImageFilename) {
      return;
    }

    // Check if need to delete previous image
    const previousImage = currentPage.image;

    if (previousImage) {
      const isImageInUse = pages.reduce((inUse, page, idx) => {
        return (
          inUse ||
          (idx !== activePageIdx && page && page.image === previousImage)
        );
      }, false);

      if (
        learnerId &&
        !isImageInUse &&
        learnerAssetsList.includes(previousImage)
      ) {
        this.deleteImage(previousImage);
      }
    }

    // Check if need to upload new image
    if (learnerId && !learnerAssetsList.includes(newImageFilename)) {
      await this.uploadImage(newImageFilename, srcKey, fromClipart);
    }

    this.changeCurrentPageValues({ image: newImageFilename }, this.saveChanges);
  };

  startPreview = () => {
    const {
      mode,
      data: { pages },
    } = this.state;

    const validPages = pages.filter(
      (p) => p && (p.text || p.image || (mode !== EDITOR_ONLY && p.audio))
    );

    this.setState(
      {
        ebookMode: true,
        validPages,
        activePageIdx: 0,
      },
      () => {
        this.saveChanges();
        this.playPageAudio();
      }
    );
  };

  stopPreview = () => {
    stopAudio();
    this.setState({ ebookMode: false, activePageIdx: 0, validPages: [] });
  };

  playPageAudio = () => {
    const { mode, ebookMode } = this.state;
    if (!ebookMode || mode === EDITOR_ONLY) return;

    const url = this.getCurrentAudioUrl();
    if (!url) return;

    console.log(url);

    playAudio(url, { onEnd: this.showNextPage, format: 'mp3' });
  };

  showPrevPage = () => {
    const { activePageIdx } = this.state;
    const isFirstPage = activePageIdx === 0;

    if (!isFirstPage) {
      this.setState({ activePageIdx: activePageIdx - 1 }, this.playPageAudio);
    }
  };

  showNextPage = () => {
    const {
      activePageIdx,
      ebookMode,
      data: { pages },
      validPages,
    } = this.state;

    const currentPages = ebookMode ? validPages : pages;

    const isLastPage = currentPages.length === activePageIdx + 1;

    if (currentPages.length && !isLastPage) {
      this.setState({ activePageIdx: activePageIdx + 1 }, this.playPageAudio);
    }
  };

  getCurrentPage = () => {
    const {
      ebookMode,
      validPages,
      activePageIdx,
      data: { pages },
    } = this.state;
    const currentPages = ebookMode ? validPages : pages;
    const activePage = currentPages[activePageIdx] || {};

    return activePage;
  };

  getCurrentAudioUrl = () => {
    const { learnerId } = this.props;
    const {
      paths: { learnerAssetsFolder },
    } = this.state;

    const currentPage = this.getCurrentPage();

    let url = currentPage.audio;

    if (!url) return;

    if (learnerId) {
      url = learnerAssetsFolder + url;
    }

    return url;
  };

  toggleApprove = () => {
    this.setState({ approved: !this.state.approved });
  };

  render() {
    const {
      mode,
      paths,
      approved,
      ebookMode,
      pagesCount,
      validPages,
      learnerAssetsList,
      activePageIdx,
      isLandscape,
      filteredImages,
      showEditor,
      filteredWords,
      data: { pages },
      gameData
    } = this.state;
    const { solutionLocale, activity, translate, getStringAudio } = this.props;
    const {
      data: { options },
    } = activity;
console.log('StoryStudio solutionLocale: ',solutionLocale);
    const currentPages = ebookMode ? validPages : pages;

    const activePage = currentPages[activePageIdx] || {};

    const { importClipart = false, autoInsertArt = false } = gameData;
    const instruction = getInstruction(
      gameData, 
      solutionLocale,
      undefined, undefined, undefined,
      { translate, getStringAudio },
    );

    const currentImage = activePage.image;
    const showPictureBox =
      !ebookMode &&
      ((filteredImages.length && !autoInsertArt) ||
        importClipart ||
        currentImage);

    const isOnlyRecorder =
      mode === RECORDER_ONLY ||
      (mode === EDITOR_AND_RECORDER && activePage.audio);

    const showRecorder =
      !ebookMode && (mode === RECORDER_ONLY || mode === EDITOR_AND_RECORDER);

    const showEditorArea = showEditor && !isOnlyRecorder;

    /*const currentImageUrl =
      currentImage &&
      (learnerAssetsList.includes(currentImage)
        ? paths.learnerAssetsFolder
        : paths.activityAssetsFolder) + currentImage;*/

      const currentImageUrl =
        currentImage &&
        `${paths.assetsFolder}${currentImage}`;    

    const isPageHasAudio = !!activePage.audio;

    return (
      <>
        {!ebookMode && (
          <Instructions
            problem={gameData}
            autoPlay={false}
            wrapperStyle={{
              top: '1%',
              marginLeft: (65 / 2) * getScale() + 'px',
              // transform: 'noe',
              width: (1858 / 2) * getScale() + 'px',
            }}
          />
        )}

        <div
          style={{
            // marginLeft: this.pxToVmin(70),
            height: divideSize(1106),
            width: divideSize(1988),
            transform: 'translate(-50%, -50%) scale(' + getScale() + ')',
          }}
          className={classes.wrapper}
        >
          {!ebookMode && (
            <WhiteBox
              outerStyle={{
                position: 'absolute',
                top: 0,
                left: divideSize(0),
                width: divideSize(100),
                height: divideSize(700),
                fontSize: divideSize(40),
                padding: '15px',
                borderRadius: '30px',
              }}
              innerClass={classes.pagesListInner}
            >
              <PagesListBox
                pagesCount={isNaN(pagesCount) ? 0 : +pagesCount}
                activePage={activePageIdx}
                onChange={this.changeActivePageIdx}
              />
            </WhiteBox>
          )}

          <WhiteBox
            outerStyle={{
              top: ebookMode ? -50 : 0,
              width: divideSize(1858),
              left: divideSize(ebookMode ? 65 : 130),
              height: ebookMode ? 'auto' : divideSize(700),
              minHeight: divideSize(700),
              padding: '15px',
              borderRadius: '30px',
            }}
            outerClass={classnames(classes.editorWrapper, {
              [classes.editorEbookMode]: ebookMode,
            })}
            innerClass={classes.editorInner}
          >
            {showEditorArea && (
              <StoryStudioEditor
                readOnly={ebookMode}
                words={filteredWords}
                toolbar={options.editor}
                initialValue={activePage.text}
                changeHandler={this.onEditorChange}
                isLandscape={isLandscape}
                writingFeedbackEnabled={options.aiFeedbackEnabled !== false}
                getPagesText={this.getPagesText}
              />
            )}

            {((!ebookMode && isOnlyRecorder) ||
              (ebookMode && currentImageUrl)) && (
                <div
                  className={classnames(classes.currentImage, {
                    [classes.big]: isOnlyRecorder,
                  })}
                >
                  <img src={currentImageUrl} alt="" />
                </div>
              )}

            {ebookMode && !isPageHasAudio && (
              <>
                {activePageIdx !== 0 && (
                  <button
                    onClick={this.showPrevPage}
                    className={classnames(classes.navBtn, classes.prevBtn)}
                  >
                    <img src={prevBtnImg} alt="Previous" />
                  </button>
                )}

                {currentPages.length !== 0 &&
                  activePageIdx !== currentPages.length - 1 && (
                    <button
                      onClick={this.showNextPage}
                      className={classnames(classes.navBtn, classes.nextBtn)}
                    >
                      <img src={nextBtnImg} alt="Next" />
                    </button>
                  )}
              </>
            )}
          </WhiteBox>

          <div
            className={classes.bottomSectionWrapper}
            style={{
              height: divideSize(376),
              top: divideSize(720),
            }}
          >
            {showPictureBox && (
              <WhiteBox
                outerStyle={{
                  width: divideSize(1140),
                  padding: '15px',
                  borderRadius: '30px',
                }}
                innerStyle={{
                  padding: 0,
                  display: 'flex',
                }}
              >
                <PictureBox
                  paths={paths}
                  showList={
                    (!!filteredImages.length && !autoInsertArt) || importClipart
                  }
                  images={filteredImages}
                  learnerAssetsList={learnerAssetsList}
                  onChangeImage={this.onChangeImage}
                  onRemoveImage={this.onRemoveImage}
                  currentImage={currentImage}
                  withClipart={importClipart}
                />
              </WhiteBox>
            )}

            {showRecorder && (
              <PlayRecorder
                currentUrl={this.getCurrentAudioUrl()}
                uploadHandler={this.uploadAudio}
                deleteHandler={this.deleteAudio}
                classes={{
                  wrapper: classes.recorder,
                  inner: classes.recorderInner,
                  button: classes.recorderButton,
                }}
              />
            )}

            {options.needApproval && (
              <div className={classnames(classes.approvalCheckbox)}>
                <input
                  checked={approved}
                  onChange={this.toggleApprove}
                  id="approval-checkbox"
                  type="checkbox"
                />
                <label htmlFor="approval-checkbox">
                  {translate(850, 'Approval')}
                </label>
              </div>
            )}
          </div>
        </div>

        <WritingFeedback />

        <ActivityButtons
          buttons={[
            { type: GO_BACK, beforeClickHandler: this.onExit },
            {
              type: EBOOK_BUTTON,
              dontShow: ebookMode,
              onClick: this.startPreview,
            },
            {
              type: STOP,
              dontShow: !ebookMode,
              onClick: this.stopPreview,
            },
            {
              type: SPEAK,
              dontShow: !instruction.audio || ebookMode,
            },
            HELP,
          ]}
        />
      </>
    );
  }
}

StoryStudio.propTypes = {
  aspectRatio: PropTypes.number,
  learnerId: PropTypes.string,
  team: PropTypes.array,
  noReports: PropTypes.bool,
};

const mapStateToProps = ({ flinkPlay: { learner, team, learningCenter } }) => ({
  learnerId: learner && learner._id,
  noReports: !!(
    learningCenter &&
    learningCenter.config &&
    learningCenter.config.noReports
  ),
  team,
});

export default connect(mapStateToProps, {
  showStats,
  closeWritingFeedback,
})(StoryStudio);
