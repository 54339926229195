import API from 'api';
import {
  SET_EDITING_ACTIVITY,
  CHANGE_ACTIVITY_DATA,
  SET_DELETED_PROBLEMS,
  EDITING_ACTIVITY_SAVED,
  ADD_CONTENT_FILES_TO_DELETE,
  RESTORE_CONTENT_FILES,
} from 'actions/types';
import { uploading } from '../statusActions';
import { getActivityData } from '../gameActions';
import { setActivitiesUnsavedChanges } from './editList';
import { changeSettingsForReal } from './editList';
import { withLoading } from 'utils';
import { activitiesKey } from 'config';
import { prepareActivity } from 'activity-templates/utils';
import ActivityTemplates from 'activity-templates';

const filter = 'flinkMake/activity';

export const setEditingActivity = (id, activityTemplates, activeActivity = null) => (dispatch) =>
  withLoading(dispatch, async () => {
    if (!id) {
      return dispatch({
        filter,
        type: SET_EDITING_ACTIVITY,
        payload: null,
      });
    }

    try {
      const activity = await getActivityData(id, activityTemplates, activeActivity);

      if (!activity) {
        throw new Error('Failed to get Activity Data');
      }

      const preparedActivity = prepareActivity(activity);

      dispatch({
        filter,
        type: SET_EDITING_ACTIVITY,
        payload: preparedActivity,
      });

      return !!preparedActivity;
    } catch (err) {
      console.log(err);
      return false;
    }
  });

export const saveEditingActivity = () => (dispatch, getState) =>
  withLoading(dispatch, async () => {
    const {
      editingActivity,
      activityWasChanged,
      filesToDelete,
    } = getState().flinkMake.activity;

    if (!activityWasChanged) {
      return true;
    }

    const { alias } = editingActivity.template;
    const TemplateData = ActivityTemplates[alias];

    if (!TemplateData || !TemplateData.validateActivity) {
      throw new Error('Missing validate activity function');
    }

    const isValid = TemplateData.validateActivity(editingActivity.data);

    const { activity, data } = editingActivity;
    
    let versionType = '';
    if (activity.currentLanguageVersion && activity.currentLanguageVersion.version 
      && activity.currentLanguageVersion.locale ) {
      versionType = activity.currentLanguageVersion.version+'_'+activity.currentLanguageVersion.locale;
    }
    
    // Save activity data to S3
    return API.activity
      .saveActivityData(activity._id, data)
      .then(async (res) => {
        // If activity isValid was changed, then save it to DB
        if (activity.isValid !== isValid) {
          activity.isValid = isValid;

          await changeSettingsForReal([activity])(dispatch);
        }

        // Remove content files
        if (filesToDelete.length) {
          const path = `${activitiesKey}/${activity._id}/gamedata`;
          const keys = filesToDelete.map((f) => `${path}/${f}`);
console.log('filesToDelete gamedata-path: ', [path,filesToDelete])
          await API.storage.deleteFiles(keys);
        }

        if (filesToDelete.length && versionType) {
          const path = `${activitiesKey}/${activity._id}/languageVersionData/${versionType}`;
          const keys = filesToDelete.map((f) => `${path}/${f}`);
console.log('filesToDelete languageVersionData-path: ', [path,filesToDelete])
          await API.storage.deleteFiles(keys);
        }

        dispatch({
          filter,
          type: EDITING_ACTIVITY_SAVED,
        });

        dispatch(setActivitiesUnsavedChanges(false));

        return true;
      })
      .catch((err) => {
        alert('Error while saving data. Try again please');
        console.log(err);
        return false;
      });
  });

export const uploadAudioFile = (params) => async (dispatch) => {
  const { file, filename, path, fileToDelete, useUploadBucket } = params;

  const key = `${path}/${filename}`;

  const success = await API.storage.uploadFile(key, file, {
    onUploadProgress: (progress) => dispatch(uploading(progress)),
  }, useUploadBucket);

  dispatch(uploading(null));

  if (!success) {
    return false;
  }

  if (fileToDelete) {
    const key = `${path}/${fileToDelete}`;
    await API.storage.deleteFiles([key]);
  }

  return true;

  // return Storage.put(key, file, {
  //   contentType: 'audio/mpeg',
  //   progressCallback: (progress) => dispatch(uploading(progress)),
  // })
  // .then((result) => {
  //   dispatch(uploading(null));
  //   console.log('upload result', result);

  //   return fileToDelete
  //     ? Storage.remove(path + '/' + fileToDelete).then((result) =>
  //         console.log('remove result', result)
  //       )
  //     : Promise.resolve(filename);
  // })
  // .then((result) => {
  //   return filename;
  // })
  // .catch((err) => {
  //   console.log(err);
  //   return false;
  // });
};

// export const copyContentFilesInBucket = (array, activityId) => {
//   const path = `${activitiesKey}/${activityId}/gamedata/`;

//   const params = array.map(set => ({
//     src: { key: path + set.src, uploadBucket: true },
//     dest: { key: path + set.dest, uploadBucket: true }
//   }));

//   return API.storage.copyFiles(params);
// };

export const changeGameData = (propPath, val) =>
    changeActivityData(`gameData.${propPath}`, val);

export const changeLanguageVersionData = (propPath, val) =>
  changeActivityData(`languageVersionData.${propPath}`, val);

export const changeOptions = (propPath, val) =>
  changeActivityData(`options.${propPath}`, val);

// export const changeHelpHtml = val => changeActivityData('helpHtml', val);

// export const changeHelpAudio = val => changeActivityData('helpAudio', val);

export const changeActivityImage = (val) =>
  changeActivityData('activityImage', val);

export const changeActivityData = (propPath, val) => (dispatch, getState) => {
  const hasUnsavedChanges = getState().flinkMake.editList.hasUnsavedChanges;

  !hasUnsavedChanges && dispatch(setActivitiesUnsavedChanges(true));

  dispatch({
    filter,
    type: CHANGE_ACTIVITY_DATA,
    payload: { propPath, val },
  });
};

export const removeContentFiles = (payload) => {
  return {
    filter,
    type: ADD_CONTENT_FILES_TO_DELETE,
    payload,
  };
};

export const restoreContentFiles = (payload) => {
  return {
    filter,
    type: RESTORE_CONTENT_FILES,
    payload,
  };
};

export const setDeletedProblems = (payload) => ({
  filter,
  type: SET_DELETED_PROBLEMS,
  payload,
});
