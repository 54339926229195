import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import GameModal from '../GameModal/GameModal';
import { withGetStringAudio } from 'components/hocs';
import { closeActivityHelp } from 'actions/gameActions';
import { toggleAudio, stopAudio } from 'actions/audioActions';
import { s3bucketPublicURL, imagesURL } from 'config';
import { formatHtmlForPlay, getTranslateFunc } from 'utils';

const ActivityHelp = ({
  audioSrc,
  isPlaying,
  strings,
  currentActivity,
  closeActivityHelp,
  solutionLocale,
  generalTranslate,
  solutionTranslate,
  getStringAudio,
  versionType
}) => {
  const {
    data,
    activity: { contentFolder, locale: activityLocale },
  } = currentActivity;

  const { multiLocaleHelp, helpLocale } = data;

  const translate = useMemo(() => {
    // Determine which translate function to use for Title and Buttons in dialog
    // If Activity has attribute "helpLocale", then generate translate function
    // based on that, if no - use solutionTranslate in priority
    // (will be in Flink Play app), if no - then use generalTranslate (login screen)
    if (helpLocale) {
      return getTranslateFunc(strings, helpLocale);
    }

    return solutionTranslate || generalTranslate;
  }, [helpLocale, strings, solutionTranslate, generalTranslate]);

  const locale =
    (solutionLocale && solutionLocale.code) ||
    (multiLocaleHelp && multiLocaleHelp.defaultLocale) ||
    'en';

  let helpHtml = multiLocaleHelp
    ? multiLocaleHelp[locale] && multiLocaleHelp[locale].helpHtml
    : data.helpHtml;

  let helpAudio = multiLocaleHelp
    ? multiLocaleHelp[locale] && multiLocaleHelp[locale].helpAudio
    : data.helpAudio;

  let helpString = multiLocaleHelp
    ? multiLocaleHelp[locale] && multiLocaleHelp[locale].helpString
    : data.helpString;

  let audioHelpUrl = helpAudio
    ? (versionType 
    ? `${s3bucketPublicURL}/${contentFolder}/languageVersionData/${versionType}/${helpAudio}` 
    :`${s3bucketPublicURL}/${contentFolder}/gamedata/${helpAudio}`)
    : null;

  if (helpString) {
    const { audio, path } = getStringAudio(helpString, true);

    helpHtml = translate(helpString, '', false, true, 
      versionType ? versionType.split('_')[1] : activityLocale);
    helpAudio = audio;
    if (helpAudio)
      audioHelpUrl = path + audio;
  }

  const audioIcon = `${imagesURL}/Images/Audio/${audioSrc === audioHelpUrl && isPlaying ? 'audiostop' : 'audio_icon1'
    }.png`;

  const closeModal = useCallback(() => {
    closeActivityHelp();
    stopAudio();
  }, [closeActivityHelp]);

  useEffect(() => {
    if (!audioHelpUrl) return;
    toggleAudio(audioHelpUrl, { onSuccessEnd: closeModal });
  }, [audioHelpUrl, closeModal]);

  // Close dialog if user clicks "esc" or "enter" buttons
  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.keyCode === 13 || e.keyCode === 27) {
        e.preventDefault();
        closeModal();
      }
    };

    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [closeModal]);

  const formattedHtml = useMemo(() => formatHtmlForPlay({ html: helpHtml }), [
    helpHtml,
  ]);

  return (
    <GameModal
      title={translate(66, 'Help')}
      buttons={[
        {
          // title: translate(224, 'Listen'),
          clickHandler: (e) => toggleAudio(audioHelpUrl),
          dontShow: !audioHelpUrl,
          icon: audioIcon,
        },
        {
          // title: translate(80, 'Close'),
          clickHandler: closeModal,
          icon: `${imagesURL}/Images/Help/Close50.png`,
        },
      ]}
    >
      <div
        style={{ fontSize: '2vmin' }}
        dangerouslySetInnerHTML={{ __html: formattedHtml }}
      />
    </GameModal>
  );
};

ActivityHelp.propTypes = {
  audioSrc: PropTypes.string,
  isPlaying: PropTypes.bool,
  strings: PropTypes.array.isRequired,
  solutionLocale: PropTypes.object,
  currentActivity: PropTypes.object.isRequired,
  generalTranslate: PropTypes.func.isRequired,
  solutionTranslate: PropTypes.func,
  getStringAudio: PropTypes.func.isRequired,
  versionType: PropTypes.string,
};

const mapStateToProps = ({
  audio,
  game,
  common,
  status,
  flinkPlay: { solutionLocale, solutionTranslate },
}) => ({
  audioSrc: audio.audioSrc,
  isPlaying: audio.isPlaying,
  strings: common.strings,
  solutionLocale,
  currentActivity: game.currentActivity,
  generalTranslate: status.translate,
  solutionTranslate,
});

export default compose(withGetStringAudio, connect(mapStateToProps, { closeActivityHelp }))(
  ActivityHelp
);
