import React, { useEffect, useState, useMemo, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withTranslate } from 'components/hocs';
import classes from './SelectEducationalCategory.module.scss';
import CategorySelect from './CategorySelect/CategorySelect';
import ActivityEdusModal from './ActivityEdusModal/ActivityEdusModal';
import { SUPER, MASTER_ADMIN } from 'consts/user-roles';

import { Scope } from 'utils';

const setCategoryProp = (
  itemCategories,
  scopeId,
  changeHandler,
  oneSet,
  prop,
  value
) => {
  const edusCopy = JSON.parse(JSON.stringify(itemCategories));

  let curentEdu = _.find(edusCopy, { scopeId });

  if (!curentEdu) {
    curentEdu = { scopeId };
    edusCopy.push(curentEdu);
  }

  if (value) {
    curentEdu[prop] = value;
  } else {
    delete curentEdu[prop];
  }

  switch (prop) {
    case 'grade':
      delete curentEdu.firstCategory;
      delete curentEdu.secondCategory;
      delete curentEdu.thirdCategory;
      break;
    case 'level':
      delete curentEdu.firstCategory;
      delete curentEdu.secondCategory;
      delete curentEdu.thirdCategory;
      break;
    case 'firstCategory':
      delete curentEdu.secondCategory;
      delete curentEdu.thirdCategory;
      break;
    case 'secondCategory':
      delete curentEdu.thirdCategory;
      break;
    default:
  }

  if (!value && (prop === 'grade' || prop === 'level')) {
    const newSet = oneSet ? [] : _.reject(edusCopy, curentEdu);
    changeHandler(newSet, scopeId);
  } else {
    const newSet = oneSet ? [curentEdu] : edusCopy;
    changeHandler(newSet, scopeId);
  }
};

const setMultipleEdus = (itemEdus, newEdus, scopeId, changeHandler) => {
  let edusCopy = JSON.parse(JSON.stringify(itemEdus));
  edusCopy = _.reject(edusCopy, { scopeId });
  edusCopy.push(...newEdus);
  changeHandler(edusCopy, scopeId);
};

const SelectEducationalCategory = ({
  auth,
  scopes,
  current,
  oneSet,
  disabled,
  translate,
  onScopeChange,
  changeHandler,
  disableMultiple,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedScopeId, setSelectedScopeId] = useState();
  const [isMultipleAttributes, setMultipleAttributes] = useState(false);

  const {
    user: { available },
    userRole,
  } = auth;
  const sortedScopes = useMemo(() => {
    const restrictedRoles = [MASTER_ADMIN, SUPER];
    if (!restrictedRoles.includes(userRole.alias)) {
      return _.chain(scopes)
        .filter((s) => available && available.scopes && available.scopes[s._id])
        .sortBy('name')
        .value();
    }
    return _.sortBy(scopes, 'name');
  }, [scopes, userRole, available]);

  useEffect(() => {
    if (!sortedScopes.length) return;

    if (!current || !current.length) {
      if (selectedScopeId) return;

      setSelectedScopeId(selectedScopeId || sortedScopes[0]._id);
      return;
    }

    if (selectedScopeId) {
      const isCurrentHaveSelectedScope =
        _.findIndex(current, { scopeId: selectedScopeId }) !== -1;

      if (isCurrentHaveSelectedScope) return;
    }

    const scopeIdToSelect = current.find((edu) =>
      sortedScopes.find((s) => s._id === edu.scopeId)
    ).scopeId;

    setSelectedScopeId(scopeIdToSelect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    const isMultipleAttributes =
      _.filter(current, {
        scopeId: selectedScopeId,
      }).length > 1;

    setMultipleAttributes(isMultipleAttributes);
    onScopeChange && onScopeChange(selectedScopeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScopeId]);

  const disableUncheckMultipleAttrs =
    isMultipleAttributes &&
    _.filter(current, {
      scopeId: selectedScopeId,
    }).length > 1;

  const selectedScope = useMemo(() => {
    const scope = _.find(sortedScopes, { _id: selectedScopeId });
    return scope ? new Scope(scope) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScopeId]);

  const currentEducationalCategory =
    (selectedScopeId && _.find(current, { scopeId: selectedScopeId })) || {};

  const singleFirstMenu =
    selectedScope && selectedScope.getSingleFirstMenuTitle();

  const firstCategoryItems =
    (currentEducationalCategory[singleFirstMenu] &&
      selectedScope.getFirstCategoryItems(
        currentEducationalCategory[singleFirstMenu]
      )) ||
    [];

  const secondCategoryItems = useMemo(() => {
    if (
      !selectedScope ||
      !currentEducationalCategory ||
      !currentEducationalCategory.firstCategory
    ) {
      return [];
    }

    const { firstCategory: firstCategoryId } = currentEducationalCategory;

    return selectedScope.getSecondCategoryItems(
      { firstCategoryId },
      currentEducationalCategory[singleFirstMenu]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, selectedScope]);

  const thirdCategoryItems = useMemo(() => {
    if (
      !selectedScope ||
      !currentEducationalCategory ||
      !currentEducationalCategory.secondCategory
    ) {
      return [];
    }

    const { secondCategory: secondCategoryId } = currentEducationalCategory;

    return selectedScope.getThirdCategoryItems(
      { secondCategoryId },
      currentEducationalCategory[singleFirstMenu]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, selectedScope]);

  const changeEduCategoryProp = setCategoryProp.bind(
    null,
    current,
    selectedScopeId,
    changeHandler,
    oneSet
  );

  return (
    <Fragment>
      <label className={classes.form__label}>
        {translate(368, 'Educational Categories:')}
      </label>
      <select
        disabled={disabled}
        style={{ width: '100%' }}
        value={selectedScopeId || ''}
        onChange={(e) => {
          setSelectedScopeId(e.target.value);

          if (oneSet) {
            changeHandler([], e.target.value);
          }
        }}
      >
        <option value=""></option>
        {sortedScopes.map((scope) => (
          <option key={scope._id} value={scope._id}>
            {scope.name}
          </option>
        ))}
      </select>

      {!disableMultiple && (
        <div className={classes.multipleBlock}>
          <label className={classes.checkbox}>
            <input
              type="checkbox"
              checked={isMultipleAttributes}
              onChange={(e) => setMultipleAttributes(e.target.checked)}
              disabled={disabled || disableUncheckMultipleAttrs}
            />
            {translate(378, 'Multiple attributes')}
          </label>

          {isMultipleAttributes && (
            <button
              disabled={disabled}
              className={classes.btn}
              type="button"
              onClick={() => setShowModal(true)}
            >
              {translate(484, 'Show')}
            </button>
          )}
        </div>
      )}

      {!disableMultiple && isMultipleAttributes && selectedScope && (
        <ActivityEdusModal
          scope={selectedScope}
          edus={current.filter((edu) => edu.scopeId === selectedScopeId)}
          show={showModal}
          onClose={(edus) => {
            setShowModal(false);
            setMultipleEdus(current, edus, selectedScopeId, changeHandler);
            if (edus.length === 1) {
              setMultipleAttributes(false);
            }
            console.log(edus);
          }}
        />
      )}

      {!isMultipleAttributes && selectedScope && (
        <div>
          <CategorySelect
            disabled={disabled}
            label={_.capitalize(selectedScope.getFirstMenu())}
            value={currentEducationalCategory[singleFirstMenu]}
            changeHandler={changeEduCategoryProp.bind(null, singleFirstMenu)}
            defaultText={`Select ${singleFirstMenu}`}
            items={selectedScope.getFirstMenuItems()}
          />

          {currentEducationalCategory[singleFirstMenu] &&
            !!firstCategoryItems.length && (
              <CategorySelect
                disabled={disabled}
                label={selectedScope.getFirstCategoryTitle()}
                value={currentEducationalCategory.firstCategory}
                changeHandler={changeEduCategoryProp.bind(
                  null,
                  'firstCategory'
                )}
                defaultText={translate(486, '- All -')}
                items={firstCategoryItems}
              />
            )}

          {currentEducationalCategory.firstCategory &&
            !!secondCategoryItems.length && (
              <CategorySelect
                disabled={disabled}
                label={selectedScope.getSecondCategoryTitle()}
                value={currentEducationalCategory.secondCategory}
                changeHandler={changeEduCategoryProp.bind(
                  null,
                  'secondCategory'
                )}
                defaultText={translate(486, '- All -')}
                items={secondCategoryItems}
              />
            )}

          {currentEducationalCategory.secondCategory &&
            !!thirdCategoryItems.length && (
              <CategorySelect
                disabled={disabled}
                label={selectedScope.getThirdCategoryTitle()}
                value={currentEducationalCategory.thirdCategory}
                changeHandler={changeEduCategoryProp.bind(
                  null,
                  'thirdCategory'
                )}
                defaultText={translate(486, '- All -')}
                items={thirdCategoryItems}
              />
            )}
        </div>
      )}
    </Fragment>
  );
};

SelectEducationalCategory.propTypes = {
  auth: PropTypes.object.isRequired,
  scopes: PropTypes.array.isRequired,
  changeHandler: PropTypes.func.isRequired,
  onScopeChange: PropTypes.func,
};

const mapStateToProps = ({ auth, flinkMake }) => ({
  auth,
  scopes: flinkMake.common.scopes,
});

export default compose(
  withTranslate,
  connect(mapStateToProps)
)(SelectEducationalCategory);
