import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default () => {
  const locales = useSelector((state: any) => state.common.locales);
  const emailsPageLocales = useSelector(
    (state: any) => state.common.config?.emailsLocales
  );

  const localesOptions: { label: string; value: string }[] = useMemo(() => {
    if (!emailsPageLocales || !locales) {
      return [];
    }
    return emailsPageLocales.map((localeCode: string) => {
      const locale = locales.find((loc: any) => loc.code === localeCode);
      return { label: locale.displayName, value: locale.code };
    });
  }, [emailsPageLocales, locales]);

  return localesOptions;
};
