import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import TouchBackend from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { activitiesURL } from 'config';

import ActivityTemplates from 'activity-templates';
import {
  ActivityHelp,
  ActivityTeamHelp,
  GameFeedback,
  // Character,
  ParentAdvice,
  ActivityResults,
} from 'components/flink-play';
import {
  setCurrentActivity,
  openActivityHelp,
  closeActivityHelp,
  openActivityTeamHelp,
  closeActivityTeamHelp,
  setGameInitialized,
  showFeedback,
  addIdleTime,
} from 'actions/gameActions';
import { stopAudio } from 'actions/audioActions';
// import { mobileAndTabletCheck } from "utils";
import { filesURL } from 'config';
import IdleTracker from 'components/common/IdleTracker';
import { withGetStringAudio } from 'components/hocs';

import './TemplateWrapper.scss';

class TemplateWrapper extends Component {
  state = {};

  templateWrapper = React.createRef();

  calcTextStyles = () => {
    const { currentActivity } = this.props;

    this.setState({
      textStyle: currentActivity &&
        currentActivity.data &&
        currentActivity.data.options && {
          color: currentActivity.data.options.fontColor || '#000',
          fontSize: (currentActivity.data.options.fontSize || 18) / 11 + 'vmin',
          fontFamily:
            currentActivity.data.options.fontFamily || 'Roboto, sans-serif',
        },
    });
  };

  componentDidMount() {
    const {
      preview,
      team,
      activity,
      closeHandler,
      activityTemplates,
      setCurrentActivity,
    } = this.props;

    disableBodyScroll();

    // mobileAndTabletCheck() &&
    //   document.body.addEventListener("click", this.requestFullscreen);

    setCurrentActivity(activity, activityTemplates, {
      isPreview: preview,
      goBackHandler: closeHandler,
      players: team,
    });
  }

  requestFullscreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      return;
    }

    const rootElem = document.getElementById('rootElem');

    if (rootElem.requestFullScreen) {
      rootElem.requestFullScreen();
    } else if (rootElem.mozRequestFullScreen) {
      rootElem.mozRequestFullScreen();
    } else if (rootElem.webkitRequestFullScreen) {
      rootElem.webkitRequestFullScreen();
    }
  };

  componentDidUpdate(prevProps) {
    const {
      isTeam,
      settings,
      currentActivity,
      initialActivityHelpShown,
      initialTeamActivityHelpShown,
      isActivityHelpOpened,
      isTeamActivityHelpOpened,
      history,
      closeHandler,
      gameInitialized,
      shouldGameInit,
    } = this.props;
    const { Template: TemplateInState } = this.state;

    if (currentActivity && !TemplateInState) {
      let Template;

      try {
        const TemplateData = ActivityTemplates[currentActivity.template.alias];

        if (!TemplateData) {
          throw new Error('template not found');
        }

        const isValidActivity = TemplateData.validateActivity(
          currentActivity.data
        );

        if (!isValidActivity) {
          throw new Error('Not enought data for activity');
        }

        if (TemplateData.Template) {
          Template = TemplateData.Template;
        } else {
          throw new Error('template not found');
        }
      } catch (e) {
        console.error(e.message);
      }

      if (!Template) return closeHandler ? closeHandler() : history.goBack();

      this.setState({ Template }, this.calcTextStyles);
    }

    if (
      shouldGameInit &&
      !gameInitialized &&
      prevProps.shouldGameInit === false
    ) {
      this.startTemplate();
    }

    const showTeamHelp = false && isTeam && settings && settings.teamHelp;

    // If is team then show team activity help
    if (
      showTeamHelp &&
      !initialTeamActivityHelpShown &&
      !isTeamActivityHelpOpened
    ) {
      this.showInitialTeamActivityHelp();
    }

    // If is single learner then show activity help
    if (!showTeamHelp && !initialActivityHelpShown && !isActivityHelpOpened) {
      this.showInitialActivityHelp();
    }
  }

  showInitialTeamActivityHelp = () => {
    const {
      openActivityTeamHelp,
      closeActivityTeamHelp,
      settings,
      isTeam,
    } = this.props;

    if (!isTeam) return closeActivityTeamHelp();

    if (!settings || (settings && settings.teamHelp)) {
      openActivityTeamHelp();
    } else {
      closeActivityTeamHelp();
    }
  };

  showInitialActivityHelp = () => {
    const { openActivityHelp, closeActivityHelp, settings } = this.props;

    if (!settings || (settings && settings.activityHelp)) {
      openActivityHelp();
      // closeActivityHelp(); // for testing to disable help
    } else {
      closeActivityHelp();
    }
  };

  startTemplate = () => {
    console.log('Starting Game');
    document.dispatchEvent(new CustomEvent('startGame'));
    this.props.setGameInitialized();
  };

  componentWillUnmount() {
    const { setCurrentActivity } = this.props;

    // document.body.removeEventListener("click", this.requestFullscreen);
    // if (
    //   document.fullscreenElement ||
    //   document.webkitFullscreenElement ||
    //   document.mozFullScreenElement
    // ) {
    //   document.exitFullscreen();
    // }
    clearAllBodyScrollLocks();
    setCurrentActivity(null);
    stopAudio();
  }

  render() {
    const {
      translate,
      getStringAudio,
      preview,
      aspectRatio,
      addIdleTime,
      showFeedback,
      solutionLocale,
      currentActivity,
      isActivityHelpOpened,
      isTeamActivityHelpOpened,
      multiplayerModeEnabled,
      versionType,
      isPlayVersion
    } = this.props;
    const { Template, textStyle } = this.state;
    console.log('TemplateWrapper versionType: ',versionType);
    console.log('TemplateWrapper isPlayVersion: ',isPlayVersion);
    const backgroundImage = preview
      ? `url(${filesURL}/Themes/KidsPatterns/Backgrounds/background4.jpg)`
      : undefined;

    const styles = preview ? { backgroundImage } : { background: 'none' };

    return (
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <div
          className="template-wrapper"
          style={styles}
          ref={this.templateWrapper}
        >
          {currentActivity && Template && (
            <>
              <IdleTracker
                triggerHandler={addIdleTime}
                triggerMs={10 * 60 * 1000}
              />
              {isActivityHelpOpened && <ActivityHelp versionType={versionType} />}
              {isTeamActivityHelpOpened && <ActivityTeamHelp />}

              <ActivityResults />
              <GameFeedback versionType={versionType} />

              <Template
                contentFolder={versionType 
                  ? `${activitiesURL}/${currentActivity.activity._id}/languageVersionData/${versionType}/` 
                  : `${activitiesURL}/${currentActivity.activity._id}/gamedata/`}
                textStyle={textStyle || {}}
                solutionLocale={solutionLocale}
                activity={currentActivity}
                preview={preview}
                translate={translate}
                getStringAudio={getStringAudio}
                aspectRatio={aspectRatio}
                showFeedback={showFeedback}
                multiplayerModeEnabled={multiplayerModeEnabled}
                isPlayVersion={isPlayVersion}
              />

              {preview && <ParentAdvice />}
              {/* {preview && <Character />} */}
            </>
          )}
        </div>
      </DndProvider>
    );
  }
}

TemplateWrapper.propTypes = {
  translate: PropTypes.func.isRequired,
  getStringAudio: PropTypes.func.isRequired,
  settings: PropTypes.object,
  solutionLocale: PropTypes.object,
  openActivityHelp: PropTypes.func.isRequired,
  closeActivityHelp: PropTypes.func.isRequired,
  openActivityTeamHelp: PropTypes.func.isRequired,
  closeActivityTeamHelp: PropTypes.func.isRequired,
  addIdleTime: PropTypes.func.isRequired,
  isActivityHelpOpened: PropTypes.bool,
  isTeamActivityHelpOpened: PropTypes.bool,
  initialActivityHelpShown: PropTypes.bool,
  initialTeamActivityHelpShown: PropTypes.bool,
  aspectRatio: PropTypes.number,
  isTeam: PropTypes.bool,
  versionType: PropTypes.string,
  isPlayVersion: PropTypes.bool,
};

const mapStateToProps = ({
  auth,
  game,
  common,
  flinkPlay: { learner, team, solutionTranslate, solutionLocale },
  status,
}) => ({
  isTeam: auth.isTeam,
  team: learner ? [learner, ...(team ?? [])] : [],
  multiplayerModeEnabled: game.multiplayerModeEnabled,
  solutionLocale: solutionLocale || status.lang,
  currentActivity: game.currentActivity,
  initialActivityHelpShown: game.initialActivityHelpShown,
  initialTeamActivityHelpShown: game.initialTeamActivityHelpShown,
  isActivityHelpOpened: game.isActivityHelpOpened,
  isTeamActivityHelpOpened: game.isTeamActivityHelpOpened,
  shouldGameInit: game.shouldGameInit,
  gameInitialized: game.gameInitialized,
  activityTemplates: common.activityTemplates,
  settings: learner && learner.settings,
  translate: solutionTranslate || status.translate,
  aspectRatio: status.aspectRatio,
});

export default compose(
  withRouter,
  withGetStringAudio,
  // DragDropContext(TouchBackend({ enableMouseEvents: true })),
  connect(mapStateToProps, {
    addIdleTime,
    setCurrentActivity,
    openActivityHelp,
    closeActivityHelp,
    openActivityTeamHelp,
    closeActivityTeamHelp,
    setGameInitialized,
    showFeedback,
  })
)(TemplateWrapper);
