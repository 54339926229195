import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";

import classes from "./GroupsSwitch.module.scss";
import { withTranslate } from "components/hocs";

class GroupsSwitch extends Component {
  state = {
    activityGroupName: "educationalCategories"
  };

  changePropHandler = (prop, value) => {
    this.setState({ [prop]: value },
      ()=>{
        this.props.clickSwitchHandler(this.state.activityGroupName);
      });    
  };

  render() {
    const { translate } = this.props;
    const { activityGroupName } = this.state;

    return (
      <div className={classes.wrapper}>
        <label className={classes.radioGroup}>
          <input
            type="radio"
            checked={activityGroupName === "educationalCategories"}
            value="educationalCategories"
            onChange={e =>
              this.changePropHandler("activityGroupName", e.target.value)
            }
          />
          <span>{translate(1384, "Educational Categories Activity Groups")}</span>
        </label>

        <label className={classes.radioGroup}>
          <input
            type="radio"
            checked={activityGroupName === "menu"}
            value="menu"
            onChange={e =>
              this.changePropHandler("activityGroupName", e.target.value)
            }
          />
          <span>{translate(1385, "Menu Activity Groups")}</span>
        </label>
      </div>
    );
  }
}

GroupsSwitch.propTypes = {
  clickSwitchHandler: PropTypes.func.isRequired,
};

export default compose(
  withTranslate
)(GroupsSwitch);
