import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { options as selectOptions } from 'activity-templates/utils';
import { ColorPickerField } from 'components/common';
import classes from './ProblemOptions.module.scss';
import { withTranslate } from 'components/hocs';

const ProblemOptions = ({
  options,
  activeProblem,
  activeProblemIdx,
  translate,
  changeHandler,
  questionItems,
  correctItems,
  incorrectItems,
  versionType
}) => {
  return (
    <div className={classes.options}>
      {options.includes('feedback') && (
        <div className={classes.radioSelect}>
          <label className={classes.radioLabel}>
            <input
              type="checkbox"
              checked={
                (activeProblem && activeProblem.specificFeedback) || false
              }
              onChange={(e) => {
                if (versionType) 
                  changeHandler(
                    `${versionType}.problems[${activeProblemIdx}].specificFeedback`,
                    e.target.checked
                  );
                else
                  changeHandler(
                    `problems[${activeProblemIdx}].specificFeedback`,
                    e.target.checked
                  );
              }}
            />
            {translate(949, 'Specific Feedback')}
          </label>
        </div>
      )}
      {options.includes('answerBelongs') && (
        <div className={classes.radioSelect}>
          <label className={classes.radioLabel}>
            <input
              type="radio"
              checked={(activeProblem && activeProblem.answerBelongs) || false}
              onChange={(e) => {
                if (versionType) 
                  changeHandler(
                    `${versionType}.problems[${activeProblemIdx}].answerBelongs`,
                    e.target.checked
                  );
                else
                  changeHandler(
                    `problems[${activeProblemIdx}].answerBelongs`,
                    e.target.checked
                  );
              }}
            />
            {translate(582, 'Answer "Belongs"')}
          </label>
          <label className={classes.radioLabel}>
            <input
              type="radio"
              checked={(activeProblem && !activeProblem.answerBelongs) || false}
              onChange={(e) => {
                if (versionType) 
                  changeHandler(
                    `${versionType}.problems[${activeProblemIdx}].answerBelongs`,
                    !e.target.checked
                  );
                else
                  changeHandler(
                    `problems[${activeProblemIdx}].answerBelongs`,
                    !e.target.checked
                  );
              }}
            />
            {translate(583, 'Answer "Does Not Belong"')}
          </label>
        </div>
      )}

      {questionItems && (
        <div className={classes.itemsShownSelect}>
          <label>{translate(0, 'Question Items Shown:')}</label>
          <select
            value={activeProblem && activeProblem.questionItemsShown}
            onChange={(e) => {
              if (versionType) 
                changeHandler(
                  `${versionType}.problems[${activeProblemIdx}].questionItemsShown`,
                  +e.target.checked
                );
              else
                changeHandler(
                  `problems[${activeProblemIdx}].questionItemsShown`,
                  +e.target.checked
                );
            }}
          >
            {_.range(questionItems.min, questionItems.max + 1).map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
      )}

      {correctItems && (
        <div className={classes.itemsShownSelect}>
          <label>{translate(588, 'Correct Items Shown:')}</label>
          <select
            value={activeProblem && activeProblem.correctItemsShown}
            onChange={(e) => {
              if (versionType) 
                changeHandler(
                  `${versionType}.problems[${activeProblemIdx}].correctItemsShown`,
                  +e.target.checked
                );
              else
                changeHandler(
                  `problems[${activeProblemIdx}].correctItemsShown`,
                  +e.target.checked
                );
            }}
          >
            {_.range(correctItems.min, correctItems.max + 1).map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
      )}

      {incorrectItems && (
        <div className={classes.itemsShownSelect}>
          <label>{translate(589, 'Incorrect Items Shown:')}</label>
          <select
            value={activeProblem && activeProblem.incorrectItemsShown}
            onChange={(e) => {
              if (versionType) 
                changeHandler(
                  `${versionType}.problems[${activeProblemIdx}].incorrectItemsShown`,
                  +e.target.checked
                );
              else
                changeHandler(
                  `problems[${activeProblemIdx}].incorrectItemsShown`,
                  +e.target.checked
                );
            }}
          >
            {_.range(incorrectItems.min, incorrectItems.max + 1).map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
      )}

      {(options.includes('fontFamily') ||
        options.includes('fontSize') ||
        options.includes('fontColor')) && (
        <div className={classes.fontControls}>
          <label>{translate(369, 'Font:')}</label>
          {options.includes('fontFamily') && (
            <div className={classes.fontFamilySelect}>
              <select
                value={activeProblem && activeProblem.fontFamily}
                onChange={(e) => {
                  if (versionType) 
                    changeHandler(
                      `${versionType}.problems[${activeProblemIdx}].fontFamily`,
                      e.target.checked
                    );
                  else
                    changeHandler(
                      `problems[${activeProblemIdx}].fontFamily`,
                      e.target.checked
                    );
                }}
              >
                {selectOptions.fontFamily.map((font) => (
                  <option key={font} value={font}>
                    {font}
                  </option>
                ))}
              </select>
            </div>
          )}

          {options.includes('fontSize') && (
            <div className={classes.fontSizeSelect}>
              <select
                value={activeProblem && activeProblem.fontSize}
                onChange={(e) => {
                  if (versionType) 
                    changeHandler(
                      `${versionType}.problems[${activeProblemIdx}].fontSize`,
                      e.target.checked
                    );
                  else
                    changeHandler(
                      `problems[${activeProblemIdx}].fontSize`,
                      e.target.checked
                    );
                }}
              >
                {selectOptions.fontSize.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          )}

          {options.includes('fontColor') && (
            <ColorPickerField
              label={translate(455, 'Color:')}
              changeHandler={(color) => {
                if (versionType) 
                  changeHandler(
                    `${versionType}.problems[${activeProblemIdx}].fontColor`,
                    color.hex
                  );
                else
                  changeHandler(
                    `problems[${activeProblemIdx}].fontColor`,
                    color.hex
                  );
              }}
              currentColor={activeProblem && activeProblem.fontColor}
            />
          )}
        </div>
      )}
    </div>
  );
};

ProblemOptions.propTypes = {
  activeProblem: PropTypes.object,
  changeHandler: PropTypes.func.isRequired,
  activeProblemIdx: PropTypes.number,
  translate: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  versionType: PropTypes.string
};

export default withTranslate(ProblemOptions);
