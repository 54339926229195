import { axiosAPI, withLoading } from 'utils';

export const fetchActivities = (params = {}) => dispatch =>
  withLoading(dispatch, () => {
    return axiosAPI
      .get(`api/flink-make/activities`, { params })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err);
        return [];
      });
  });

export const moveToEditList = activitiesIds => dispatch =>
  withLoading(dispatch, async () =>
    axiosAPI
      .post('/api/flink-make/activities/move-to-edit-list', { activitiesIds })
      .then(res => {
        if (!res.data.success) {
          return console.log(res.data);
        }

        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      })
  );

export const currentLangClean = activitiesIds => dispatch =>
  withLoading(dispatch, async () =>
    axiosAPI
      .post('/api/flink-make/activities/current-lang-clean', { activitiesIds })
      .then(res => {
        if (!res.data.success) {
          return console.log(res.data);
        }

        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      })
  );
