import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import shortId from 'shortid';

import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import {
  playCorrectSound,
  playIncorrectSound,
  playWatch,
} from 'actions/audioActions';
import { prepareProblems, getInstruction } from 'activity-templates/utils';
import { prepareProblem, validateProblem, validateItem } from './allTogetherNowHelpers';
import {
  Instructions,
  ActivityButtons,
  ProblemsProgress,
  MultiplayerOverlay,
} from 'components/flink-play';

import {
  changePlayerTurn,
  incrementPlayerPoints,
} from 'actions/gameActions';

import { stringProblemsMapper } from 'activity-templates/utils';

import BoardWithAnswers from './BoardWithAnswers/BoardWithAnswers';
import QuestionItem from './QuestionItem/QuestionItem';
import classes from './AllTogetherNow.module.scss';

const maxItems = 8;

const getItems = (problem) => {
  if (!problem) return null;

  const {
    correctAnswers,
    answerBelongs,
    correctItemsShown,
    incorrectAnswers,
    incorrectItemsShown,
  } = problem;

  let correctItems = _.chain(correctAnswers)
    .filter(validateItem)
    .map((i) => ({ ...i, correct: true }))
    .shuffle()
    .take(correctItemsShown)
    .value();
  let incorrectItems = _.chain(incorrectAnswers)
    .filter(validateItem)
    .map((i) => ({ ...i, correct: false }))
    .shuffle()
    .take(incorrectItemsShown)
    .value();

  const items = _.chain(correctItems)
    .concat(incorrectItems)
    .take(maxItems)
    .map((i) => ({
      ...i,
      checked: false,
      id: shortId.generate(),
      checkedBorderColor: answerBelongs ? 'green' : 'red',
    }))
    .shuffle()
    .value();

  return items;
};

class AllTogetherNow extends Component {
  /*constructor(props) {
    super(props);

    const { options } = props.activity.data;
    const { translate, getStringAudio } = props;
    
    const thisVersion = !props.isPlayVersion ? props.activity.activity.currentLanguageVersion 
    : {version: 'immersion', locale: props.solutionLocale.code};
    const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";    
    const gameData = versionType 
    ? props.activity.data.languageVersionData?.[versionType]
    : props.activity.data.gameData;

    const mapper = stringProblemsMapper({ translate, getStringAudio });
    const problems = prepareProblems(
      gameData.problems,
      options,
      validateProblem,
      prepareProblem(mapper),
    );

    this.state = {
      problems,
      currentProblem: null,
    };
  }*/

    constructor(props) {
      super(props);
  
      const { options } = props.activity.data;
      const { translate, getStringAudio } = props;
  
      const thisVersion = !props.isPlayVersion
          ? props.activity.activity.currentLanguageVersion
          : { version: 'immersion', locale: props.solutionLocale.code };
  
      const versionType =
          thisVersion && thisVersion.version && thisVersion.locale
              ? `${thisVersion.version}_${thisVersion.locale}`
              : "";
  
      const languageVersionDataExists = versionType
          ? !!props.activity.data.languageVersionData?.[versionType]
          : false;
  
      const gameData = languageVersionDataExists
          ? props.activity.data.languageVersionData[versionType]
          : props.activity.data.gameData;
  
      const mapper = stringProblemsMapper({ translate, getStringAudio });
      const problems = prepareProblems(
          gameData.problems,
          options,
          validateProblem,
          prepareProblem(mapper),
      );
  
      this.state = {
          problems,
          currentProblem: null,
          versionType,
          languageVersionDataExists, 
      };
  }
  

  componentDidMount() {
    document.addEventListener('startGame', this.startGame);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutBeforeNext);
    document.removeEventListener('audioEnded', this.setupNextQuestion);
    document.removeEventListener('startGame', this.startGame);
  }

  startGame = () => {
    const { problems } = this.state;
    const currentProblem = problems[0];
    const items = getItems(currentProblem);

    this.setState({
      currentProblem,
      items,
      currentIndex: 0,
      isResolved: false,
      problemsCount: problems.length,
      attemptsOnCurrentProblem: 0,
      answeredCorrectly: 0,
      answeredIncorrectly: 0,
      problemsLeft: problems.length,
    });
  };

  checkAnswer = () => {
    const { items, attemptsOnCurrentProblem } = this.state;
    const { 
      changePlayerTurn, 
      incrementPlayerPoints,
      multiplayerModeEnabled,
    } = this.props;

    let correct = true;

    items.forEach((i) => {
      if (!correct) return;
      if (i.correct && i.checked) return;

      if ((i.correct && !i.checked) || (!i.correct && i.checked)) {
        correct = false;
      }
    });

    if (correct) {
      this.setState({ isResolved: true, shouldChangeTurn: true });
      this.props.playCorrectSound();
      if (multiplayerModeEnabled) {
        incrementPlayerPoints(attemptsOnCurrentProblem);
      };

      document.addEventListener('audioEnded', this.setupNextQuestion, {
        once: true,
      });
      return;
    }

    this.props.playIncorrectSound();
    if (multiplayerModeEnabled) {
      changePlayerTurn();
    };

    this.setState((state) => ({
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
      answeredIncorrectly:
        state.attemptsOnCurrentProblem === 0
          ? state.answeredIncorrectly + 1
          : state.answeredIncorrectly,
    }));
  };

  finishGame = () => {
    const { answeredCorrectly, answeredIncorrectly, problems } = this.state;

    this.props.showStats({
      withScore: true,
      data: {
        allProblemsCount: problems.length,
        problemsAnsweredCorrectly: answeredCorrectly,
        problemsAnsweredIncorrectly: answeredIncorrectly,
      },
    });

    this.setState({ currentProblem: null });
  };

  setupNextQuestion = () => {
    const {
      problemsLeft,
      answeredCorrectly,
      attemptsOnCurrentProblem,
      shouldChangeTurn,
    } = this.state;

    const { delayBeforeNext } = this.props.activity.data.options;
    const { multiplayerModeEnabled, changePlayerTurn } = this.props;

    let newAnsweredCorrectly = answeredCorrectly;

    if (!attemptsOnCurrentProblem) {
      newAnsweredCorrectly++;
    }

    let newProblemsLeft = problemsLeft - 1;

    if (!newProblemsLeft) {
      this.setState({
        answeredCorrectly: newAnsweredCorrectly,
        problemsLeft: newProblemsLeft,
      });

      this.timeoutBeforeNext = setTimeout(() => {
        this.finishGame();
      }, delayBeforeNext * 1000);

      return;
    }

    this.timeoutBeforeNext = setTimeout(() => {
      // Get next question
      this.setState((state) => {
        const nextProblem = state.problems[state.currentIndex + 1];
        const items = getItems(nextProblem);

        return {
          items,
          answeredCorrectly: newAnsweredCorrectly,
          attemptsOnCurrentProblem: 0,
          problemsLeft: newProblemsLeft,
          isResolved: false,
          currentProblem: nextProblem,
          currentIndex: state.currentIndex + 1,
        };
      });

      if (multiplayerModeEnabled && shouldChangeTurn) changePlayerTurn();
    }, delayBeforeNext * 1000);
  };

  answerClickHandler = (answerId) => {
    const { items } = this.state;

    const changedItems = items.map((i) => {
      if (i.id !== answerId) return i;

      return { ...i, checked: !i.checked };
    });

    this.setState({ items: changedItems });
  };

  showAnswer = () => {
    const { items } = this.state;

    const correctCheckedItems = items.map((i) => {
      if (i.correct && !i.checked) {
        i.checked = true;
        return i;
      }

      if (!i.correct && i.checked) {
        i.checked = false;
        return i;
      }

      return i;
    });

    this.setState((state) => ({
      items: JSON.parse(JSON.stringify(correctCheckedItems)),
      isResolved: true,
      shouldChangeTurn: false,
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
    }));

    this.props.playWatch();
    document.addEventListener('audioEnded', this.setupNextQuestion, {
      once: true,
    });
  };

  render() {
    const { solutionLocale, translate, getStringAudio } = this.props;
    const {
      items,
      isResolved,
      problems,
      problemsLeft,
      currentProblem,
      // answeredCorrectly,
      // answeredIncorrectly,
      attemptsOnCurrentProblem,
    } = this.state;

    const instruction = getInstruction(
      currentProblem, 
      solutionLocale,
      undefined, undefined, undefined,
      { translate, getStringAudio },
    );

    const { activity, multiplayerModeEnabled } = this.props;
    const { options } = activity.data;

    const textStyle = currentProblem && {
      fontFamily: currentProblem.fontFamily,
      color: currentProblem.fontColor,
    };

    return (
      <div style={textStyle}>
        <Instructions problem={currentProblem} />

        {this.renderBlocks()}

        {problems && (
          <ProblemsProgress
            problemsNumber={problems.length}
            problemsLeft={problemsLeft}
          />
        )}

        {multiplayerModeEnabled && (
          <MultiplayerOverlay /> 
        )}

        <ActivityButtons
          buttons={[
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.CHECK_ANSWER,
              onClick: () => this.checkAnswer(),
              dontShow:
                isResolved || !items || !items.filter((i) => i.checked).length,
            },
            {
              type: buttonsTypes.CORRECT_ANSWER,
              onClick: () => this.showAnswer(),
              dontShow:
                multiplayerModeEnabled ||
                isResolved ||
                options.showAnswer === 'n/a' ||
                +options.showAnswer > attemptsOnCurrentProblem,
            },
            {
              type: buttonsTypes.SPEAK,
              dontShow: !instruction.audio || isResolved,
            },
            buttonsTypes.HELP,
          ]}
        />
      </div>
    );
  }

  renderBlocks = () => {
    const { items, currentProblem } = this.state;

    if (!currentProblem || !items) return null;
    const { questions } = currentProblem;

    const question = questions && validateItem(questions[0]) && questions[0];

    return (
      <>
        <div className={classes.blocksWrapper}>
          {question && <QuestionItem data={question} />}

          <BoardWithAnswers
            answers={items}
            clickHandler={this.answerClickHandler}
          />
        </div>
      </>
    );
  };
}

export default connect(null, {
  playWatch,
  playCorrectSound,
  playIncorrectSound,
  showStats,
  changePlayerTurn,
  incrementPlayerPoints,
})(AllTogetherNow);
