import { axiosAPI } from "utils";

import {
  ADD_ACTIVITY,
  EDIT_ACTIVITY,
  DELETE_ACTIVITY,
  CLEAR_ACTIVITIES_DELETE_LIST,
  REMOVE_FROM_EDIT_LIST,
  UNDO_DELETE_ACTIVITY,
  SET_ACTIVE_ACTIVITY,
  SET_MY_EDIT_ACTIVITIES_LIST,
  SET_ACTIVITIES_UNSAVED_CHANGES
} from "actions/types";
import { withLoading } from "utils";

const filter = "flinkMake/editList";

export const setActivitiesUnsavedChanges = bool => ({
  filter,
  type: SET_ACTIVITIES_UNSAVED_CHANGES,
  payload: bool
});

export const createActivity = data => dispatch =>
  withLoading(dispatch, async () => {
    try {
      console.log(data);

      const res = await axiosAPI.post("/api/flink-make/edit-list", data);

      dispatch({
        filter,
        type: ADD_ACTIVITY,
        payload: res.data
      });

      return res.data;
    } catch (err) {
      console.log(err);
    }
  });

export const editActivity = data => dispatch => {
  dispatch(setActivitiesUnsavedChanges(true));
  dispatch({
    filter,
    type: EDIT_ACTIVITY,
    payload: { ...data, wasChanged: true }
  });
};

export const undoDeleteActivity = () => ({
  filter,
  type: UNDO_DELETE_ACTIVITY
});

export const copyActivityInEditList = (activityId, data) => dispatch =>
  withLoading(dispatch, async () => {
    const result = {};

    return axiosAPI
      .post(`/api/activities/copy-activity/${activityId}`, data)
      .then(res => {
        if (!res.data) throw new Error("Error copy activity");

        result.activity = res.data;

        // Dispatch new activity to store
        dispatch({
          filter,
          type: ADD_ACTIVITY,
          payload: res.data
        });

        return result;
      })
      .catch(err => {
        console.log(err);
        result.error = err;
        return result;
      });
  });

export const copyActivityFiles = (activityId, data) => dispatch =>
  withLoading(dispatch, async () => {
    const result = {};

    return axiosAPI
      .post(`/api/activities/copy-activity-files/${activityId}`, data)
      .then(res => {
        if (!res.data) throw new Error("Error copy activity");

        result.activity = res.data;

        // Dispatch new activity to store
        dispatch({
          filter,
          type: ADD_ACTIVITY,
          payload: res.data
        });

        return result;
      })
      .catch(err => {
        console.log(err);
        result.error = err;
        return result;
      });
  });

export const deleteActivity = activity => ({
  filter,
  type: DELETE_ACTIVITY,
  payload: activity
});

export const deleteActivitiesForReal = activitiesList => dispatch =>
  withLoading(dispatch, async () => {
    return Promise.all(
      activitiesList.map(({ _id: id }) => {
        return axiosAPI.delete(`/api/activities/delete-activity/${id}`);
      })
    )
      .then(() => dispatch(_clearDeleteList()))
      .catch(err => {
        console.log(err);
        dispatch(_clearDeleteList());
      });
  });

export const changeSettingsForReal = activities => dispatch =>
  withLoading(dispatch, () => {
    const promises = activities.map(activity => {
      delete activity.wasChanged;

      return axiosAPI.put("/api/flink-make/edit-list", activity);
    });

    return Promise.all(promises)
      .then(() => {
        dispatch(setActivitiesUnsavedChanges(false));
      })
      .catch(err => console.log(err));
  });

const _clearDeleteList = () => ({
  filter,
  type: CLEAR_ACTIVITIES_DELETE_LIST
});

export const fetchMyEditActivitiesList = () => dispatch =>
  withLoading(dispatch, async () => {
    try {
      const res = await axiosAPI.get("/api/flink-make/edit-list");

      dispatch({
        filter,
        type: SET_MY_EDIT_ACTIVITIES_LIST,
        payload: res.data
      });

      return res.data;
    } catch (err) {
      console.log(err);
      return [];
    }
  });

export const setActiveActivity = payload => ({
  filter,
  payload,
  type: SET_ACTIVE_ACTIVITY
});

export const removeFromEditList = activitiesIds => dispatch =>
  withLoading(dispatch, async () =>
    axiosAPI
      .post("/api/flink-make/edit-list/remove-from-edit-list", {
        activitiesIds
      })
      .then(res => {
        if (!res.data.success) {
          return console.log(res.data);
        }

        dispatch({
          filter,
          type: REMOVE_FROM_EDIT_LIST,
          payload: activitiesIds
        });
      })
      .catch(err => console.log(err))
  );
