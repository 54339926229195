import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import shortId from 'shortid';
import classnames from 'classnames';

import { getScale } from 'utils';
import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import { playWatch } from 'actions/audioActions';
import { animations, getInstruction } from 'activity-templates/utils';
import { stringProblemsMapper } from 'activity-templates/utils';
import { prepareProblem, validateProblem, validateItem } from './findTheOneHelpers';
import {
  ActivityButtons,
  Instructions,
  ProblemsProgress,
  MultiplayerOverlay,
} from 'components/flink-play';
import {
  ItemsDragLayer,
  ItemSlots,
  BoardWithDraggableItems,
} from './draggable-components';
import classes from './FindTheOne.module.scss';

import {
  changePlayerTurn,
  incrementPlayerPoints,
} from 'actions/gameActions';

const animationSpeed = 1000;
let boxesCount = 0;

const getItems = (problem) => {
  if (!problem) return null;
  const {
    questions,
    correctAnswers,
    incorrectAnswers,
    incorrectItemsShown = 2,
  } = problem;

  const validCorrectAnswers = correctAnswers.filter(validateItem);
  const validIncorrectAnswers = incorrectAnswers.filter(validateItem);
  const validQuestions = questions.filter(validateItem);

  const answersTogether = validIncorrectAnswers.length + 1;
  boxesCount = +incorrectItemsShown + 1;
  boxesCount = boxesCount > answersTogether ? answersTogether : boxesCount;

  const startPositions = _.chain(boxesCount).times().shuffle().value();

  const randomQuestionItem = _.shuffle(validQuestions)[0];
  const randomCorrectItem = _.shuffle(validCorrectAnswers)[0];
  const randomIncorrectItems = _.chain(validIncorrectAnswers)
    .shuffle()
    .slice(0, incorrectItemsShown)
    .value();

  const questionItem = {
    id: shortId.generate(),
    item: randomQuestionItem,
    inSlot: true,
    fixed: true,
    slotIdx: 0,
    isCorrect: true,
  };

  const correctItem = {
    id: shortId.generate(),
    item: randomCorrectItem,
    inSlot: false,
    fixed: false,
    boxIdx: _.last(startPositions),
    isCorrect: true,
  };

  const incorrectItems = randomIncorrectItems.map((item, idx) => ({
    id: shortId.generate(),
    item,
    inSlot: false,
    fixed: false,
    boxIdx: startPositions[idx],
    isCorrect: false,
  }));

  const allItems = _.concat(incorrectItems, correctItem, questionItem);
  return allItems;
};

class FindTheOne extends Component {
  /*constructor(props) {
    super(props);

    const { options } = props.activity.data;
    const { translate, getStringAudio } = props;

    const thisVersion = !props.isPlayVersion ? props.activity.activity.currentLanguageVersion 
    : {version: 'immersion', locale: props.solutionLocale.code};
    const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";    
    const gameData = versionType 
    ? props.activity.data.languageVersionData?.[versionType]
    : props.activity.data.gameData;

    // Remove invalid problems and map them
    const mapper = stringProblemsMapper({ translate, getStringAudio });
    let problems = gameData.problems.filter(validateProblem).map(prepareProblem(mapper));

    const { questionsLimit, randomOrder } = options;

    problems = randomOrder ? _.shuffle(problems) : problems;

    // Need to trim?
    if (
      questionsLimit &&
      questionsLimit > 0 &&
      questionsLimit < problems.length
    ) {
      problems = _.take(problems, questionsLimit);
    }

    const maxTextLength = _.chain(problems)
      .map((p) => [...p.questions, ...p.correctAnswers, ...p.incorrectAnswers])
      .flatten()
      .map((i) => i && i.text && i.text.length)
      .max()
      .value();

    this.state = {
      problems,
      maxTextLength,
      currentProblem: null,
    };
  }*/

    constructor(props) {
      super(props);
    
      const { options } = props.activity.data;
      const { translate, getStringAudio } = props;
    
      const thisVersion = !props.isPlayVersion
        ? props.activity.activity.currentLanguageVersion
        : { version: 'immersion', locale: props.solutionLocale.code };
    
      const versionType =
        thisVersion?.version && thisVersion?.locale
          ? `${thisVersion.version}_${thisVersion.locale}`
          : null;
    
      const languageVersionDataExists =
        versionType && props.activity.data.languageVersionData?.[versionType];
    
      const gameData = languageVersionDataExists
        ? props.activity.data.languageVersionData[versionType]
        : props.activity.data.gameData;
    
      if (!gameData) {
        console.error('Game data is missing or undefined.');
        return;
      }
    
      const mapper = stringProblemsMapper({ translate, getStringAudio });
      let problems = gameData.problems.filter(validateProblem).map(prepareProblem(mapper));
    
      const { questionsLimit, randomOrder } = options;
    
      problems = randomOrder ? _.shuffle(problems) : problems;
    
      if (questionsLimit && questionsLimit > 0 && questionsLimit < problems.length) {
        problems = _.take(problems, questionsLimit);
      }
    
      const maxTextLength = _.chain(problems)
        .map((p) => [...p.questions, ...p.correctAnswers, ...p.incorrectAnswers])
        .flatten()
        .map((i) => i && i.text && i.text.length)
        .max()
        .value();
    
      this.assetsFolder = languageVersionDataExists
        ? `${props.activity.activity.contentFolder}/languageVersionData/${versionType}/`
        : `${props.activity.activity.contentFolder}/gamedata/`;
    
      this.state = {
        problems,
        maxTextLength,
        currentProblem: null,
      };
    }
      

  componentDidMount() {
    document.addEventListener('startGame', this.startGame);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutBeforeNext);
    document.removeEventListener('audioEnded', this.setupNextQuestion);
    document.removeEventListener('startGame', this.startGame);
  }

  startGame = () => {
    const { problems } = this.state;
    const currentProblem = problems[0];
    const items = getItems(currentProblem);

    this.refs = {
      boxes: _.times(boxesCount).map((idx) => React.createRef()),
      slots: _.times(2).map((idx) => React.createRef()),
    };

    this.setState({
      currentProblem,
      items,
      animations: _.shuffle(animations),
      currentIndex: 0,
      isResolved: false,
      problemsCount: problems.length,
      attemptsOnCurrentProblem: 0,
      answeredCorrectly: 0,
      answeredIncorrectly: 0,
      problemsLeft: problems.length,
    });
  };

  onDrop = ({ itemId, slotIdx }) => {
    if (slotIdx !== undefined) {
      this.moveToSlot(itemId, slotIdx);
    } else {
      this.moveToBoard(itemId);
    }
  };

  moveToSlot = (itemId, slotIdx) => {
    const { items } = this.state;

    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        return { ...item, slotIdx: slotIdx, inSlot: true };
      }

      if (item.slotIdx === slotIdx) {
        return { ...item, slotIdx: undefined, inSlot: false };
      }

      return item;
    });

    this.setState({ items: updatedItems });

    this.checkAnswer();
  };

  moveToBoard = (itemId) => {
    const { items } = this.state;

    this.setState({
      items: items.map((item) => {
        if (item.id !== itemId) return item;
        return { ...item, inSlot: false, slotIdx: null };
      }),
    });
  };

  checkAnswer = () => {
    const { items, currentProblem, attemptsOnCurrentProblem } = this.state;
    const { 
      changePlayerTurn, 
      incrementPlayerPoints,
      multiplayerModeEnabled,
    } = this.props;

    const itemInSlot = _.find(items, { slotIdx: 1 });
    if (!itemInSlot) return;

    const feedback = { isCorrect: itemInSlot.isCorrect };

    if (currentProblem.specificFeedback) {
      feedback.audio = itemInSlot.item.feedbackAudio;
      feedback.text = itemInSlot.item.feedbackText;
    }

    if (itemInSlot.isCorrect) {
      this.setState({ isResolved: true });
      if (multiplayerModeEnabled) {
        incrementPlayerPoints(attemptsOnCurrentProblem);
      };

      feedback.onClose = () => {
        this.setupNextQuestion(() => {
          if (multiplayerModeEnabled) {
            changePlayerTurn();
          };
        });
      };
    } else {
      if (multiplayerModeEnabled) {
        changePlayerTurn();
      };

      this.setState((state) => ({
        attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
        answeredIncorrectly:
          state.attemptsOnCurrentProblem === 0
            ? state.answeredIncorrectly + 1
            : state.answeredIncorrectly,
      })); 
    }
    this.props.showFeedback(feedback);
  };

  finishGame = () => {
    const { answeredCorrectly, answeredIncorrectly, problems } = this.state;

    this.props.showStats({
      withScore: true,
      data: {
        allProblemsCount: problems.length,
        problemsAnsweredCorrectly: answeredCorrectly,
        problemsAnsweredIncorrectly: answeredIncorrectly,
      },
    });

    this.setState({ currentProblem: null });
  };

  setupNextQuestion = (callback) => {
    const {
      problemsLeft,
      answeredCorrectly,
      attemptsOnCurrentProblem,
    } = this.state;

    const { delayBeforeNext } = this.props.activity.data.options;

    let newAnsweredCorrectly = answeredCorrectly;

    if (!attemptsOnCurrentProblem) {
      newAnsweredCorrectly++;
    }

    let newProblemsLeft = problemsLeft - 1;

    if (!newProblemsLeft) {
      this.setState({
        answeredCorrectly: newAnsweredCorrectly,
        problemsLeft: newProblemsLeft,
      });

      this.timeoutBeforeNext = setTimeout(() => {
        this.finishGame();
      }, delayBeforeNext * 1000);

      return;
    } else {

      this.timeoutBeforeNext = setTimeout(() => {
        // Get next question
        this.setState((state) => {
          const nextProblem = state.problems[state.currentIndex + 1];
          const items = getItems(nextProblem);

          this.refs.slots = _.times(2).map((idx) => React.createRef());

          return {
            items,
            answeredCorrectly: newAnsweredCorrectly,
            attemptsOnCurrentProblem: 0,
            problemsLeft: newProblemsLeft,
            isResolved: false,
            currentProblem: nextProblem,
            currentIndex: state.currentIndex + 1,
          };
        });

        if (callback) callback();
      }, delayBeforeNext * 1000);
    };
  };

  showAnswer = () => {
    const { items } = this.state;

    const changedItems = [...items];

    const correctItem = _.find(items, { isCorrect: true, inSlot: false });

    correctItem.moveFrom = this.refs.boxes[correctItem.boxIdx].current;
    correctItem.inSlot = true;
    correctItem.slotIdx = 1;

    changedItems.forEach((i) => {
      if (!i.isCorrect && i.inSlot) {
        i.moveFrom = this.refs.slots[i.slotIdx].current;
        i.inSlot = false;
        i.slotIdx = null;
      }
    });

    this.props.playWatch();

    this.setState((state) => ({
      items: [...changedItems],
      isResolved: true,
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
    }));

    document.addEventListener('audioEnded', this.setupNextQuestion, {
      once: true,
    });
  };

  render() {
    const { solutionLocale, translate, getStringAudio } = this.props;
    const {
      problems,
      isResolved,
      problemsLeft,
      maxTextLength,
      currentProblem,
      attemptsOnCurrentProblem,
    } = this.state;

    const instruction = getInstruction(
      currentProblem, 
      solutionLocale,
      undefined, undefined, undefined,
      { translate, getStringAudio },
    );

    const { activity, multiplayerModeEnabled } = this.props;
    const { options } = activity.data;

    const textStyle = currentProblem
      ? {
          fontFamily: currentProblem.fontFamily,
          color: currentProblem.fontColor,
        }
      : {};

    return (
      <>
        <div
          style={textStyle}
          className={classnames(classes.text, {
            [classes.textUpTo1]: maxTextLength === 1,
            [classes.textUpTo2]: maxTextLength === 2,
            [classes.textUpTo4]: maxTextLength > 2 && maxTextLength <= 4,
            [classes.textUpTo6]: maxTextLength > 4 && maxTextLength <= 6,
            [classes.textUpTo10]: maxTextLength > 6 && maxTextLength <= 10,
          })}
        >
          <Instructions problem={currentProblem} />

          {this.renderBlocks()}

          {problems && (
            <ProblemsProgress
              problemsNumber={problems.length}
              problemsLeft={problemsLeft}
            />
          )}
        </div>

        {multiplayerModeEnabled && (
          <MultiplayerOverlay /> 
        )}

        <ActivityButtons
          buttons={[
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.CORRECT_ANSWER,
              onClick: () => this.showAnswer(),
              dontShow:
                multiplayerModeEnabled ||
                isResolved ||
                options.showAnswer === 'n/a' ||
                +options.showAnswer > attemptsOnCurrentProblem,
            },
            {
              type: buttonsTypes.SPEAK,
              dontShow: !instruction.audio || isResolved,
            },
            buttonsTypes.HELP,
          ]}
        />
      </>
    );
  }

  renderBlocks = () => {
    const {
      items,
      isResolved,
      animations,
      currentIndex,
      currentProblem,
    } = this.state;

    if (!currentProblem || !items) return null;
    const {
      activity: {
        data: { options },
      },
    } = this.props;

    return (
      <>
        <ItemsDragLayer
          style={{
            transform: 'scale(' + getScale() + ')',
          }}
        />

        <div
          className={classes.boxesWrapper}
          style={{
            width: 900,
            transform: 'translate(-50%, -50%) scale(' + getScale() + ')',
          }}
        >
          <BoardWithDraggableItems
            isResolved={isResolved}
            boxesCount={boxesCount}
            onDrop={this.onDrop}
            items={items}
            refs={this.refs.boxes}
          />

          <ItemSlots
            slotClassname={classes.slot}
            fixedSlotClassname={classes.fixedSlot}
            options={options}
            animationSpeed={animationSpeed}
            isResolved={isResolved}
            animation={animations[currentIndex % animations.length]}
            count={2}
            onDrop={this.onDrop}
            items={items}
            refs={this.refs.slots}
          />
        </div>
      </>
    );
  };
}

export default connect(null, { 
  playWatch, 
  showStats,
  changePlayerTurn,
  incrementPlayerPoints,
})(FindTheOne);
