export const MASTER_ADMIN = 'MASTER_ADMIN';
export const REP = 'REP';
export const AUTHOR = 'AUTHOR';
export const LEARNING_CENTER_ADMIN = 'LEARNING_CENTER_ADMIN';
export const MASTER_ORGANIZATION_ADMIN = 'MASTER_ORGANIZATION_ADMIN';
export const REGION_ADMIN = 'REGION_ADMIN';
export const TEACHER = 'TEACHER';
export const TRANSLATOR = 'TRANSLATOR';
export const TRANSLATOR_AUTHORED = 'TRANSLATOR_AUTHORED';
export const TRANSLATOR_GENERATED = 'TRANSLATOR_GENERATED';
export const SUPER = 'SUPER';
export const CONSUMER_REP = 'CONSUMER_REP';
export const REGULAR = 'REGULAR';
export const CLEVER_ADMIN = 'CLEVER_ADMIN';

export const allRoles = [
  MASTER_ADMIN,
  TRANSLATOR,
  TRANSLATOR_AUTHORED,
  TRANSLATOR_GENERATED,
  REP,
  AUTHOR,
  LEARNING_CENTER_ADMIN,
  MASTER_ORGANIZATION_ADMIN,
  REGION_ADMIN,
  TEACHER,
  SUPER,
  CONSUMER_REP,
  REGULAR,
  CLEVER_ADMIN,
];
