const pageTitles = {
  "/": "Home",
  "/login": "Login",
  "/admin": "Admin",
  "/flink-admin": "Report",
  "/flink-admin/report-groups": "Report Groups",
  "/flink-admin/reports": "Reports",
  "/play": "Play",
  "/:learningCenterUrl": "Login",

  "/flink-make": "Make",
  "/flink-make/edit-activities": "Make: Edit Activities",
  "/flink-make/edit-activities/:activityId/content": "Edit Activity: Content",
  "/flink-make/edit-activities/:activityId/help": "Edit Activity: Help",
  "/flink-make/edit-activities/:activityId/options": "Edit Activity: Options",
  "/flink-make/edit-activities/:activityId/preview": "Edit Activity: Preview",
  "/flink-make/search-activities": "Make: Search Activities",
  "/flink-make/activity-groups": "Make: Activity Groups",
  "/flink-make/wordlists": "Make: Wordlists",
  "/flink-make/search-wordlists": "Make: Search Wordlists",

  "/admin/my-account": "My Account",
  "/admin/reports/usage-and-master": "Usage and Mastery Report",
  "/admin/reports/family": "Family Report",
  "/admin/reports/cohort": "Cohort Reports",
  "/admin/reports/continuity": "Continuity Reports",
  "/admin/reports/revenue": "Revenue Reports",
  "/admin/reports/translator-work": "Translator Work Reports",
  "/admin/reports/financial": "Financial Reports",
  "/admin/reports/content-partners": "Content Partners Reports",
  "/admin/reports/data-export": "Data Export Reports",
  "/admin/reports/data-export/create": "Create Data Export Report",
  "/admin/reports/data-export/:id": "Edit Data Export Report",
  "/admin/general": "General",
  "/admin/view-documents": "View Documents",
  "/admin/solutions/solution-menu/:id": "Solution Menu"
};

const adminCategories = [
  "activity-templates",
  "characters",
  "master-organizations",
  "landing-pages",
  //"old-landing-pages",
  "regions",
  "learning-centers",
  "documents",
  "global-buttons",
  "lists",
  "locales",
  "menus",
  "products",
  "roles",
  "scopes",
  "solutions",
  "strings",
  "themes",
  "users",
  "words",
  "gift-cards"
];

adminCategories.forEach(page => {
  const formattedPageTitle = page
    .split("-")
    .map(part => part[0].toUpperCase() + part.substring(1))
    .join(" ");

  pageTitles[`/admin/${page}`] = formattedPageTitle;
  pageTitles[`/admin/${page}/create`] =
    "Create " + formattedPageTitle.substring(0, page.length - 1);
  pageTitles[`/admin/${page}/edit/:id`] =
    "Edit " + formattedPageTitle.substring(0, page.length - 1);
});

export default match => {
  let title = pageTitles[match.path];

  return title || "Flink";
};
