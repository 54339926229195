import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { imagesURL } from 'config';
import { Editor } from 'components/flink-components';
import { Rnd } from 'react-rnd';
import { showPromptPromise } from 'actions/statusActions';
import { connect } from 'react-redux';
import classes from './EbookEditor.module.scss';

export class EbookArea extends Component {
  state = {};

  onEditorChange = (html) => {
    const { changeHandler, data } = this.props;
    changeHandler({ ...data, text: html });
  };

  onEditorChangeRaw = (text) => {
    const { changeHandler, data } = this.props;
    changeHandler({ ...data, raw: text });
  };

  updateSize = (e, direction, ref, delta, position) => {
    const { changeHandler, data } = this.props;
    changeHandler({
      ...data,
      dim: { ...data.dim, width: ref.offsetWidth, height: ref.offsetHeight },
    });
  };

  updatePosition = (e, d) => {
    const { changeHandler, data } = this.props;
    changeHandler({ ...data, dim: { ...data.dim, left: d.x, top: d.y } });
  };

  updateIgnoreTTS = (event) => {
    const { changeHandler, data } = this.props;
    const checked = event.target.checked;

    console.debug('Updating ignores TTS', checked);
    changeHandler({ ...data, ignoreTTS: checked });
  };

  updateOrder = async () => {
    const { 
      data,
      index, maxIndex, 
      showPromptPromise, swapOrderHandler 
    } = this.props;

    const desiredIndex = await showPromptPromise({
      inputProps: { min: 1, max: maxIndex + 1, type: 'number', step: 1 },
      initialVal: '1',
      html: true,
      message: `<p>Enter new desired text order for TTS (current: ${index + 1})</p>`,
      controls: (
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              defaultChecked={data.ignoreTTS}
              onChange={this.updateIgnoreTTS}
            />
          }
          label="TTS should not read this text block"
        />
      )
    });

    if (desiredIndex === null || desiredIndex === false)
      return;

    swapOrderHandler(index, desiredIndex - 1);
  }

  render() {
    const {
      disableEditing = false,
      translatorReadOnly,
      data,
      removeHandler,
      boundsSelector,
      selectHandler,
      inFocus,
      withImage,
      disableDelete,
      disableDrag,
      disableResize,
      disableOrdering,
      index,
    } = this.props;

    const { dim, text, ignoreTTS } = data;
    const position = { x: parseInt(dim.left), y: parseInt(dim.top) };

    return (
      <Rnd
        className={classnames({
          [classes.inFocus]: inFocus,
        })}
        disableDragging={disableDrag}
        size={dim}
        onResize={this.updateSize}
        position={position}
        onDragStop={this.updatePosition}
        bounds={boundsSelector}
        enableResizing={
          !disableResize && {
            bottom: true,
            bottomRight: true,
            bottomLeft: false,
            top: false,
            right: true,
            topRight: false,
            topLeft: false,
          }
        }
        dragHandleClassName={classes.moveButton}
      >
        <div
          className={classnames(classes.wrapper, {
            [classes.disabled]: disableEditing,
          })}
          onFocus={selectHandler}
          tabIndex="1"
        >
          {!disableResize && (
            <button type="button" className={classes.moveButton}>
              <img
                src={`${imagesURL}/FlinkMake/MoveEditor.png`}
                alt="Move"
                draggable="false"
              />
            </button>
          )}

          {!disableDelete && (
            <button onClick={removeHandler} className={classes.removeButton}>
              <img src={`${imagesURL}/FlinkMake/delete.png`} alt="Delete" />
            </button>
          )}

          {!disableOrdering && (
            <button onClick={this.updateOrder} type="button" className={classnames(classes.orderButton, {
              [classes.ignoreTTS]: ignoreTTS,
            })}>
              {index+1}
            </button>
          )}

          <Editor
            readOnly={disableEditing}
            translatorReadOnly={translatorReadOnly}
            classes={{
              toolbar: classnames(classes.editorToolbar, {
                [classes.visibleToolbar]: inFocus,
                [classes.hidden]: disableEditing,
              }),
            }}
            className={classes.editor}
            initialValue={text || ''}
            changeHandler={this.onEditorChange}
            changeHandlerRaw={this.onEditorChangeRaw}
            withImage={withImage}
            withVideo={false}
            withLink={false}
          />
        </div>
      </Rnd>
    );
  }
}

EbookArea.defaultProps = {
  disableEditing: false,
  disableDelete: false,
  disableDrag: false,
  disableResize: false,
  withImage: false,
};

EbookArea.propTypes = {
  data: PropTypes.object.isRequired,
  boundsSelector: PropTypes.string.isRequired,
  removeHandler: PropTypes.func.isRequired,
  selectHandler: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  disableEditing: PropTypes.bool,
  disableDelete: PropTypes.bool,
  disableDrag: PropTypes.bool,
  disableResize: PropTypes.bool,
  withImage: PropTypes.bool,
  translatorReadOnly: PropTypes.bool,
};

export default connect(null, {
  showPromptPromise,
})(EbookArea);
