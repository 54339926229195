import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import shortId from 'shortid';

import { showStats } from 'actions/gameActions';
import * as buttonsTypes from 'consts/buttons';
import {
  toggleAudio,
  playCorrectSound,
  playIncorrectSound,
  playWatch,
} from 'actions/audioActions';
import { animations } from 'activity-templates/utils';
import { s3bucketPublicURL } from 'config';
import { validateProblem } from './wordConnectablesHelpers';
import {
  ActivityButtons,
  ProblemsProgress,
  ItemsDragLayer,
  ItemSlots,
  ProblemImage,
  BoardWithDraggableItems,
  ConnectablesProblemDefinition,
  MultiplayerOverlay,
} from 'components/flink-play';

import {
  changePlayerTurn,
  incrementPlayerPoints,
} from 'actions/gameActions';

import classes from './WordConnectables.module.scss';

const boxesCount = 10;
const animationSpeed = 1000;

const getSyllables = (problem) => {
  if (!problem) return null;
  const { syllables, mask, options } = problem;

  const startPositions = _.chain(boxesCount).times().shuffle().value();

  const formattedSyllables = _.chain(syllables)
    .map((text, idx) => {
      const isFixed = mask.charAt(idx) === '1';

      return {
        text,
        inSlot: isFixed,
        fixed: isFixed,
        slotIdx: isFixed ? idx : null,
      };
    })
    .concat(options.map((text) => ({ text, inSlot: false })))
    .map((syllable, idx) => ({
      ...syllable,
      id: shortId.generate(),
      boxIdx: startPositions[idx],
    }))
    .value();

  return formattedSyllables;
};

class WordConnectables extends Component {
  /*constructor(props) {
    super(props);
    const thisVersion = !props.isPlayVersion ? props.activity.activity.currentLanguageVersion 
    : {version: 'immersion', locale: props.solutionLocale.code};
    const versionType = thisVersion && thisVersion.version && thisVersion.locale ? `${thisVersion.version}_${thisVersion.locale}` : "";    
    const { options } = props.activity.data;
    const gameData = versionType 
    ? props.activity.data.languageVersionData?.[versionType]
    : props.activity.data.gameData;

    // Remove invalid problems
    let problems = gameData.problems.filter(validateProblem);

    const { questionsLimit, audioBefore, randomOrder } = options;

    problems = randomOrder ? _.shuffle(problems) : problems;

    // Need to trim?
    if (
      questionsLimit &&
      questionsLimit > 0 &&
      questionsLimit < problems.length
    ) {
      problems = _.take(problems, questionsLimit);
    }

    this.state = {
      audioBefore,
      problems,
      currentProblem: null,
      versionType
    };
  }*/

    constructor(props) {
      super(props);
      const thisVersion = !props.isPlayVersion
          ? props.activity.activity.currentLanguageVersion
          : { version: 'immersion', locale: props.solutionLocale.code };
      const versionType =
          thisVersion && thisVersion.version && thisVersion.locale
              ? `${thisVersion.version}_${thisVersion.locale}`
              : "";
      const { options } = props.activity.data;
  
      // Проверяем наличие languageVersionData
      const languageVersionDataExists =
          versionType &&
          props.activity.data.languageVersionData &&
          props.activity.data.languageVersionData[versionType];
  
      const gameData = languageVersionDataExists
          ? props.activity.data.languageVersionData[versionType]
          : props.activity.data.gameData;
  
      // Remove invalid problems
      let problems = gameData.problems.filter(validateProblem);
  
      const { questionsLimit, audioBefore, randomOrder } = options;
  
      problems = randomOrder ? _.shuffle(problems) : problems;
  
      // Need to trim?
      if (questionsLimit && questionsLimit > 0 && questionsLimit < problems.length) {
          problems = _.take(problems, questionsLimit);
      }
  
      this.state = {
          audioBefore,
          problems,
          currentProblem: null,
          versionType,
          languageVersionDataExists, // Сохраняем флаг в state
      };
  }

  componentDidMount() {
    document.addEventListener('startGame', this.startGame);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutBeforeNext);
    document.removeEventListener('audioEnded', this.setupNextQuestion);
    document.removeEventListener('audioEnded', this.setupNextQuestionWithAudio);
    document.removeEventListener('startGame', this.startGame);
  }

  startGame = () => {
    const { problems, audioBefore } = this.state;
    const currentProblem = problems[0];
    const syllables = getSyllables(currentProblem);

    this.refs = {
      boxes: _.times(boxesCount).map((idx) => React.createRef()),
      slots: _.times(currentProblem.syllables.length).map((idx) =>
        React.createRef()
      ),
    };

    this.setState({
      currentProblem,
      syllables,
      animations: _.shuffle(animations),
      currentIndex: 0,
      isResolved: false,
      problemsCount: problems.length,
      attemptsOnCurrentProblem: 0,
      answeredCorrectly: 0,
      answeredIncorrectly: 0,
      problemsLeft: problems.length,
    });

    audioBefore && this.playProblemAudio(currentProblem);
  };

  onDrop = ({ itemId, slotIdx }) => {
    if (slotIdx !== undefined) {
      this.moveToSlot(itemId, slotIdx);
    } else {
      this.moveToBoard(itemId);
    }
  };

  moveToSlot = (itemId, slotIdx) => {
    const { syllables } = this.state;

    const updatedSyllables = syllables.map((syllable) => {
      if (syllable.id === itemId) {
        return { ...syllable, slotIdx: slotIdx, inSlot: true };
      }

      if (syllable.slotIdx === slotIdx) {
        return { ...syllable, slotIdx: undefined, inSlot: false };
      }

      return syllable;
    });

    this.setState({ syllables: updatedSyllables });

    this.checkAnswer();
  };

  moveToBoard = (itemId) => {
    const { syllables } = this.state;

    this.setState({
      syllables: syllables.map((syllable) => {
        if (syllable.id !== itemId) return syllable;
        return { ...syllable, inSlot: false, slotIdx: null };
      }),
    });
  };

  checkAnswer = () => {
    const { syllables: formattedSyllables, currentProblem, attemptsOnCurrentProblem } = this.state;
    const { syllables: originSyllables } = currentProblem;

    const { 
      changePlayerTurn, 
      incrementPlayerPoints,
      multiplayerModeEnabled,
    } = this.props;

    const syllablesInSlot = _.chain(formattedSyllables)
      .filter({ inSlot: true })
      .sortBy((s) => s.slotIdx)
      .value();

    if (syllablesInSlot.length !== originSyllables.length) return;

    const wordInSlots = syllablesInSlot.map((s) => s.text).join('');

    if (wordInSlots === originSyllables.join('')) {
      this.setState({ isResolved: true, shouldChangeTurn: true });
      this.props.playCorrectSound();
      if (multiplayerModeEnabled) {
        incrementPlayerPoints(attemptsOnCurrentProblem);
      };

      document.addEventListener('audioEnded', this.setupNextQuestionWithAudio, {
        once: true,
      });
      return;
    }

    this.props.playIncorrectSound();
    if (multiplayerModeEnabled) {
      changePlayerTurn();
    };

    this.setState((state) => ({
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
      answeredIncorrectly:
        state.attemptsOnCurrentProblem === 0
          ? state.answeredIncorrectly + 1
          : state.answeredIncorrectly,
    }));
  };

  setupNextQuestionWithAudio = () => {
    const { currentProblem } = this.state;

    if (currentProblem.audio) {
      this.playProblemAudio();

      document.addEventListener('audioEnded', this.setupNextQuestion, {
        once: true,
      });
    } else {
      this.setupNextQuestion();
    }
  };

  /*playProblemAudio = (problem) => {
    let problemToPlay = problem || this.state.currentProblem;
    const {versionType} = this.state;
    if (!problemToPlay || !problemToPlay.audio) return;

    const {
      activity: {
        activity: { contentFolder },
      },
    } = this.props;

    const audioUrl = versionType 
    ? `${s3bucketPublicURL}/${contentFolder}/languageVersionData/${versionType}/${problemToPlay.audio}` 
    : `${s3bucketPublicURL}/${contentFolder}/gamedata/${problemToPlay.audio}`;
    toggleAudio(audioUrl);
  };*/

  playProblemAudio = (problem) => {
    let problemToPlay = problem || this.state.currentProblem;
    const { versionType, languageVersionDataExists } = this.state;

    if (!problemToPlay || !problemToPlay.audio) return;

    const {
        activity: {
            activity: { contentFolder },
        },
    } = this.props;

    const audioUrl = languageVersionDataExists
        ? `${s3bucketPublicURL}/${contentFolder}/languageVersionData/${versionType}/${problemToPlay.audio}`
        : `${s3bucketPublicURL}/${contentFolder}/gamedata/${problemToPlay.audio}`;

    toggleAudio(audioUrl);
};


  finishGame = () => {
    const { answeredCorrectly, answeredIncorrectly, problems } = this.state;

    this.props.showStats({
      withScore: true,
      data: {
        allProblemsCount: problems.length,
        problemsAnsweredCorrectly: answeredCorrectly,
        problemsAnsweredIncorrectly: answeredIncorrectly,
      },
    });

    this.setState({ currentProblem: null });
  };

  setupNextQuestion = () => {
    const {
      problemsLeft,
      answeredCorrectly,
      attemptsOnCurrentProblem,
      shouldChangeTurn,
    } = this.state;

    const { delayBeforeNext } = this.props.activity.data.options;
    const { multiplayerModeEnabled, changePlayerTurn } = this.props;

    let newAnsweredCorrectly = answeredCorrectly;

    if (!attemptsOnCurrentProblem) {
      newAnsweredCorrectly++;
    }

    let newProblemsLeft = problemsLeft - 1;

    if (!newProblemsLeft) {
      this.setState({
        answeredCorrectly: newAnsweredCorrectly,
        problemsLeft: newProblemsLeft,
      });

      this.timeoutBeforeNext = setTimeout(() => {
        this.finishGame();
      }, delayBeforeNext * 1000);

      return;
    }

    this.timeoutBeforeNext = setTimeout(() => {
      // Get next question
      this.setState((state) => {
        const nextProblem = state.problems[state.currentIndex + 1];
        const syllables = getSyllables(nextProblem);

        this.refs.slots = _.times(nextProblem.syllables.length).map((idx) =>
          React.createRef()
        );

        this.playProblemAudio(nextProblem);

        return {
          syllables,
          answeredCorrectly: newAnsweredCorrectly,
          attemptsOnCurrentProblem: 0,
          problemsLeft: newProblemsLeft,
          isResolved: false,
          currentProblem: nextProblem,
          currentIndex: state.currentIndex + 1,
        };
      });

      if (multiplayerModeEnabled && shouldChangeTurn) changePlayerTurn();
    }, delayBeforeNext * 1000);
  };

  showAnswer = () => {
    const {
      syllables,
      currentProblem: { syllables: originSyllables },
    } = this.state;

    let changedSyllables = _.map(syllables, (s) => ({
      ...s,
      isCorrect: s.inSlot && s.text === originSyllables[s.slotIdx],
    }));

    _.chain(originSyllables.length)
      .times()
      .forEach((idx) => {
        const syllableInThatSlot = _.find(changedSyllables, { slotIdx: idx });

        if (syllableInThatSlot && syllableInThatSlot.isCorrect) return;

        const shouldBeSyllable = originSyllables[idx];

        const textForThatSlot = _.find(changedSyllables, {
          text: shouldBeSyllable,
          isCorrect: false,
        });

        textForThatSlot.moveFrom = textForThatSlot.inSlot
          ? this.refs.slots[textForThatSlot.slotIdx].current
          : this.refs.boxes[textForThatSlot.boxIdx].current;
        textForThatSlot.inSlot = true;
        textForThatSlot.slotIdx = idx;
        textForThatSlot.isCorrect = true;
      })
      .value();

    changedSyllables.forEach((s) => {
      if (!s.isCorrect && s.inSlot) {
        s.moveFrom = this.refs.slots[s.slotIdx].current;
        s.inSlot = false;
        s.slotIdx = null;
      }
    });

    this.props.playWatch();

    this.setState((state) => ({
      syllables: [...changedSyllables],
      isResolved: true,
      shouldChangeTurn: false,
      attemptsOnCurrentProblem: state.attemptsOnCurrentProblem + 1,
    }));

    document.addEventListener('audioEnded', this.setupNextQuestionWithAudio, {
      once: true,
    });
  };

  render() {
    const {
      isResolved,
      problems,
      problemsLeft,
      currentProblem,
      attemptsOnCurrentProblem,
    } = this.state;

    const { textStyle, activity, multiplayerModeEnabled } = this.props;
    const { options } = activity.data;

    return (
      <div style={textStyle}>
        {this.renderBlocks()}

        {problems && (
          <ProblemsProgress
            problemsNumber={problems.length}
            problemsLeft={problemsLeft}
          />
        )}

        {multiplayerModeEnabled && (
          <MultiplayerOverlay /> 
        )}

        <ActivityButtons
          buttons={[
            buttonsTypes.GO_BACK,
            {
              type: buttonsTypes.CORRECT_ANSWER,
              onClick: () => this.showAnswer(),
              dontShow:
                multiplayerModeEnabled ||
                isResolved ||
                options.showAnswer === 'n/a' ||
                +options.showAnswer > attemptsOnCurrentProblem,
            },
            {
              type: buttonsTypes.SPEAK,
              onClick: () => this.playProblemAudio(),
              dontShow: !currentProblem || !currentProblem.audio || isResolved,
            },
            buttonsTypes.HELP,
          ]}
        />
      </div>
    );
  }

  renderBlocks = () => {
    const {
      syllables,
      isResolved,
      animations,
      currentIndex,
      currentProblem,
      versionType,
      languageVersionDataExists,
    } = this.state;

    if (!currentProblem || !syllables) return null;

    const {
      textStyle,
      activity: {
        activity: { contentFolder },
        data: { options },
      },
    } = this.props;

    /*const imageUrl = versionType 
    ? (currentProblem.image &&
          `${s3bucketPublicURL}/${contentFolder}/languageVersionData/${versionType}/${currentProblem.image}`)
    : (currentProblem.image &&
          `${s3bucketPublicURL}/${contentFolder}/gamedata/${currentProblem.image}`);*/

    const imageUrl = languageVersionDataExists
          ? currentProblem.image &&
            `${s3bucketPublicURL}/${contentFolder}/languageVersionData/${versionType}/${currentProblem.image}`
          : currentProblem.image &&
            `${s3bucketPublicURL}/${contentFolder}/gamedata/${currentProblem.image}`;
    


    return (
      <Fragment>
        <ItemsDragLayer />
        <ItemSlots
          slotClassname={classes.slot}
          options={options}
          animationSpeed={animationSpeed}
          isResolved={isResolved}
          animation={animations[currentIndex % animations.length]}
          count={currentProblem.syllables.length}
          onDrop={this.onDrop}
          items={syllables}
          refs={this.refs.slots}
          joinOnResolve
        />
        <ProblemImage url={imageUrl} />
        <BoardWithDraggableItems
          isResolved={isResolved}
          boxesCount={boxesCount}
          onDrop={this.onDrop}
          items={syllables}
          textStyle={textStyle}
          refs={this.refs.boxes}
        />
        <ConnectablesProblemDefinition text={currentProblem.definition} />
      </Fragment>
    );
  };
}

export default connect(null, {
  playWatch,
  playCorrectSound,
  playIncorrectSound,
  showStats,
  changePlayerTurn,
  incrementPlayerPoints,
})(WordConnectables);
