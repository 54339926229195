import _ from 'lodash';

const deleteInheritedFields = (obj, inheritedFields) => {
  _.forEach(inheritedFields, (val, key) => {
    if (_.isEqual(obj[key], val)) {
      delete obj[key];
    }
  });
};

export default deleteInheritedFields;
