import { isMatch, isObject } from 'lodash';

const checkPermissions = (auth, permissions) => {
  // If permissions not passed then everybody can access
  if (!permissions) {
    return true;
  }

  // If permissions is string then check if auth has that property and it's not empty
  if (typeof permissions === 'string' && auth[permissions]) {
    return true;
  }

  if (Array.isArray(permissions)) {
    const roleAlias = auth.userRole && auth.userRole.alias;

    return permissions.includes(roleAlias);
  }

  if (isObject(permissions)) {
    if (auth.userRole && auth.userRole.alias === 'SUPER') {
      // don't access super to flink reports
      if (permissions.apps && permissions.apps.flinkAdmin) {
        return false;
      }
      
      if (permissions?.flinkMake?.tabs?.searchAgs) return false;
      
      return true;
    }

    return isMatch(auth.userRole && auth.userRole.permissions, permissions);
  }

  return false;
};

export default checkPermissions;
