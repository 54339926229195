import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { DraggableModal } from 'components/flink-components';
import RadioGroup from '../ActivityOptions/RadioGroup';

import { imagesURL, filesURL, uploadsURL } from 'config';
import { playAudio, stopAudio } from 'actions/audioActions';
import {
  uploadAudioFile,
  generateTTS,
  setTTSVoice,
  setTTSLocale,
  saveEditingActivity
} from 'actions/flinkMakeActions';
import API from 'api';
import classes from './AddAudio.module.scss';
import Record from './_Record';

class AddAudio extends Component {
  state = {
    currentUrl: '',
    show: false,
    file: null,
  };

  componentDidUpdate(prevProps) {
    const { currentUrl } = this.state;
    const { current: prevCurrent, playingSrc, isPlaying } = prevProps;
    const { current } = this.props;

    if (current !== prevCurrent && currentUrl === playingSrc && isPlaying) {
      this.stopHandler();
    }
  }

  componentWillUnmount() {
    stopAudio();
  }

  onDrop = (files) => {
    this.setState({ file: files[0] }, this.uploadHandler);
    console.log('uploadHandler', files[0]);
  };

  onGenerateTTS = async () => {
    const {
      isUrl,
      path,
      removeOldOnChange,
      current,
      tts,
      locales,
      useFileBucket,
      generateTTS,
      associatedText: text,
    } = this.props;

    if (!locales.length)
      return console.warn('Tried to generate TTS with no available locales');

    const { voice, locale } = tts;
    const filename = await generateTTS({
      path,
      voice,
      text,
      useFileBucket,
      locale: locale ?? locales[0].code,
      fileToDelete: !isUrl && removeOldOnChange ? current : null,
    });

    if (!filename)
      return console.warn('No file returned from generate TTS function');

    this.changeHandler(filename);
    this.setState({ show: false, file: null });
  }

  playHandler = () => {
    const { current, path, isUrl, useFileBucket } = this.props;
    const audioUrl = isUrl ? current : `${useFileBucket ? filesURL : uploadsURL}/${path}/${current}`;
    playAudio(audioUrl);
    this.setState({ currentUrl: audioUrl });
  };

  stopHandler = () => {
    stopAudio();
  };

  removeHandler = () => {
    this.stopHandler();
    const { path, current, isUrl } = this.props;

    if (!isUrl) {
      const key = `${path}/${current}`;
      API.storage.deleteFiles([key], false);
    }

    this.changeHandler();
  };

  uploadHandler = async () => {
    const {
      path,
      isUrl,
      withId,
      current,
      uploadAudioFile,
      filename: filenameFromProps,
      removeOldOnChange,
      useFileBucket,
    } = this.props;

    const { file } = this.state;

    const [, originFileName, extension] = file.name.match(/\/?([^/]+)\.(\S+)$/);

    const withoutExt = filenameFromProps ? filenameFromProps : originFileName;
    const id = withId ? '_' + shortid.generate() : '';

    const filename = `${withoutExt}${id}.${extension}`;

    const params = {
      path,
      file: this.state.file,
      filename,
      fileToDelete: !isUrl && removeOldOnChange ? current : null,
      useUploadBucket: !useFileBucket,
    };

    const success = await uploadAudioFile(params);

    if (!success) return;

    this.changeHandler(filename);
    this.setState({ show: false, file: null });
  };

  changeHandler = (value) => {
    const {
      onChangeHandler,
      saveAfterChange,
      saveEditingActivity,
    } = this.props;

    onChangeHandler(value);
    saveAfterChange && saveEditingActivity();
  };

  useRecorderFile = async (file) => {
    const {
      path,
      isUrl,
      current,
      filename: filenameFromProps,
      uploadAudioFile,
      removeOldOnChange,
      useFileBucket,
    } = this.props;

    const filename = filenameFromProps || file.name;

    const params = {
      path,
      file,
      filename,
      fileToDelete: !isUrl && removeOldOnChange ? current : null,
      useUploadBucket: !useFileBucket,
    };

    const success = await uploadAudioFile(params);

    if (!success) return;

    this.changeHandler(filename);
    this.setState({ show: false, file: null });
  };

  fromDirectoryHandler = () => {
    console.log('Choose from Directory');
  };

  toggleShow = () => {
    stopAudio();
    this.setState({ show: !this.state.show });
  };

  render() {
    const { show, /*file*/ currentUrl } = this.state;
    const {
      current,
      translate,
      withOverlay,
      readOnly,
      isUrl,
      isPlaying,
      playingSrc,
      tts,
      locales,
      allLocales,
      setTTSVoice,
      setTTSLocale,
      wrapperClassname = '',
      associatedText = '',
      title: propsTitle,
      versionType
    } = this.props;

    const isCurrentPlaying = currentUrl === playingSrc && isPlaying;
    const title =
      propsTitle !== undefined ? propsTitle : 
      readOnly ? translate(1330, 'Play Audio') :
      translate(13, 'Add Audio');
    return (
      <>
        <div className={wrapperClassname}>
          <span className={classes.label}>{title}</span>
          <div className={classes['buttons-wrapper']}>
            {!readOnly && (
              <button
                type='button'
                title={title}
                className={`${classes['btn']} btn-effect`}
                onClick={this.toggleShow}
              >
                <img
                  src={`${imagesURL}/FrontendUtilities/addaudio.png`}
                  alt={title}
                />
              </button>
            )}

            {(current || isUrl) && (
              <>
                {isCurrentPlaying ? (
                  <button
                    type='button'
                    title={translate(73, 'Stop Audio')}
                    className={`${classes['btn']} btn-effect`}
                    onClick={this.stopHandler}
                  >
                    <img
                      src={`${imagesURL}/FrontendUtilities/stop1.png`}
                      alt={translate(73, 'Stop Audio')}
                    />
                  </button>
                ) : (
                  <button
                    type='button'
                    title={translate(514, 'Play')}
                    className={`${classes['btn']} btn-effect`}
                    onClick={this.playHandler}
                  >
                    <img
                      src={`${imagesURL}/Images/Audio/audio_make.png`}
                      alt={translate(514, 'Play')}
                    />
                  </button>
                )}
                {!readOnly && (
                  <button
                    type='button'
                    title={translate(96, 'Delete')}
                    className={`${classes['btn']} btn-effect`}
                    onClick={this.removeHandler}
                  >
                    <img
                      src={`${imagesURL}/FrontendUtilities/delete1.png`}
                      alt={translate(96, 'Delete')}
                    />
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        {show && (
          <DraggableModal
            title={translate(13, 'Add Audio')}
            show={show}
            withOverlay={withOverlay}
            onClose={this.toggleShow}
          >
            <div className={classes['add-audio-modal']}>
              <div className={classes['col']}>
                {/* <h3>{translate(445, 'Upload a File')}</h3> */}
                <Dropzone
                  onDrop={this.onDrop}
                  accept=".mp3"
                  multiple={false}
                  maxSize={6 * 1000000} // 6mb
                >
                  {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                      <div className={classes['dropzone-wrapper']}>
                        <div
                          {...getRootProps()}
                          className={classNames(classes['dropzone'], {
                            [`${classes['dropzone--active']}`]: isDragActive,
                          })}
                        >
                          <input {...getInputProps()} />
                          <p>
                            {translate(
                              441,
                              `Drop .mp3 file here, or click to select file to upload. Max 6mb`
                            )}
                          </p>
                          {/* {isDragActive ? (
                            <p>Drop file here...</p>
                          ) : (
                            <p>
                              {translate(
                                441,
                                `Drop .mp3 file here, or click to select file to upload. Max 6mb`
                              )}
                            </p>
                          )} */}
                        </div>
                        {/* {file && (
                          <>
                            <p>{file.name}</p>
                            <button
                              className={classes['upload-button']}
                              onClick={() => this.uploadHandler()}
                            >
                              Upload
                            </button>
                          </>
                        )} */}

                        {!!locales.length && (
                          <div className={classes.row}>
                            <button
                              type='button'
                              title="Generate TTS"
                              className={`${classes['tts-btn']} btn-effect`}
                              onClick={this.onGenerateTTS}
                              disabled={!associatedText}
                            >
                              <img
                                src={`${imagesURL}/FlinkMake/TTS.png`}
                                alt="TTS"
                                width={50}
                                height={50}
                              />
                            </button>
                            <div className={classes['tts-picker']}>

                              <select 
                                value={tts.locale || (versionType ? versionType.split('_')[1] : "")} 
                                onChange={(event) => setTTSLocale(event.target.value)}>
                                  { !versionType ?
                                    locales.map((locale) =>
                                      (<option
                                          label={locale.displayName}
                                          value={locale.code}
                                          key={`tts-locale-${locale._id}`}
                                        />)):
                                    allLocales.map((locale) =>
                                      (locale.code === versionType.split('_')[1]) && (<option
                                          label={locale.displayName}
                                          value={locale.code}
                                          key={`tts-locale-${locale._id}`}
                                        />))                                       
                                    }
                              </select>

                              <RadioGroup
                                name="voice"
                                changeHandler={(_, value) => setTTSVoice(value)}
                                current={tts}
                                options={[
                                  {
                                    label: translate(0, 'Male'),
                                    value: 'male',
                                  },
                                  {
                                    label: translate(0, 'Female'),
                                    value: 'female',
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }}
                </Dropzone>
                <p />
              </div>
              <div className={classes['col']}>
                <p style={{ marginTop: 0 }}>
                  {translate(436, 'Click the start button to record')}
                </p>
                <div className={classes['record-wrapper']}>
                  <Record
                    translate={translate}
                    addRecordedFileHandler={this.useRecorderFile}
                  />
                </div>
              </div>
            </div>
          </DraggableModal>
        )}
      </>
    );
  }
}

AddAudio.propTypes = {
  wrapperClassname: PropTypes.string,
  removeOldOnChange: PropTypes.bool,
  filename: PropTypes.string,
  withId: PropTypes.bool,
  saveAfterChange: PropTypes.bool,
  withOverlay: PropTypes.bool,
  isUrl: PropTypes.bool,
  useFileBucket: PropTypes.bool,
  readOnly: PropTypes.bool,
  // Required
  path: PropTypes.string.isRequired,
  current: PropTypes.string,
  onChangeHandler: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  // from redux
  uploadAudioFile: PropTypes.func.isRequired,
  saveEditingActivity: PropTypes.func.isRequired,
  playingSrc: PropTypes.string,
  isPlaying: PropTypes.bool,
  versionType: PropTypes.string
};

const mapStateToProps = ({
  status: { translate },
  audio: { audioSrc, isPlaying },
  flinkMake: { tts },
  common: { locales },
}) => ({
  translate,
  playingSrc: audioSrc,
  isPlaying,
  tts,
  locales: locales.filter(locale => locale.ttsConfig?.enable),
  allLocales: locales
});

export default compose(
  connect(mapStateToProps, {
    uploadAudioFile,
    generateTTS,
    setTTSVoice,
    setTTSLocale,
    saveEditingActivity,
  })
)(AddAudio);
