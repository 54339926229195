import _ from "lodash";

import { isEmpty } from "validation";

const addInheritedFields = (obj, proto, fields) => {
  const inheritedFields = {};

  if (!proto) {
    return {
      inheritedFields,
      formattedObject: obj
    };
  }

  const formattedObject = obj ? _.cloneDeep(obj) : {};

  _.forEach(fields, field => {
    const val = _.get(obj, field);

    if (isEmpty(val)) {
      const protoVal = _.get(proto, field);
      inheritedFields[field] = protoVal;
      formattedObject[field] = protoVal;
    }
  });

  return {
    inheritedFields,
    formattedObject
  };
};

export default addInheritedFields;
